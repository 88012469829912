import { FormHelperText } from "@mui/material";
import React from "react";
import { MuiIcons, MuiComponents } from "../../../components";

const { OutlinedInput, InputAdornment, IconButton } = MuiComponents;

const { Visibility, VisibilityOff, LockIcon } = MuiIcons;

const PasswordField = ({
  label,
  input,
  name,
  meta: { touched, error },
  ...custom
}) => {
  const [passwordValue, setPasswordValue] = React.useState({
    password: "",
    showPassword: false
  });

  const handleClickShowPassword = () => {
    setPasswordValue({
      ...passwordValue,
      showPassword: !passwordValue.showPassword
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <OutlinedInput
        {...input}
        {...custom}
        id="password"
        name={name}
        type={passwordValue.showPassword ? "text" : "password"}
        error={touched && error}
        startAdornment={
          <InputAdornment position="start">
            <LockIcon />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {passwordValue.showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        placeholder={label}
      />
      {!!touched && (
        <FormHelperText error sx={{ position: "absolute" }}>
          {error}
        </FormHelperText>
      )}
    </>
  );
};

export default PasswordField;
