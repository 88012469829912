import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import RenderFormHelper from "./ErrorHelperText";

export const createOptions = (arr = []) =>
  arr.map((item) => (
    <MenuItem id={item.id} value={item.id} key='item'>
      {item.name}
    </MenuItem>
  ));

const RenderSelectField = ({
  name,
  input,
  label,
  meta: { touched, error },
  children,
  multiple = false,
  isMandatory = false,
  ...custom
}) => {
  return (
    <>
      <InputLabel sx={{ fontSize: "13px" }}>
        {label} {isMandatory && <span style={{ color: "red", fontSize: "15px" }}>*</span>}</InputLabel>
      <FormControl error={touched && error}>
        <Select
          multiple={multiple}
          labelId={`label-${name}`}
          id={name}
          {...input}
          name={name}
          value={multiple ? input.value || [] : input.value}
          {...custom}
          onChange={(event) => input.onChange(event.target.value)}
        >
          {createOptions(children)}
        </Select>
      </FormControl>
      <RenderFormHelper {...{ touched, error }} />
    </>
  );
};

export default RenderSelectField;
