import React from "react";
import { Field, Form, reduxForm } from "redux-form";
import {
  MuiComponents, CustomComponents
} from "../../../../common/components";
import validate from "../../validations/otpValidation";


const { OtpInputField } = CustomComponents;
const {
  Grid,
  Card,
  Container,
  Typography,
  Button
} = MuiComponents;

const style = {
  cardStyle: {
    p: 2,
    width: "40rem",
    height: "18rem",
    backgroundColor: "secondary.main",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    marginTop: "10%"
  }
};


const EnterOtpNumber = (props) => {

  const { handleSubmit } = props;


  const handleSubmitForm = () => {


  };

  return (
    <Grid>
      <Form onSubmit={handleSubmit(handleSubmitForm)}>
        <Container maxWidth="md">
          <Card sx={style.cardStyle}>
            <Grid item xs={12}>
              <Container maxWidth="md">
                <Grid container spacing={2}>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={8}>
                    <Typography
                      variant="h5"
                      color="primary.dark"
                      sx={{ textAlign: "center", pt: 2 }}
                    >
                      Enter 4 Digit Code
                    </Typography>
                  </Grid>
                  <Grid item xs={2}></Grid>
                </Grid>
              </Container>
            </Grid>

            <Grid item xs={12}>
              <Container maxWidth="md">
                <Grid container spacing={2}>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={8}>
                    <Typography
                      color="secondary.contrastText"
                      sx={{ pt: 2, textAlign: "center" }}
                    >
                      Enter 4 digit code that you received on your email.
                    </Typography>
                  </Grid>
                  <Grid item xs={2}></Grid>
                </Grid>
              </Container>
            </Grid>

            <Grid item xs={12}>
              <Container maxWidth="md">
                <Grid container spacing={2}>
                  <Grid item xs={3}></Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{ textAlign: "center", marginTop: "25px" }}
                  >
                    <Field
                      className="form-control"
                      numInputs={4}
                      name="otpNumber"
                      component={OtpInputField}
                    />
                  </Grid>

                  <Grid item xs={3}></Grid>
                </Grid>
              </Container>
            </Grid>

            <Grid item xs={12}>
              <Container maxWidth="md">
                <Grid container spacing={2}>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={5} sx={{ marginTop: "20px" }}>

                    Click here ?&nbsp;
                    <a href="!#" className="text-blue-600">
                      Resend OTP
                    </a>

                  </Grid>
                  <Grid item xs={4}></Grid>
                </Grid>
              </Container>
            </Grid>

            <Grid sx={{ pt: 5 }}>
              <Button
                type="submit"
                sx={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto"
                }}
              >
                Submit
              </Button>
            </Grid>
          </Card>
        </Container>
      </Form>
    </Grid>
  );
};

export default reduxForm({
  form: "otpNumber form",
  validate
})(EnterOtpNumber);

