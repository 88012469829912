import { notify } from "reapop";

const NOTIFICATION_PROPS = {
    // id: "NOTIFICATION",
    title: "",
    message: "",
    dismissible: false,
    dismissAfter: 2000,
    position: "top-right"
};

export const successNotify = (props) => notify({ ...NOTIFICATION_PROPS, ...props, status: "success" });

export const infoNotify = (props) => notify({ ...NOTIFICATION_PROPS, ...props, status: "info" });

export const warningNotify = (props) => notify({ ...NOTIFICATION_PROPS, ...props, status: "warning" });

export const errorNotify = (props) => notify({ ...NOTIFICATION_PROPS, ...props, status: "error" });

export const loderNotify = (props) => notify({ ...NOTIFICATION_PROPS, ...props, status: "loading" });

