
export default function validate(values) {
    const errors = {};
    if ((new Date(values.fromDate) > new Date(values.toDate)) || (new Date(values.toDate) < new Date(values.fromDate))) {
        errors.toDate = "End date should greater than start date";
      } else {
        // null or false date error validation
      }
    return errors;
    }
