import React from "react";
import { useDispatch } from "react-redux";
import { Field, Form, reduxForm } from "redux-form";
import { loginPostApi } from "../actions";
import LOGO_IMAGE from "../../../../assets/images/Safety_logo.png";
import validate from "../../validations/LoginValidation";
import {
  CustomComponents,
  MuiComponents,
  MuiIcons
} from "../../../../common/components";
import { Link } from "react-router-dom";


const { RenderTextField, PasswordField } = CustomComponents;

const {
  Grid,
  Avatar,
  Card,
  Container,
  FormControl,
  InputAdornment,
  Typography,
  Button
} = MuiComponents;

const { AccountCircle } = MuiIcons;

const style = {
  cardStyle: {
    p: 2,
    width: "40rem",
    height: "22rem",
    backgroundColor: "secondary.main",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    marginTop: "8%"
  },
  imageStyle: {
    pt: 2,
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "5rem",
    height: "5rem"
  }
};


const LoginPage = (props) => {
  const { handleSubmit } = props;

  const dispatch = useDispatch();

  const handleSubmitForm = (values) => {

    dispatch(loginPostApi(values));
  };
  return (
    <Grid>
      <Container maxWidth="md">
        <Typography
          variant="h5"
          color="secondary.contrastText"
          sx={{ textAlign: "center", marginTop: "5%" }}
        >
          Login page
        </Typography>
        <Card sx={style.cardStyle}>
          <Grid item xs={12}>
            <Container maxWidth="md">
              <Grid container spacing={2}>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                </Grid>
                <Grid item xs={4}></Grid>
              </Grid>
            </Container>
          </Grid>

          <Grid item xs={12}>
            <Container maxWidth="md">
              <Grid container spacing={2}>
                <Grid item xs={4}></Grid>

                <Grid item xs={4}>
                  <Avatar
                    variant={"rounded"}
                    alt="safety-logo"
                    src={LOGO_IMAGE}
                    sx={style.imageStyle}
                  />
                </Grid>
                <Grid item xs={4}></Grid>
              </Grid>
            </Container>
          </Grid>

          <Form onSubmit={handleSubmit(handleSubmitForm)}>
            <Grid item xs={12}>
              <Container maxWidth="md">
                <Grid container spacing={2}>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={8}>
                    <FormControl
                      sx={{
                        pt: 2,
                        width: "14rem",
                        display: "block",
                        margin: "auto",
                        marginLeft: "auto",
                        marginRight: "auto"
                      }}
                    >
                      <Field
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircle />
                            </InputAdornment>
                          )
                        }}
                        className="form-control"
                        name="userName"
                        variant="outlined"
                        component={RenderTextField}
                        placeholder="Username"
                        type="text"
                        sx={{ backgroundColor: "white"}}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}></Grid>
                </Grid>
              </Container>
            </Grid>
            <Grid item xs={12}>
              <Container maxWidth="md">
                <Grid container spacing={2}>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={8}>
                    <FormControl
                      sx={{
                        width: "14rem",
                        pt: 1,
                        display: "block",
                        margin: "auto",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: "20px"
                      }}
                      variant="outlined"
                    >
                      <Field
                        className="form-control"
                        name="password"
                        component={PasswordField}
                        label="Password"
                        sx={{ backgroundColor: "white" }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3} md={6}></Grid>
                  <Grid item xs={12} sm={3} md={3}>
                  <Link
                    to="/forget-password"
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      fontSize: "13px",
                      textDecoration: 'none',
                      color: "primary.dark",
                      "&:hover": {
                        color: "primary.light"
                      }
                    }}
                  >
                    forgot password?
                  </Link>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}></Grid>
                  <Grid item xs={2}></Grid>
                </Grid>
              </Container>
            </Grid>
            <Grid >
              <Button
                type="submit"
                sx={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "20px"
                }}
              >
                Submit
              </Button>
            </Grid>
          </Form>
        </Card>
      </Container>
    </Grid>
  );
};

export default reduxForm({
  form: "LoginForm",
  validate
})(LoginPage);

