import * as React from 'react';
//import { useEffect } from "react";
// import _ from 'lodash';
import { Outlet, useLocation } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Navigate } from "../../../common/common/components";
import AccountMenu from 'common/components/custom/AccountMenu';
import LOGO_IMAGE from "../../../../assets/images/Safety_logo.png";
import { commonSliceActions } from 'modules/common/common/actions';
// import { getTotalTicketsCount } from '../actions';
// import { getTotalStatusCount } from '../selectors';
//import CustomBadge from 'common/components/custom/CustomBadge';
//import { STATE_REDUCER_KEY } from 'modules/common/Login';
import { MuiIcons } from 'common/components';
import { MuiComponents } from 'common/components';
import { getUserDetailsData } from 'modules/common/Login/selectors';
import { USER_ROLE_ID } from 'modules/common/common/constants';
import { Grid } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AssessmentIcon from '@mui/icons-material/Assessment';


const { DashboardIcon,
  FactCheckIcon,
  // FileOpenIcon,
  // TransferWithinAStationIcon,
  // AssignmentTurnedInIcon,
  // HighlightOffIcon,
  IconExpandLess,
  IconExpandMore,
  ViewListIcon,
  // EventRepeatIcon,
  // PendingActionsIcon,
  // EventSeatIcon,
  MenuIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  AddCircleOutlineIcon } = MuiIcons;

const {
  Collapse,
  Avatar,
  styled,
  useTheme,
  Box,
  Drawer,
  CssBaseline,
  Toolbar,
  List,
  ListItem,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MuiAppBar,
  IconButton,
  Typography } = MuiComponents;

const drawerWidth = 278;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0
    })
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}));

const SdcLayout = (props) => {
  //const { data } = useSelector((state) => _.get(state, `${STATE_REDUCER_KEY}.userDetails`));

  const { userDetails: { userDetails: userData = {} } = {} } = props;
  const { userRoles = [], firstName = "", lastName = "" } = userData;

  // const { totalTickets } = totalCount;

  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const [subMenu, setSubMenu] = React.useState(false);

  function handleClick() {
    setSubMenu(!subMenu);
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // useEffect(() => {
  //   dispatch(getTotalTicketsCount());
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location.pathname]);

  return (
    <Box sx={{ display: 'flex' }} >
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography>
            {firstName} {lastName} ({userRoles[0].userRole})
          </Typography>
          <IconButton>
            <AccountMenu />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            backgroundColor: "#333333",
            color: "white",
            width: drawerWidth,
            boxSizing: 'border-box'
          },
          "&:active": {
            activeBackgroundColor: "white"
          }
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Avatar
            variant={"rounded"}
            alt="safety-logo"
            src={LOGO_IMAGE}
            sx={{ marginRight: "8%" }}
          /> <Typography sx={{ marginRight: "2%" }}>Safe Kerala Ticketing</Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon sx={{ color: "white" }} /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <ListItem disablePadding sx={{
          marginTop: "4%", "&:hover": {
            backgroundColor: "#262626"
          }, "&:active": { backgroundColor: "white" }
        }} onClick={() => dispatch(commonSliceActions.navigateTo("/sdc/dashboard"))}>
          <ListItemButton selected={"/sdc/dashboard" === location.pathname}>
            <ListItemIcon>
              <DashboardIcon sx={{ color: "whitesmoke" }} />
            </ListItemIcon>
            <ListItemText primary={"Dashboard"} />
          </ListItemButton>
        </ListItem>
        <Divider />
        {(userRoles[0]?.userRoleId === USER_ROLE_ID?.SDC_ROLE_ID ||
          userRoles[0]?.userRoleId === USER_ROLE_ID?.DCR_ROLE_ID ||
          userRoles[0]?.userRoleId === USER_ROLE_ID?.CCR_ROLE_ID) && <Grid>
            <ListItem disablePadding onClick={handleClick} sx={{
              "&:hover": {
                backgroundColor: "#262626"
              }
            }}>
              <ListItemButton>
                <ListItemIcon>
                  <FactCheckIcon sx={{ color: "whitesmoke" }} />
                </ListItemIcon>
                <ListItemText primary={"Create Ticket"} />
                {subMenu ? <IconExpandLess /> : <IconExpandMore />}
              </ListItemButton>
            </ListItem>
            <Collapse in={subMenu} timeout="auto" unmountOnExit>
              <Divider sx={{ backgroundColor: "white" }} />
              <List component="div" disablePadding>

                <ListItem disablePadding onClick={() => dispatch(commonSliceActions.navigateTo("/sdc/service-request/create-ticket"))} sx={{
                  "&:hover": {
                    backgroundColor: "#262626"
                  }
                }}>
                  <ListItemButton selected={"/sdc/service-request/create-ticket" === location.pathname}>
                    <ListItemIcon sx={{ marginLeft: "10%" }}>
                      <AddCircleOutlineIcon sx={{ color: "whitesmoke" }} />
                    </ListItemIcon>
                    <ListItemText primary={"Log an Incident"} />
                  </ListItemButton>
                </ListItem>
              </List>
            </Collapse>
          </Grid>
        }
        <Divider />


        {userRoles[0]?.userRoleId !== USER_ROLE_ID?.SERVICE_MANAGER_ROLE_ID &&
          <Grid>
            <ListItem disablePadding onClick={() => dispatch(commonSliceActions.navigateTo("/sdc/service-request"))} sx={{
              "&:hover": {
                backgroundColor: "#262626"
              }
            }}>
              <ListItemButton selected={"/sdc/service-request" === location.pathname}>
                <ListItemIcon>
                  <ViewListIcon sx={{ color: "whitesmoke" }} />
                </ListItemIcon>
                <ListItemText primary={"Logged Incidents"} />
              </ListItemButton>
            </ListItem>
            <Divider />
          </Grid>}

        {userRoles[0]?.userRoleId === USER_ROLE_ID?.SERVICE_MANAGER_ROLE_ID &&
          <Grid>
            <ListItem disablePadding onClick={() => dispatch(commonSliceActions.navigateTo("/sdc/service-request/1"))} sx={{
              "&:hover": {
                backgroundColor: "#262626"
              }
            }}>
              <ListItemButton selected={"/sdc/service-request/1" === location.pathname}>
                <ListItemIcon>
                  <ViewListIcon sx={{ color: "whitesmoke" }} />
                </ListItemIcon>
                <ListItemText primary={"Logged Incidents for sdc"} />
              </ListItemButton>
            </ListItem>
            <Divider />

            <ListItem disablePadding onClick={() => dispatch(commonSliceActions.navigateTo("/sdc/service-request/2"))} sx={{
              "&:hover": {
                backgroundColor: "#262626"
              }
            }}>
              <ListItemButton selected={"/sdc/service-request/2" === location.pathname}>
                <ListItemIcon>
                  <ViewListIcon sx={{ color: "whitesmoke" }} />
                </ListItemIcon>
                <ListItemText primary={"Logged Incidents for dcr & ccr"} />
              </ListItemButton>
            </ListItem>
            <Divider />
          </Grid>
        }

        {(userRoles[0]?.userRoleId === USER_ROLE_ID?.SERVICE_MANAGER_ROLE_ID ||
          userRoles[0]?.userRoleId === USER_ROLE_ID?.DATA_CENTER_GROUP_ROLE_ID) &&
          <Grid>
            <ListItem disablePadding onClick={() => dispatch(commonSliceActions.navigateTo("/sdc/service-request/sla-report/2"))} sx={{
              "&:hover": {
                backgroundColor: "#262626"
              }
            }}>
              <ListItemButton selected={"/sdc/service-request/sla-report/2" === location.pathname}>
                <ListItemIcon>
                  <AssessmentIcon sx={{ color: "whitesmoke" }} />
                </ListItemIcon>
                <ListItemText primary={"SLA Report"} />
              </ListItemButton>
            </ListItem>
            <Divider />
          </Grid>

        }


        <ListItem disablePadding onClick={() => dispatch(commonSliceActions.navigateTo("/sdc/camera-location"))} sx={{
          "&:hover": {
            backgroundColor: "#262626"
          }
        }}>
          <ListItemButton selected={"/sdc/camera-location" === location.pathname}>
            <ListItemIcon>
              <LocationOnIcon sx={{ color: "whitesmoke" }} />
            </ListItemIcon>
            <ListItemText primary={"Asset Location"} />
          </ListItemButton>
        </ListItem>
        <Divider />

      </Drawer>
      <Main open={open} sx={{ overflow: "auto" }}>
        <DrawerHeader />
        <Navigate />
        <Outlet />
      </Main>
    </Box >
  );
};

const mapStateToProps = createStructuredSelector({
  //  totalCount: getTotalStatusCount,
  userDetails: getUserDetailsData
});


export default connect(mapStateToProps)(SdcLayout);
