import { all, call, fork, put, select, take, takeEvery, takeLatest } from "redux-saga/effects";
import { ACTION_TYPES, getTicketStatusList } from "./actions";
import { handleAPIRequest } from "../../../utils/http";
import {
  acceptPatchAPICall,
  actionRequiredAPICall,
  AssignDataAPICall,
  assignPatchAPICall,
  closePatchAPICall,
  controlRoomPostApprovedAPICall,
  controlRoomPostAssignAPICall,
  controlRoomPostCompletedAPICall,
  controlRoomPostReassignAPICall,
  controlRoomPostRejectAPICall,
  controlRoomPostResolvedAPICall,
  controlRoomPostUnderAnalysisAPICall,
  controlRoomPostWipAPICall,
  CreateControlRoomPostAPICall,
  CreatePagePostAPICall,
  districtAPICall,
  editTicketPatchAPICall,
  fetchFileToDownload,
  fetchSlaReportDetailsAPICall,
  fetchTicketByIdApiCall,
  fetchTicketStatusFilter,
  // fetchTicketByIdApiCall,
  locationAPICall,
  problemCategoryAPICall,
  problemTypeAPICall,
  problemTypeFilterAPICall,
  rejectPatchAPICall,
  systemStatusAPICall,
  systemTypeAPICall,
  ticketDeleteAPICall,
  ticketHistoryAPICall,
  ticketStatusListApiCall,
  // totalTicketListApiCall,
  userNameListAPICall,
  userRoleAPICall
} from "./api";
import { commonSliceActions } from "../../common/common/actions";
import { successNotify } from "utils/notificationUtils";
import Swal from "sweetalert2";
import _ from 'lodash';
import { getTotalTicketsCount } from "modules/layouts/SdcLayout/actions";
import { getPagintation } from "./selectors";
import { getUserDetailsData } from "modules/common/Login/selectors";
import { getFilterValues } from "modules/common/common/selectors";
import { USER_ROLE_ID } from "modules/common/common/constants";


export function* fetchDistrictDetails(action) {
  let request = action.payload;
  yield call(handleAPIRequest, districtAPICall, request);
}

export function* fetchLocationDetails(action) {
  let { crModuleId = "", val = "", slaId = "" } = action.payload;
  let userData = yield select(getUserDetailsData);
  const { userDetails: { userRoles = [] } = {} } = userData;
  let moduleId = userRoles[0]?.moduleId;
  if (userRoles[0]?.userRoleId === USER_ROLE_ID?.SERVICE_MANAGER_ROLE_ID) {
    if (slaId) {
      yield fork(handleAPIRequest, locationAPICall, { moduleId: slaId });
    } else {
      yield fork(handleAPIRequest, locationAPICall, { districtId: val, moduleId: crModuleId });
    }
  } else {
    yield call(handleAPIRequest, locationAPICall, { districtId: val, moduleId });
  }
}

export function* fetchActionRequiredDetails(action) {
  let request = action.payload;
  yield call(handleAPIRequest, actionRequiredAPICall, request);
}

export function* fetchProblemCategory(action) {
  let { moduleId = "", crModuleId = "" } = action.payload;
  let userData = yield select(getUserDetailsData);
  const { userDetails: { userRoles = [] } = {} } = userData;
  if (userRoles[0]?.userRoleId === USER_ROLE_ID?.SERVICE_MANAGER_ROLE_ID) {
    if (crModuleId) {
      yield fork(handleAPIRequest, problemCategoryAPICall, { moduleId: crModuleId });
    } else {
      yield fork(handleAPIRequest, problemCategoryAPICall, { moduleId });
    }
  } else {
    yield fork(handleAPIRequest, problemCategoryAPICall, { moduleId });
  }
}

export function* fetchProblemType(action) {
  let { value } = action.payload;
  let id = _.get(value, 'problemCategoryId', value);
  yield call(handleAPIRequest, problemTypeAPICall, { id });
}

export function* fetchProblemTypeFilter(action) {
  let { slaId = "", val = "" } = action.payload;
  let request = {};
  if (slaId) {
    request = { moduleId: slaId };
  } else {
    request = { problemCategory: val };
  }
  yield call(handleAPIRequest, problemTypeFilterAPICall, request);
}

export function* fetchSystemType(action) {
  let request = action.payload;
  yield call(handleAPIRequest, systemTypeAPICall, request);
}

export function* fetchSystemStatus(action) {
  let request = action.payload;
  yield call(handleAPIRequest, systemStatusAPICall, request);
}

function sweetAl() {
  return Swal.fire({
    title: 'Technician Assign?',
    text: "Do you want to assign the ticket now!",
    icon: 'question',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes',
    cancelButtonText: "No"
  }).then(result => ({ result })).catch(err => ({ err }));
}

export function* createPagePost(action) {
  let request = action.payload;
  let userData = yield select(getUserDetailsData);
  const { userDetails: { userId = "", userRoles = {} } = {} } = userData;
  let userProfile = { userId };

  if (userRoles[0]?.moduleId === 1) {
    yield fork(handleAPIRequest, CreatePagePostAPICall, { ...request, userProfile });
    const responses = yield take([ACTION_TYPES.TICKET_POST_SUCCESS]);
    const { payload } = responses;
    const { response } = payload;
    const ticketId = response.payLoad.ticketId ? response.payLoad.ticketId : null;

    if (responses.type === ACTION_TYPES.TICKET_POST_SUCCESS) {
      yield put(
        successNotify({
          title: "Ticket created",
          message: "successfully created Ticket"
        })
      );
      const { result } = yield call(sweetAl);

      if (result.isConfirmed) {
        yield put(commonSliceActions.navigateTo(`/sdc/service-request/assign/${ticketId}`));
      } else {
        yield put(commonSliceActions.navigateTo(`/sdc/service-request`));
        yield put(getTotalTicketsCount());
      }
    }
  } else {
    const { controlRoom: { districtId = "", locationId = "" } = {} } = userData;
    request.districtId = districtId;
    request.locationId = locationId;
    yield fork(handleAPIRequest, CreateControlRoomPostAPICall, { payLoad: { ...request, userProfile } });
    const responses = yield take([ACTION_TYPES.CONTROL_ROOM_TICKET_POST_DETAILS_SUCCESS]);

    if (responses.type === ACTION_TYPES.CONTROL_ROOM_TICKET_POST_DETAILS_SUCCESS) {
      yield put(
        successNotify({
          title: "Ticket created",
          message: "successfully created Ticket"
        })
      );
      yield put(commonSliceActions.navigateTo(`/sdc/service-request`));
      yield put(getTotalTicketsCount());
    }
  }
}

export function* fetchTicketById(action) {
  let id = action.payload;
  yield call(handleAPIRequest, fetchTicketByIdApiCall, { id });
}

export function* fetchAssignData(action) {
  let id = action.payload;
  yield call(handleAPIRequest, AssignDataAPICall, { id });
}

export function* fetchUserRoleData() {
  let userData = yield select(getUserDetailsData);
  const { userDetails: { userRoles = [] } = {} } = userData;
  let id = userRoles[0]?.moduleId;
  yield call(handleAPIRequest, userRoleAPICall, { moduleId: id });
}

export function* fetchUserNameList(action) {
  let id = action.payload;
  yield call(handleAPIRequest, userNameListAPICall, { id });
}

export function* createAssignPatch(action) {
  let { id, userId, statusId } = action.payload;
  yield fork(handleAPIRequest, assignPatchAPICall, { id, userId });

  const actionTypes = yield take([ACTION_TYPES.ASSIGN_PATCH_DETAILS_SUCCESS]);

  if (actionTypes.type === ACTION_TYPES.ASSIGN_PATCH_DETAILS_SUCCESS) {
    yield put(
      successNotify({
        title: "Ticket Assignation",
        message: "Successfully Assigned Ticket"
      })
    );
    if (Number(statusId) === 1) {
      yield put(commonSliceActions.navigateTo("/sdc/service-request/1"));
      yield put(getTicketStatusList(statusId));
      yield put(getTotalTicketsCount());
    } else if (Number(statusId) === 2) {
      yield put(commonSliceActions.navigateTo("/sdc/service-request/2"));
      yield put(getTicketStatusList(statusId));
      yield put(getTotalTicketsCount());
    } else if (Number(statusId) === 3) {
      yield put(commonSliceActions.navigateTo("/sdc/service-request/3"));
      yield put(getTicketStatusList(statusId));
      yield put(getTotalTicketsCount());
    } else if (Number(statusId) === 6) {
      yield put(commonSliceActions.navigateTo("/sdc/service-request/6"));
      yield put(getTicketStatusList(statusId));
      yield put(getTotalTicketsCount());
    } else if (Number(statusId) === 8) {
      yield put(commonSliceActions.navigateTo("/sdc/service-request/8"));
      yield put(getTicketStatusList(statusId));
      yield put(getTotalTicketsCount());
    } else {
      yield put(commonSliceActions.navigateTo("/sdc/service-request"));
      // yield put(getTotalTicketListData());
      yield put(getTotalTicketsCount());
    }
  }
}

export function* fetchByTicketStatus(action) {
  const { filterValues = {}, moduleId = "", userId = "", status = "", ticketStatusId = "", crModuleId = "", roleName = "" } = action.payload;
  const { districtId: filterDistrict = "" } = filterValues;
  let pagination = yield select(getPagintation);
  let userData = yield select(getUserDetailsData);
  const { userDetails: { userRoles = [], userDistrict = [] } = {} } = userData;
  let districtId = userDistrict[0]?.districtId;
  let request;
  if (userRoles[0]?.userRoleId === USER_ROLE_ID?.DATA_CENTER_GROUP_ROLE_ID) {
    if (ticketStatusId) {
      request = { ...filterValues, ...pagination, moduleId, problemsCategoryId: 7, assignedTo: userId, ticketStatusId };
    } else if (!status) {
      request = { ...filterValues, ...pagination, moduleId, problemsCategoryId: 7, newlyAssignedTicket: true };
    } else if (status === 9) {
      request = { ...filterValues, ...pagination, moduleId, problemsCategoryId: 7, ticketStatusId: 9 };
    } else {
      request = { ...filterValues, ...pagination, moduleId, problemsCategoryId: 7, assignedTo: userId };
    }
  } else if (userRoles[0]?.userRoleId === USER_ROLE_ID?.PERMIS_SUPPORT_GROUP_ROLE_ID) {
    if (ticketStatusId) {
      request = { ...filterValues, ...pagination, moduleId, problemsCategoryId: 8, assignedTo: userId, ticketStatusId };
    } else if (!status) {
      request = { ...filterValues, ...pagination, moduleId, problemsCategoryId: 8, newlyAssignedTicket: true };
    } else if (status === 9) {
      request = { ...filterValues, ...pagination, moduleId, problemsCategoryId: 8, ticketStatusId: 9 };
    } else {
      request = { ...filterValues, ...pagination, moduleId, problemsCategoryId: 8, assignedTo: userId };
    }
  } else if (userRoles[0]?.userRoleId === USER_ROLE_ID?.SDC_ROLE_ID) {
    if (ticketStatusId) {
      request = { ...filterValues, ...pagination, moduleId, ticketStatusId };
    } else {
      request = { ...filterValues, ...pagination, moduleId };
    }
  } else if (userRoles[0]?.userRoleId === USER_ROLE_ID?.DCR_ROLE_ID || userRoles[0]?.userRoleId === USER_ROLE_ID?.CCR_ROLE_ID) {
    if (filterDistrict) {
      request = { ...filterValues, ...pagination, moduleId };
    } else if (ticketStatusId) {
      request = { ...filterValues, ...pagination, moduleId, ticketStatusId, districtId };
    } else {
      request = { ...filterValues, ...pagination, moduleId, districtId };
    }
  } else if (userRoles[0]?.userRoleId === USER_ROLE_ID?.SERVICE_MANAGER_ROLE_ID) {
    if (roleName === "sdc") {
      request = { ...filterValues, ...pagination, moduleId: 1 };
    } else if (roleName === "dcr") {
      request = { ...filterValues, ...pagination, moduleId: 2 };
    } else if (roleName === "ccr") {
      request = { ...filterValues, ...pagination, moduleId: 2, districtId: 15 };
    } else {
      request = { ...filterValues, ...pagination, moduleId: crModuleId };
    }
  } else {
    request = { ...filterValues, ...pagination, moduleId, userId };
  }
  yield call(handleAPIRequest, ticketStatusListApiCall, request);
}

export function* getFileToDownload(action) {
  let { url, file, filter: { filterValues = {}, moduleId = "", userId = "", status = "", ticketStatusId = "", crModuleId = "" } = {} } = action.payload;
  const { districtId: filterDistrict = "" } = filterValues;
  let userData = yield select(getUserDetailsData);
  const { userDetails: { userRoles = [], userDistrict = [] } = {} } = userData;
  let districtId = userDistrict[0]?.districtId;
  let params;
  if (userRoles[0]?.userRoleId === USER_ROLE_ID?.DATA_CENTER_GROUP_ROLE_ID) {
    if (ticketStatusId) {
      params = { ...filterValues, moduleId, problemsCategoryId: 7, assignedTo: userId, ticketStatusId };
    } else if (!status || status === 9) {
      params = { ...filterValues, moduleId, problemsCategoryId: 7, ticketStatusId: 9 };
    } else {
      params = { ...filterValues, moduleId, problemsCategoryId: 7, assignedTo: userId };
    }
  } else if (userRoles[0]?.userRoleId === USER_ROLE_ID?.PERMIS_SUPPORT_GROUP_ROLE_ID) {
    if (ticketStatusId) {
      params = { ...filterValues, moduleId, problemsCategoryId: 8, assignedTo: userId, ticketStatusId };
    } else if (!status || status === 9) {
      params = { ...filterValues, moduleId, problemsCategoryId: 8, ticketStatusId: 9 };
    } else {
      params = { ...filterValues, moduleId, problemsCategoryId: 8, assignedTo: userId };
    }
  } else if (userRoles[0]?.userRoleId === USER_ROLE_ID?.SDC_ROLE_ID) {
    if (ticketStatusId) {
      params = { ...filterValues, moduleId, ticketStatusId };
    } else {
      params = { ...filterValues, moduleId };
    }
  } else if (userRoles[0]?.userRoleId === USER_ROLE_ID?.DCR_ROLE_ID || userRoles[0]?.userRoleId === USER_ROLE_ID?.CCR_ROLE_ID) {
    if (filterDistrict) {
      params = { ...filterValues, moduleId };
    } else if (ticketStatusId) {
      params = { ...filterValues, moduleId, ticketStatusId, districtId };
    } else {
      params = { ...filterValues, moduleId, districtId };
    }
  } else if (userRoles[0]?.userRoleId === USER_ROLE_ID?.SERVICE_MANAGER_ROLE_ID) {
    if (crModuleId) {
      params = { ...filterValues, moduleId: crModuleId };
    } else {
      params = { ...filterValues };
    }
  } else {
    params = { ...filterValues, moduleId, userId };
  }

  yield fork(handleAPIRequest, fetchFileToDownload, { url, params, file });
  const currentAction = yield take([ACTION_TYPES.DOWNLOAD_FILE_SUCCESS]);
  if (currentAction.type === ACTION_TYPES.DOWNLOAD_FILE_SUCCESS) {
    yield put(
      successNotify({
        title: "Success",
        message: "Successfully Downloaded Report"
      })
    );
  }
}

export function* fetchTicketHistoryData(action) {
  let id = action.payload;
  yield call(handleAPIRequest, ticketHistoryAPICall, { id });
}

export function* createRejectPatch(action) {
  let ticketStatusId = 1;
  let { ticketId } = action.payload;
  let userData = yield select(getUserDetailsData);
  const { userDetails: { userRoles = [] } = {} } = userData;
  let filterValues = yield select(getFilterValues);
  let moduleId = userRoles[0]?.moduleId;
  yield fork(handleAPIRequest, rejectPatchAPICall, { ticketId, ticketStatusId });

  const actionTypes = yield take([ACTION_TYPES.REJECT_PATCH_DETAILS_SUCCESS]);

  if (actionTypes.type === ACTION_TYPES.REJECT_PATCH_DETAILS_SUCCESS) {
    yield put(
      successNotify({
        title: "Rejected Successfully",
        message: "Ticket Rejected successfully"
      })
    );
    yield put(getTicketStatusList({ filterValues, moduleId }));
    yield put(getTotalTicketsCount());
  }
}

export function* createAcceptPatch(action) {
  let ticketStatusId = 8;
  let { ticketId } = action.payload;
  let userData = yield select(getUserDetailsData);
  const { userDetails: { userRoles = [] } = {} } = userData;
  let filterValues = yield select(getFilterValues);
  let moduleId = userRoles[0]?.moduleId;
  yield fork(handleAPIRequest, acceptPatchAPICall, { ticketId, ticketStatusId });

  const actionTypes = yield take([ACTION_TYPES.ACCEPT_PATCH_DETAILS_SUCCESS]);

  if (actionTypes.type === ACTION_TYPES.ACCEPT_PATCH_DETAILS_SUCCESS) {
    yield put(
      successNotify({
        title: "Accepted Successfully",
        message: "Ticket Accepted successfully"
      })
    );
    yield put(getTicketStatusList({ filterValues, moduleId }));
    yield put(getTotalTicketsCount());
  }
}

export function* createClosePatch(action) {
  let ticketStatusId = 5;
  let { ticketId } = action.payload;
  let userData = yield select(getUserDetailsData);
  const { userDetails: { userRoles = [] } = {} } = userData;
  let filterValues = yield select(getFilterValues);
  let moduleId = userRoles[0]?.moduleId;
  yield fork(handleAPIRequest, closePatchAPICall, { ticketId, ticketStatusId });

  const actionTypes = yield take([ACTION_TYPES.CLOSE_PATCH_DETAILS_SUCCESS]);

  if (actionTypes.type === ACTION_TYPES.CLOSE_PATCH_DETAILS_SUCCESS) {
    yield put(
      successNotify({
        title: "Closed Successfully",
        message: "Ticket Closed Successfully"
      })
    );
    yield put(getTicketStatusList({ filterValues, moduleId }));
    yield put(getTotalTicketsCount());
  }
}

export function* ticketDelete(action) {
  let { ticketId } = action.payload;
  yield fork(handleAPIRequest, ticketDeleteAPICall, { ticketId });

  const actionTypes = yield take([ACTION_TYPES.DELETE_TICKET_SUCCESS]);

  if (actionTypes.type === ACTION_TYPES.DELETE_TICKET_SUCCESS) {
    yield put(
      successNotify({
        title: "Deleted Successfully",
        message: "Ticket Deleted Successfully"
      })
    );
    location.reload();
  }
}

export function* editTicketPatch(action) {
  let { ticketId, values, statusId } = action.payload;
  yield fork(handleAPIRequest, editTicketPatchAPICall, { ticketId, values });

  const actionTypes = yield take([ACTION_TYPES.EDIT_TICKET_PATCH_DETAILS_SUCCESS]);

  if (actionTypes.type === ACTION_TYPES.EDIT_TICKET_PATCH_DETAILS_SUCCESS) {
    yield put(
      successNotify({
        title: "Success",
        message: "Ticket Updated successfully"
      })
    );
    if (Number(statusId) === 1) {
      yield put(commonSliceActions.navigateTo("/sdc/service-request/1"));
    } else if (Number(statusId) === 2) {
      yield put(commonSliceActions.navigateTo("/sdc/service-request/2"));
    } else if (Number(statusId) === 3) {
      yield put(commonSliceActions.navigateTo("/sdc/service-request/3"));
    } else if (Number(statusId) === 8) {
      yield put(commonSliceActions.navigateTo("/sdc/service-request/8"));
    } else {
      yield put(commonSliceActions.navigateTo("/sdc/service-request"));
    }
  }
}

export function* fetchFilterTicketStatus(action) {
  let { crModuleId = "", slaId = "" } = action.payload;
  let userData = yield select(getUserDetailsData);
  const { userDetails: { userRoles = {} } = {} } = userData;
  let moduleId = userRoles[0]?.moduleId;
  if (userRoles[0]?.userRoleId === USER_ROLE_ID?.SERVICE_MANAGER_ROLE_ID) {
    if (slaId) {
      yield fork(handleAPIRequest, fetchTicketStatusFilter, { moduleId: slaId, dropDown: true });

    } else {
      yield fork(handleAPIRequest, fetchTicketStatusFilter, { moduleId: crModuleId, dropDown: true });
    }
  } else {
    yield call(handleAPIRequest, fetchTicketStatusFilter, { moduleId, dropDown: true });
  }
}

export function* supportGroupAssignPost(action) {
  let ticketId = action.payload;
  let userData = yield select(getUserDetailsData);
  let filterValues = yield select(getFilterValues);
  const { ticketStatusId: status = "" } = filterValues;
  const { userDetails: { userId = "", userRoles = [] } = {} } = userData;
  const moduleId = userRoles[0]?.moduleId;
  let userProfile = { userId };
  let ticketDetails = { ticketId };
  let operationType = "ASSIGNED";
  let payLoad = { userProfile, ticketDetails, operationType };
  yield fork(handleAPIRequest, controlRoomPostAssignAPICall, { payLoad });

  const actionTypes = yield take([ACTION_TYPES.POST_TICKET_STATUS_ASSIGN_SUCCESS]);

  if (actionTypes.type === ACTION_TYPES.POST_TICKET_STATUS_ASSIGN_SUCCESS) {
    yield put(
      successNotify({
        title: "Assigned Successfully",
        message: "Ticket Assigned Successfully"
      })
    );
    yield put(getTicketStatusList({ filterValues, status, userId, moduleId }));

  }
}

export function* supportGroupUnderAnalysisPost(action) {
  let { ticketId, val } = action.payload;
  let userData = yield select(getUserDetailsData);
  let filterValues = yield select(getFilterValues);
  const { ticketStatusId: status = "" } = filterValues;
  const { userDetails: { userId = "", userRoles = [] } = {} } = userData;
  const moduleId = userRoles[0]?.moduleId;
  let userProfile = { userId };
  let ticketDetails = { ticketId };
  let comments = val;
  let operationType = "UNDER_ANALYSIS";
  let payLoad = { userProfile, ticketDetails, operationType, ...comments };
  yield fork(handleAPIRequest, controlRoomPostUnderAnalysisAPICall, { payLoad });

  const actionTypes = yield take([ACTION_TYPES.POST_TICKET_STATUS_UNDER_ANALYSIS_SUCCESS]);

  if (actionTypes.type === ACTION_TYPES.POST_TICKET_STATUS_UNDER_ANALYSIS_SUCCESS) {
    yield put(
      successNotify({
        title: "Changed Status",
        message: "Ticket Under Analysis"
      })
    );
    yield put(getTicketStatusList({ filterValues, status, userId, moduleId }));

  }
}

export function* supportGroupApprovedPost(action) {
  let { ticketId, val } = action.payload;
  let userData = yield select(getUserDetailsData);
  let filterValues = yield select(getFilterValues);
  const { ticketStatusId: status = "" } = filterValues;
  const { userDetails: { userId = "", userRoles = [] } = {} } = userData;
  const moduleId = userRoles[0]?.moduleId;
  let userProfile = { userId };
  let ticketDetails = { ticketId };
  let comments = val;
  let operationType = "APPROVED";
  let payLoad = { userProfile, ticketDetails, operationType, ...comments };
  yield fork(handleAPIRequest, controlRoomPostApprovedAPICall, { payLoad });

  const actionTypes = yield take([ACTION_TYPES.POST_TICKET_STATUS_ACCEPT_SUCCESS]);

  if (actionTypes.type === ACTION_TYPES.POST_TICKET_STATUS_ACCEPT_SUCCESS) {
    yield put(
      successNotify({
        title: "Changed Status",
        message: "Ticket Accepted"
      })
    );
    yield put(getTicketStatusList({ filterValues, status, userId, moduleId }));

  }
}

export function* supportGroupRejectedPost(action) {
  let { ticketId, val } = action.payload;
  let userData = yield select(getUserDetailsData);
  let filterValues = yield select(getFilterValues);
  const { ticketStatusId: status = "" } = filterValues;
  const { userDetails: { userId = "", userRoles = [] } = {} } = userData;
  const moduleId = userRoles[0]?.moduleId;
  let userProfile = { userId };
  let ticketDetails = { ticketId };
  let comments = val;
  let operationType = "REJECTED";
  let payLoad = { userProfile, ticketDetails, operationType, ...comments };
  yield fork(handleAPIRequest, controlRoomPostRejectAPICall, { payLoad });

  const actionTypes = yield take([ACTION_TYPES.POST_TICKET_STATUS_REJECT_SUCCESS]);

  if (actionTypes.type === ACTION_TYPES.POST_TICKET_STATUS_REJECT_SUCCESS) {
    yield put(
      successNotify({
        title: "Changed Status",
        message: "Ticket Accepted"
      })
    );
    yield put(getTicketStatusList({ filterValues, status, userId, moduleId }));

  }
}

export function* supportGroupWorkInProgressPost(action) {
  let { ticketId, val } = action.payload;
  let userData = yield select(getUserDetailsData);
  let filterValues = yield select(getFilterValues);
  const { ticketStatusId: status = "" } = filterValues;
  const { userDetails: { userId = "", userRoles = [] } = {} } = userData;
  const moduleId = userRoles[0]?.moduleId;
  let userProfile = { userId };
  let ticketDetails = { ticketId };
  let comments = val;
  let operationType = "W_I_P";
  let payLoad = { userProfile, ticketDetails, operationType, ...comments };
  yield fork(handleAPIRequest, controlRoomPostWipAPICall, { payLoad });

  const actionTypes = yield take([ACTION_TYPES.POST_TICKET_STATUS_WIP_SUCCESS]);

  if (actionTypes.type === ACTION_TYPES.POST_TICKET_STATUS_WIP_SUCCESS) {
    yield put(
      successNotify({
        title: "Changed Status",
        message: "Successfully updated status"
      })
    );
    yield put(getTicketStatusList({ filterValues, status, userId, moduleId }));

  }
}


export function* supportGroupCompletedPost(action) {
  let { ticketId, val } = action.payload;
  let userData = yield select(getUserDetailsData);
  let filterValues = yield select(getFilterValues);
  const { ticketStatusId: status = "" } = filterValues;
  const { userDetails: { userId = "", userRoles = [] } = {} } = userData;
  const moduleId = userRoles[0]?.moduleId;
  let userProfile = { userId };
  let ticketDetails = { ticketId };
  let comments = val;
  let operationType = "COMPLETED";
  let payLoad = { userProfile, ticketDetails, operationType, ...comments };
  yield fork(handleAPIRequest, controlRoomPostCompletedAPICall, { payLoad });

  const actionTypes = yield take([ACTION_TYPES.POST_TICKET_STATUS_COMPLETED_SUCCESS]);

  if (actionTypes.type === ACTION_TYPES.POST_TICKET_STATUS_COMPLETED_SUCCESS) {
    yield put(
      successNotify({
        title: "Changed Status",
        message: "Ticket Moved to Completed Status"
      })
    );
    yield put(getTicketStatusList({ filterValues, status, userId, moduleId }));

  }
}

export function* supportGroupResolvedPost(action) {
  let { ticketId, val } = action.payload;
  let userData = yield select(getUserDetailsData);
  let filterValues = yield select(getFilterValues);
  const { ticketStatusId: status = "" } = filterValues;
  const { userDetails: { userId = "", userRoles = [] } = {} } = userData;
  const moduleId = userRoles[0]?.moduleId;
  let userProfile = { userId };
  let ticketDetails = { ticketId };
  let comments = val;
  let operationType = "RESOLVED";
  let payLoad = { userProfile, ticketDetails, operationType, ...comments };
  yield fork(handleAPIRequest, controlRoomPostResolvedAPICall, { payLoad });

  const actionTypes = yield take([ACTION_TYPES.POST_TICKET_STATUS_RESOLVED_SUCCESS]);

  if (actionTypes.type === ACTION_TYPES.POST_TICKET_STATUS_RESOLVED_SUCCESS) {
    yield put(
      successNotify({
        title: "Changed Status",
        message: "Ticket Successfully Resolved "
      })
    );
    yield put(getTicketStatusList({ filterValues, status, userId, moduleId }));

  }
}

export function* supportGroupReassignedPost(action) {
  let { ticketId, val, actionText } = action.payload;
  let userData = yield select(getUserDetailsData);
  let filterValues = yield select(getFilterValues);
  const { ticketStatusId: status = "" } = filterValues;
  const { userDetails: { userId = "", userRoles = [] } = {} } = userData;
  const moduleId = userRoles[0]?.moduleId;
  let userProfile = { userId };
  let ticketDetails = { ticketId };
  let comments = val;
  let operationType;
  if (actionText === "vendor") {
    operationType = "RE_ASSIGNED";
  } else {
    operationType = "RE_ASSIGN_TO_OTHER_CATEGORY";
  }
  let payLoad = { userProfile, ticketDetails, operationType, ...comments };
  yield fork(handleAPIRequest, controlRoomPostReassignAPICall, { payLoad });

  const actionTypes = yield take([ACTION_TYPES.POST_TICKET_STATUS_RESOLVED_SUCCESS]);

  if (actionTypes.type === ACTION_TYPES.POST_TICKET_STATUS_RESOLVED_SUCCESS) {
    yield put(
      successNotify({
        title: "Re-assigned successfully",
        message: "Ticket Successfully Re-assigned "
      })
    );
    yield put(getTicketStatusList({ filterValues, status, userId, moduleId }));

  }
}

export function* fetchSlaReportDetails(action) {
  let { filterValues = {} } = action.payload;
  let pagination = yield select(getPagintation);
  yield call(handleAPIRequest, fetchSlaReportDetailsAPICall, { ...pagination, ...filterValues });
}

export default function* serviceCreatePageSaga() {
  yield all([
    takeLatest(ACTION_TYPES.FETCH_DISTRICT_DETAILS, fetchDistrictDetails),
    takeLatest(ACTION_TYPES.FETCH_LOCATION_DETAILS, fetchLocationDetails),
    takeLatest(ACTION_TYPES.FETCH_ACTION_DETAILS, fetchActionRequiredDetails),
    takeLatest(ACTION_TYPES.FETCH_PROBLEM_CATEGORY_DETAILS, fetchProblemCategory),
    takeEvery(ACTION_TYPES.FETCH_PROBLEM_TYPE_DETAILS, fetchProblemType),
    takeEvery(ACTION_TYPES.FETCH_PROBLEM_TYPE_FILTER_DETAILS, fetchProblemTypeFilter),
    takeLatest(ACTION_TYPES.FETCH_SYSTEM_TYPE_DETAILS, fetchSystemType),
    takeLatest(ACTION_TYPES.FETCH_SYSTEM_STATUS_DETAILS, fetchSystemStatus),
    takeLatest(ACTION_TYPES.TICKET_POST_DETAILS, createPagePost),
    takeLatest(ACTION_TYPES.FETCH_TICKET_BY_ID_DETAILS, fetchTicketById),
    takeLatest(ACTION_TYPES.FETCH_ASSIGNDATA_DETAILS, fetchAssignData),
    takeLatest(ACTION_TYPES.FETCH_USER_ROLE_DETAILS, fetchUserRoleData),
    takeLatest(ACTION_TYPES.FETCH_LIST_USERNAME_DETAILS, fetchUserNameList),
    takeLatest(ACTION_TYPES.ASSIGN_PATCH_DETAILS, createAssignPatch),
    // takeLatest(ACTION_TYPES.FETCH_TOTAL_TICKET_LIST_DETAILS, fetchTotalTicketList),
    takeLatest(ACTION_TYPES.FETCH_TICKET_STATUS_LIST_DETAILS, fetchByTicketStatus),
    takeLatest(ACTION_TYPES.FETCH_TICKET_HISTORY_LIST_DETAILS, fetchTicketHistoryData),
    takeLatest(ACTION_TYPES.REJECT_PATCH_DETAILS, createRejectPatch),
    takeLatest(ACTION_TYPES.ACCEPT_PATCH_DETAILS, createAcceptPatch),
    takeLatest(ACTION_TYPES.CLOSE_PATCH_DETAILS, createClosePatch),
    takeLatest(ACTION_TYPES.DOWNLOAD_FILE, getFileToDownload),
    takeLatest(ACTION_TYPES.DELETE_TICKET, ticketDelete),
    takeLatest(ACTION_TYPES.EDIT_TICKET_PATCH_DETAILS, editTicketPatch),
    takeLatest(ACTION_TYPES.FETCH_TICKET_STATUS_FILTER, fetchFilterTicketStatus),
    takeLatest(ACTION_TYPES.POST_TICKET_STATUS_ASSIGN, supportGroupAssignPost),
    takeLatest(ACTION_TYPES.POST_TICKET_STATUS_UNDER_ANALYSIS, supportGroupUnderAnalysisPost),
    takeLatest(ACTION_TYPES.POST_TICKET_STATUS_ACCEPT, supportGroupApprovedPost),
    takeLatest(ACTION_TYPES.POST_TICKET_STATUS_REJECT, supportGroupRejectedPost),
    takeLatest(ACTION_TYPES.POST_TICKET_STATUS_WIP, supportGroupWorkInProgressPost),
    takeLatest(ACTION_TYPES.POST_TICKET_STATUS_COMPLETED, supportGroupCompletedPost),
    takeLatest(ACTION_TYPES.POST_TICKET_STATUS_RESOLVED, supportGroupResolvedPost),
    takeLatest(ACTION_TYPES.POST_TICKET_STATUS_REASSIGN, supportGroupReassignedPost),
    takeLatest(ACTION_TYPES.FETCH_SLA_REPORT_DETAILS, fetchSlaReportDetails)
  ]);
}
