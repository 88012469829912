import { React, useEffect } from "react";
import { createStructuredSelector } from "reselect";
import { connect, useDispatch, useSelector } from "react-redux";
import { Field, FieldArray, Form, reduxForm } from "redux-form";
import validate from "../validations/CreatePageValidation";
import { CustomComponents, MuiComponents } from "../../../../common/components";
import {
  getActionRequiredData,
  getDistrictData,
  getLocationData,
  getProblemCategoryData,
  getProblemTypeData,
  getSystemStatusData,
  getSystemTypeData,
  postTicketData,
  getTicketByIdData,
  patchEditTicket,
  clearLocationValue
} from "../actions";
import {
  getActionDropdown,
  getDistrictDropdown,
  getLocationDropdown,
  getProblemCategoryDropdown,
  getProblemTypeDropdown,
  getSystemStatusDropdown,
  getSystemTypeDropdown,
  getTicketGetById
} from "../selectors";
import { Divider, Typography } from "@mui/material";
import LabelField from "common/components/custom/form-components/LabelField";
import { useParams } from "react-router-dom";
import { actions as sliceActions } from '../slice';
import _ from "lodash";
import { getUserDetailsData } from "modules/common/Login/selectors";
import { USER_ROLE_ID } from "modules/common/common/constants";
import RenderTextField from "common/components/custom/form-components/TextField";


const { Button, Card, Container, Grid } = MuiComponents;

const { RenderLabelFieldArray, RenderSelectField, RenderTextArea } = CustomComponents;

const problemFieldArrayFields = (change, moduleId) => ({
  dispatchActions: [getProblemCategoryData({ moduleId })],
  reducerKey: [
    { STATE_REDUCER_KEY: "serviceRequest.problemCategoryDropdown.data" },
    {
      STATE_REDUCER_KEY: "serviceRequest.problemTypeDropdown.data",
      key: true
    }
  ],
  fieldData: [
    {
      name: "problemCategoryId",
      label: "Problem Category",
      onChange: [
        { action: getProblemTypeData, data: { key: "problemCategoryId" } },
        {
          fn: (field) => {
            change(field, '');
          }
        }
      ],
      dependant: true
    },
    {
      name: "problemTypesId",
      label: "Type Of Problem"
    }
  ]
});

const systemFieldArrayFields = {
  dispatchActions: [getSystemTypeData(), getSystemStatusData()],
  reducerKey: [
    { STATE_REDUCER_KEY: "serviceRequest.systemTypeDropdown.data" },
    { STATE_REDUCER_KEY: "serviceRequest.systemStatusDropdown.data" }
  ],
  fieldData: [
    {
      name: "systemId",
      label: "Type Of system",
      onChange: []
    },
    {
      name: "statusId",
      label: "System Status"
    }
  ]
};

const CreatePage = (props) => {
  const { locationId: location } = useSelector((state) => _.get(state, 'form.CreateForm.values', ""));
  let { statusId, ticketId } = useParams();

  const {
    change,
    handleSubmit,
    reset,
    districtList = [],
    actionRequiredList = [],
    locationList = [],
    initialValues = [],
    userDetails: { userDetails: userData } = {} } = props;

  const { userRoles = [] } = userData;
  const moduleId = userRoles[0]?.moduleId;
  const { districtId, ticketProblems = [] } = initialValues;
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(sliceActions.clear());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ticketId) {
      dispatch(getTicketByIdData(ticketId));
    }
    dispatch(getDistrictData());
    dispatch(getActionRequiredData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketId]);

  useEffect(() => {
    if (districtId) {
      dispatch(getLocationData({ districtId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [districtId]);

  useEffect(() => {
    if (ticketProblems) {
      const problems = ticketProblems?.map((val) => val.problemCategoryId);
      problems.forEach((val) => {
        dispatch(getProblemTypeData({ data: { key: 'problemCategoryId' }, value: val }));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketProblems]);


  const handleSubmitForm = (values) => {
    if (ticketId) {
      dispatch(patchEditTicket({ ticketId, values, statusId }));
    } else {
      dispatch(postTicketData(values));
    }
  };


  return (
    <Card sx={{ height: "calc(95vh - 95px)", overflow: "auto", backgroundColor: "#e6e6e6" }}>
      <Form onSubmit={handleSubmit(handleSubmitForm)}>
        <Container >
          <Grid container spacing={2} sx={{ marginTop: "1%" }}>

            {userRoles[0]?.userRoleId !== USER_ROLE_ID?.DCR_ROLE_ID && userRoles[0]?.userRoleId !== USER_ROLE_ID?.CCR_ROLE_ID &&
              <Grid item xs={12} sm={4} md={4}>
                <Field
                  name="districtId"
                  component={RenderSelectField}
                  label="District *"
                  variant="standard"
                  onChange={(val) => {
                    dispatch(getLocationData({ val }));
                    change('locationId', '');
                  }}
                >
                  {districtList}
                </Field>
              </Grid>}

            {userRoles[0]?.userRoleId !== USER_ROLE_ID?.DCR_ROLE_ID && userRoles[0]?.userRoleId !== USER_ROLE_ID?.CCR_ROLE_ID && <Grid item xs={12} sm={4} md={4}>
              <Field
                name="locationId"
                component={RenderSelectField}
                label="Location *"
                variant="standard"
              >
                {locationList}
              </Field>
            </Grid>}

            {location && userRoles[0]?.userRoleId !== USER_ROLE_ID?.DCR_ROLE_ID && userRoles[0]?.userRoleId !== USER_ROLE_ID?.CCR_ROLE_ID &&
              <Grid item xs={12} sm={4} md={4}>
                <LabelField label="Site Id " />
                <Typography sx={{ marginTop: "6px" }}>{location}</Typography>
              </Grid>}

            <Grid item xs={12} sm={12} md={12}>
              <FieldArray
                name="ticketProblems"
                title="Problem Type"
                variant="standard"
                label="problem"
                formFields={problemFieldArrayFields(change, moduleId)}
                component={RenderLabelFieldArray}
                userId={userRoles[0]?.userRoleId}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Field name="comments" minRows={4} placeholder="Enter Additional problems Quires(if needed)" component={RenderTextArea} style={{ width: "100%" }} />
            </Grid>
            {userRoles[0]?.userRoleId !== USER_ROLE_ID?.DCR_ROLE_ID && userRoles[0]?.userRoleId !== USER_ROLE_ID?.CCR_ROLE_ID && <Grid item xs={12} sm={12} md={12}>
              <FieldArray
                name="systemStatusPayload"
                title="System Status"
                variant="standard"
                formFields={systemFieldArrayFields}
                component={RenderLabelFieldArray}
              />
            </Grid>}

            {userRoles[0]?.userRoleId === USER_ROLE_ID?.SDC_ROLE_ID && <Grid item xs={12} sm={12} md={12}>
              <Field
                name="actionRequiredId"
                component={RenderSelectField}
                label="Action Needed *"
                multiple={true}
                variant="standard"
              >
                {actionRequiredList}
              </Field>
            </Grid>}

            <Grid item xs={12} sm={12} md={12}>
              <Divider><Typography sx={{ fontSize: 25, fontFamily: "Luminari", fontWeight: "bold", color: "#cc5233", textAlign: "center" }}>Additional Information</Typography></Divider>
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <Field
                className="form-control"
                name="contactName"
                component={RenderTextField}
                label="Contact Name"
                variant="standard"
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <Field
                className="form-control"
                name="contactNumber"
                component={RenderTextField}
                label="Contact Number"
                variant="standard"
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <Field
                className="form-control"
                name="contactEmail"
                component={RenderTextField}
                label="Contact Email Address"
                variant="standard"
              />
            </Grid>


            <Grid item xs={6} sx={{ marginTop: "1%" }}>
              <Button
                type="submit"
                sx={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "0%",
                  fontSize: "12px"
                }}
              >
                {ticketId ? "Update" : "Submit"}
              </Button>
            </Grid>
            {ticketId ? null : <Grid item xs={6} sx={{ marginTop: "1%" }}>
              <Button
                type="reset"
                onClick={() => {
                  reset();
                  dispatch(clearLocationValue());
                }}
                sx={{
                  display: "block",
                  marginLeft: "0%",
                  marginRight: "auto",
                  marginBottom: "3%",
                  fontSize: "12px"
                }}
              >
                Reset
              </Button>
            </Grid>}
          </Grid>
        </Container>
      </Form>
    </Card >
  );
};

const mapStateToProps = createStructuredSelector({
  districtList: getDistrictDropdown,
  actionRequiredList: getActionDropdown,
  locationList: getLocationDropdown,
  problemCategory: getProblemCategoryDropdown,
  problemType: getProblemTypeDropdown,
  systemType: getSystemTypeDropdown,
  systemStatus: getSystemStatusDropdown,
  initialValues: getTicketGetById,
  userDetails: getUserDetailsData
});

export default connect(mapStateToProps)(
  reduxForm({
    form: "CreateForm",
    validate,
    enableReinitialize: true
  })(CreatePage)
);
