import React, { useEffect } from "react";
import { createStructuredSelector } from "reselect";
import { connect, useDispatch, useSelector } from "react-redux";
import { MuiComponents } from "../../../../../common/components";
import { Field, Form, reduxForm } from "redux-form";
import { getListUsernameData, getUserRolesData } from "../../selectors";
import { CustomComponents } from "../../../../../common/components";
import validate from "../../validations/AssignFormValidation";
import { clearUserIdValue, getListUserData, getUserRoleData, patchAssignData } from "../../actions";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { STATE_REDUCER_KEY } from "modules/common/Login";

const { Button, Card, Grid, Typography } = MuiComponents;
const { RenderSelectField } = CustomComponents;

const AssignForm = (props) => {
  const { statusId, ticketId } = useParams();

  const { data } = useSelector((state) => _.get(state, `${STATE_REDUCER_KEY}.userDetails`));
  const { userId: usersId } = data;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserRoleData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { handleSubmit, reset, usersName, roleName } = props;

  const userList = usersName.filter((e) => e.id !== usersId);

  const handleSubmitForm = (values) => {
    const { userId } = values;

    const newValues = {
      id: ticketId,
      userId,
      statusId
    };

    dispatch(patchAssignData(newValues));
  };
  return (
    <>
      <Card sx={{ height: "17rem", mt: "2%" }}>
        <Form onSubmit={handleSubmit(handleSubmitForm)}>
          <Typography variant="h6" sx={{ textAlign: "center", mt: "3%" }}>
            Assign Ticket
          </Typography>
          <Grid container spacing={4} sx={{ mt: "1%" }}>
            <Grid item xs={12} sm={1} md={1}></Grid>
            <Grid item xs={12} sm={3} md={5}>
              <Field
                name="assignTo"
                component={RenderSelectField}
                label="Assign To"
                variant="standard"
                onChange={(event) => {
                  dispatch(getListUserData(event));
                }}
              >
                {roleName}
              </Field>
            </Grid>
            <Grid item xs={12} sm={3} md={5}>
              <Field
                name="userId"
                component={RenderSelectField}
                label="Technician Name"
                variant="standard"
              >
                {userList}
              </Field>
            </Grid>
            <Grid item xs={12} sm={1} md={1}></Grid>

            <Grid item xs={12} md={6}>
              <Button
                type="submit"
                sx={{
                  marginLeft: "86%",
                  fontSize: "12px"
                }}
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                onClick={
                  () => {
                    reset();
                    dispatch(clearUserIdValue());
                  }}
                sx={{
                  fontSize: "12px"
                }}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Card>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  usersName: getListUsernameData,
  roleName: getUserRolesData
});

export default connect(mapStateToProps)(
  reduxForm({
    form: "assignForm",
    validate,
    enableReinitialize: true
  })(AssignForm)
);
