import React from 'react';
import { Badge } from '@mui/material';

const CustomBadge = ({ content, max }) => {
    return (
        <Badge color="secondary" badgeContent={content} max={max} />
    );
};

export default CustomBadge;
