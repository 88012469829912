import { getRequest } from "../../../app/axios";
import { API_URL } from "../../../common/apiUrls";
import { ACTION_TYPES } from "./actions";

export const totalTicketCountAPICall = (query) => {
  return {
    url: API_URL.SERVICE_REQUEST.TOTAL_TICKETS_COUNT_DASHBOARD,
    api: getRequest,
    payload: {
      types: [
        ACTION_TYPES.FETCH_TOTAL_TICKETS_COUNT_REQUEST,
        ACTION_TYPES.FETCH_TOTAL_TICKETS_COUNT_SUCCESS,
        ACTION_TYPES.FETCH_TOTAL_TICKETS_COUNT_FAILURE
      ],
      query
    }
  };
};


export const dashboardDataAPICall = (query) => {
  return {
    url: API_URL.SERVICE_REQUEST.DASHBOARD_DATA_URL,
    api: getRequest,
    payload: {
      types: [
        ACTION_TYPES.FETCH_DASHBOARD_DATA_REQUEST,
        ACTION_TYPES.FETCH_DASHBOARD_DATA_SUCCESS,
        ACTION_TYPES.FETCH_DASHBOARD_DATA_FAILURE
      ],
      query
    }
  };
};

export const cameraTicketCountAPICall = (query) => {
  return {
    url: API_URL.SERVICE_REQUEST.CAMERA_TICKETS_COUNT_URL,
    api: getRequest,
    payload: {
      types: [
        ACTION_TYPES.FETCH_CAMERA_TICKETS_COUNT_DATA_REQUEST,
        ACTION_TYPES.FETCH_CAMERA_TICKETS_COUNT_DATA_SUCCESS,
        ACTION_TYPES.FETCH_CAMERA_TICKETS_COUNT_DATA_FAILURE
      ],
      query
    }
  };
};


export const sdcDashboardCountAPICall = (query) => {
  return {
    url: API_URL.SERVICE_REQUEST.SDC_TICKETS_COUNT_FOR_SDC_DASHBOARD_URL,
    api: getRequest,
    payload: {
      types: [
        ACTION_TYPES.FETCH_DASHBOARD_SDC_COUNT_DATA_REQUEST,
        ACTION_TYPES.FETCH_DASHBOARD_SDC_COUNT_DATA_SUCCESS,
        ACTION_TYPES.FETCH_DASHBOARD_SDC_COUNT_DATA_FAILURE
      ],
      query
    }
  };
};

export const ccrTicketCountAPICall = (query) => {
  return {
    url: API_URL.SERVICE_REQUEST.CCR_TICKETS_COUNT_URL,
    api: getRequest,
    payload: {
      types: [
        ACTION_TYPES.FETCH_CCR_TICKETS_COUNT_DATA_REQUEST,
        ACTION_TYPES.FETCH_CCR_TICKETS_COUNT_DATA_SUCCESS,
        ACTION_TYPES.FETCH_CCR_TICKETS_COUNT_DATA_FAILURE
      ],
      query
    }
  };
};

export const ccrDashboardCountAPICall = (query) => {
  return {
    url: API_URL.SERVICE_REQUEST.CCR_TICKETS_COUNT_FOR_CCR_DASHBOARD_URL,
    api: getRequest,
    payload: {
      types: [
        ACTION_TYPES.FETCH_DASHBOARD_CCR_COUNT_DATA_REQUEST,
        ACTION_TYPES.FETCH_DASHBOARD_CCR_COUNT_DATA_SUCCESS,
        ACTION_TYPES.FETCH_DASHBOARD_CCR_COUNT_DATA_FAILURE
      ],
      query
    }
  };
};

export const dcrTicketCountAPICall = (query) => {
  return {
    url: API_URL.SERVICE_REQUEST.DCR_TICKETS_COUNT_URL,
    api: getRequest,
    payload: {
      types: [
        ACTION_TYPES.FETCH_DCR_TICKETS_COUNT_DATA_REQUEST,
        ACTION_TYPES.FETCH_DCR_TICKETS_COUNT_DATA_SUCCESS,
        ACTION_TYPES.FETCH_DCR_TICKETS_COUNT_DATA_FAILURE
      ],
      query
    }
  };
};

export const dcrDashboardCountAPICall = (query) => {
  return {
    url: API_URL.SERVICE_REQUEST.DCR_TICKETS_COUNT_FOR_DCR_DASHBOARD_URL,
    api: getRequest,
    payload: {
      types: [
        ACTION_TYPES.FETCH_DASHBOARD_DCR_COUNT_DATA_REQUEST,
        ACTION_TYPES.FETCH_DASHBOARD_DCR_COUNT_DATA_SUCCESS,
        ACTION_TYPES.FETCH_DASHBOARD_DCR_COUNT_DATA_FAILURE
      ],
      query
    }
  };
};


export const cameraTypeWiseCountAPICall = (query) => {
  return {
    url: API_URL.SERVICE_REQUEST.CAMERA_TYPE_WISE_COUNT_URL,
    api: getRequest,
    payload: {
      types: [
        ACTION_TYPES.FETCH_CAMERA_TYPE_WISE_COUNT_DATA_REQUEST,
        ACTION_TYPES.FETCH_CAMERA_TYPE_WISE_COUNT_DATA_SUCCESS,
        ACTION_TYPES.FETCH_CAMERA_TYPE_WISE_COUNT_DATA_FAILURE
      ],
      query
    }
  };
};


export const fetchItAndNonItCountAPICall = (query) => {
  return {
    url: API_URL.SERVICE_REQUEST.IT_AND_NON_IT_COUNT_URL,
    api: getRequest,
    payload: {
      types: [
        ACTION_TYPES.FETCH_IT_AND_NON_IT_COUNT_DATA_REQUEST,
        ACTION_TYPES.FETCH_IT_AND_NON_IT_COUNT_DATA_SUCCESS,
        ACTION_TYPES.FETCH_IT_AND_NON_IT_COUNT_DATA_FAILURE
      ],
      query
    }
  };
};

