import { all, call, select, takeLatest } from "redux-saga/effects";
import { handleAPIRequest } from "utils/http";
import { ACTION_TYPES } from "./actions";
import { cameraTicketCountAPICall, cameraTypeWiseCountAPICall, ccrDashboardCountAPICall, ccrTicketCountAPICall, dashboardDataAPICall, dcrDashboardCountAPICall, dcrTicketCountAPICall, fetchItAndNonItCountAPICall, sdcDashboardCountAPICall, totalTicketCountAPICall } from "./api";
import { getUserDetailsData } from "../Login/selectors";
import { USER_ROLE_ID } from "../common/constants";

export function* fetchTotalTicketCount(action) {
  let { startDate, endDate, filtered } = action.payload.filterValues;
  yield call(handleAPIRequest, totalTicketCountAPICall, { startDate, endDate, filtered });
}


export function* fetchDashboardData(action) {
  let { filterValues = {} } = action.payload;
  yield call(handleAPIRequest, dashboardDataAPICall, filterValues);
}

export function* fetchCameraTicketCount(action) {
  let { filterValues = {} } = action.payload;
  yield call(handleAPIRequest, cameraTicketCountAPICall, filterValues);
}

export function* fetchSdcDashboardCount(action) {
  let { filterValues = {} } = action.payload;
  yield call(handleAPIRequest, sdcDashboardCountAPICall, filterValues);
}

export function* fetchCcrTicketCount(action) {
  let { filterValues = {} } = action.payload;
  yield call(handleAPIRequest, ccrTicketCountAPICall, filterValues);
}

export function* fetchCcrDashboardCount(action) {
  let { filterValues = {} } = action.payload;

  yield call(handleAPIRequest, ccrDashboardCountAPICall, filterValues);
}

export function* fetchDcrTicketCount(action) {
  let { filterValues = {} } = action.payload;
  let userData = yield select(getUserDetailsData);
  const { userDetails: { userDistrict = [], userRoles = [] } = {} } = userData;
  let districtId = userDistrict[0]?.districtId;
  let request;
  if (userRoles[0].userRoleId === USER_ROLE_ID?.DCR_ROLE_ID) {
    request = { ...filterValues, districtId };
  } else {
    request = { ...filterValues };
  }
  yield call(handleAPIRequest, dcrTicketCountAPICall, request);
}

export function* fetchDcrDashboardCount(action) {
  let { filterValues = {} } = action.payload;
  let userData = yield select(getUserDetailsData);
  const { userDetails: { userDistrict = [], userRoles = [] } = {} } = userData;
  let districtId = userDistrict[0]?.districtId;
  let request;
  if (userRoles[0].userRoleId === USER_ROLE_ID?.DCR_ROLE_ID) {
    request = { ...filterValues, districtId };
  } else {
    request = { ...filterValues };
  }
  yield call(handleAPIRequest, dcrDashboardCountAPICall, request);
}

export function* fetchCameraTypeWiseCount(action) {
  let { filterValues = {} } = action.payload;
  yield call(handleAPIRequest, cameraTypeWiseCountAPICall, filterValues);
}

export function* fetchItAndNonItCount(action) {
  let { filterValues = {} } = action.payload;
  let userData = yield select(getUserDetailsData);
  const { userDetails: { userId = "" } = {} } = userData;
  yield call(handleAPIRequest, fetchItAndNonItCountAPICall, { ...filterValues, userId });
}


export default function* serviceCreatePageSaga() {
  yield all([
    takeLatest(ACTION_TYPES.FETCH_TOTAL_TICKETS_COUNT, fetchTotalTicketCount),
    takeLatest(ACTION_TYPES.FETCH_DASHBOARD_DATA, fetchDashboardData),
    takeLatest(ACTION_TYPES.FETCH_CAMERA_TICKETS_COUNT_DATA, fetchCameraTicketCount),
    takeLatest(ACTION_TYPES.FETCH_CCR_TICKETS_COUNT_DATA, fetchCcrTicketCount),
    takeLatest(ACTION_TYPES.FETCH_DCR_TICKETS_COUNT_DATA, fetchDcrTicketCount),
    takeLatest(ACTION_TYPES.FETCH_CAMERA_TYPE_WISE_COUNT_DATA, fetchCameraTypeWiseCount),
    takeLatest(ACTION_TYPES.FETCH_IT_AND_NON_IT_COUNT_DATA, fetchItAndNonItCount),
    takeLatest(ACTION_TYPES.FETCH_DASHBOARD_DCR_COUNT_DATA, fetchDcrDashboardCount),
    takeLatest(ACTION_TYPES.FETCH_DASHBOARD_CCR_COUNT_DATA, fetchCcrDashboardCount),
    takeLatest(ACTION_TYPES.FETCH_DASHBOARD_SDC_COUNT_DATA, fetchSdcDashboardCount)

  ]);
}
