import PasswordField from "./PasswordField";
import RenderTextField from "./TextField";
import OtpInputField from "./OtpInputField";
import RenderSelectField from "./Dropdown";
import RenderFormHelper from "./ErrorHelperText";
import LabelField from "./LabelField";
import DateField from "./DateField";
import Filter from "./Filter";
import RenderLabelFieldArray from "./FieldArray";
import RenderTextArea from "./TextArea";

const FormComponents = {
  PasswordField,
  RenderTextField,
  OtpInputField,
  RenderSelectField,
  RenderFormHelper,
  LabelField,
  DateField,
  RenderLabelFieldArray,
  Filter,
  RenderTextArea
};
export default FormComponents;
