import { deleteRequest, getFile, getRequest, patchRequest, postRequest } from "../../../app/axios";
import { API_URL } from "../../../common/apiUrls";
import { ACTION_TYPES } from "./actions";

export const districtAPICall = (query = {}) => {
  return {
    url: API_URL.SERVICE_REQUEST.DISTRICT_LIST_URL,
    api: getRequest,
    payload: {
      types: [
        ACTION_TYPES.FETCH_DISTRICT_DETAILS_REQUEST,
        ACTION_TYPES.FETCH_DISTRICT_DETAILS_SUCCESS,
        ACTION_TYPES.FETCH_DISTRICT_DETAILS_FAILURE
      ],
      query
    }
  };
};

export const locationAPICall = (query = {}) => {
  return {
    url: API_URL.SERVICE_REQUEST.LOCATION_LIST_URL,
    api: getRequest,
    payload: {
      types: [
        ACTION_TYPES.FETCH_LOCATION_DETAILS_REQUEST,
        ACTION_TYPES.FETCH_LOCATION_DETAILS_SUCCESS,
        ACTION_TYPES.FETCH_LOCATION_DETAILS_FAILURE
      ],
      query

    }
  };
};

export const actionRequiredAPICall = (query = {}) => {
  return {
    url: API_URL.SERVICE_REQUEST.ACTION_REQUIRED_URL,
    api: getRequest,
    payload: {
      types: [
        ACTION_TYPES.FETCH_ACTION_DETAILS_REQUEST,
        ACTION_TYPES.FETCH_ACTION_DETAILS_SUCCESS,
        ACTION_TYPES.FETCH_ACTION_DETAILS_FAILURE
      ],
      query
    }
  };
};

export const problemCategoryAPICall = (query = {}) => {
  return {
    url: API_URL.SERVICE_REQUEST.PROBLEM_CATEGORY_URL,
    api: getRequest,
    payload: {
      types: [
        ACTION_TYPES.FETCH_PROBLEM_CATEGORY_DETAILS_REQUEST,
        ACTION_TYPES.FETCH_PROBLEM_CATEGORY_DETAILS_SUCCESS,
        ACTION_TYPES.FETCH_PROBLEM_CATEGORY_DETAILS_FAILURE
      ],
      query
    }
  };
};

export const problemTypeAPICall = ({ id }) => {
  return {
    url: API_URL.SERVICE_REQUEST.PROBLEM_TYPE_URL.replace(
      ":problemsCategoryId",
      id
    ),
    api: getRequest,
    payload: {
      types: [
        ACTION_TYPES.FETCH_PROBLEM_TYPE_DETAILS_REQUEST,
        ACTION_TYPES.FETCH_PROBLEM_TYPE_DETAILS_SUCCESS,
        ACTION_TYPES.FETCH_PROBLEM_TYPE_DETAILS_FAILURE
      ]
    }
  };
};


export const problemTypeFilterAPICall = (query = {}) => {
  return {
    url: API_URL.SERVICE_REQUEST.PROBLEM_TYPE_FILTER_URL,
    api: getRequest,
    payload: {
      types: [
        ACTION_TYPES.FETCH_PROBLEM_TYPE_FILTER_DETAILS_REQUEST,
        ACTION_TYPES.FETCH_PROBLEM_TYPE_FILTER_DETAILS_SUCCESS,
        ACTION_TYPES.FETCH_PROBLEM_TYPE_FILTER_DETAILS_FAILURE
      ],
      query
    }
  };
};


export const systemTypeAPICall = (query = {}) => {
  return {
    url: API_URL.SERVICE_REQUEST.SYSTEM_TYPE_URL,
    api: getRequest,
    payload: {
      types: [
        ACTION_TYPES.FETCH_SYSTEM_TYPE_DETAILS_REQUEST,
        ACTION_TYPES.FETCH_SYSTEM_TYPE_DETAILS_SUCCESS,
        ACTION_TYPES.FETCH_SYSTEM_TYPE_DETAILS_FAILURE
      ],
      query
    }
  };
};

export const systemStatusAPICall = (query = {}) => {
  return {
    url: API_URL.SERVICE_REQUEST.SYSTEM_STATUS_URL,
    api: getRequest,
    payload: {
      types: [
        ACTION_TYPES.FETCH_SYSTEM_STATUS_DETAILS_REQUEST,
        ACTION_TYPES.FETCH_SYSTEM_STATUS_DETAILS_SUCCESS,
        ACTION_TYPES.FETCH_SYSTEM_STATUS_DETAILS_FAILURE
      ],
      query
    }
  };
};

export const CreatePagePostAPICall = (body = {}) => {
  return {
    url: API_URL.SERVICE_REQUEST.POST_URL,
    api: postRequest,
    payload: {
      types: [
        ACTION_TYPES.TICKET_POST_REQUEST,
        ACTION_TYPES.TICKET_POST_SUCCESS,
        ACTION_TYPES.TICKET_POST_FAILURE
      ],
      body
    }
  };
};

export const CreateControlRoomPostAPICall = (body = {}) => {
  return {
    url: API_URL.SERVICE_REQUEST.CONTROL_ROOM_POST_URL,
    api: postRequest,
    payload: {
      types: [
        ACTION_TYPES.CONTROL_ROOM_TICKET_POST_DETAILS_REQUEST,
        ACTION_TYPES.CONTROL_ROOM_TICKET_POST_DETAILS_SUCCESS,
        ACTION_TYPES.CONTROL_ROOM_TICKET_POST_DETAILS_FAILURE
      ],
      body
    }
  };
};

export const AssignDataAPICall = ({ id }) => {
  return {
    url: API_URL.SERVICE_REQUEST.ASSIGNVIEW_URL.replace(":ticketId", id),
    api: getRequest,
    payload: {
      types: [
        ACTION_TYPES.FETCH_ASSIGNDATA_DETAILS_REQUEST,
        ACTION_TYPES.FETCH_ASSIGNDATA_DETAILS_SUCCESS,
        ACTION_TYPES.FETCH_ASSIGNDATA_DETAILS_FAILURE
      ]
    }
  };
};

export const userRoleAPICall = (query = {}) => {
  return {
    url: API_URL.SERVICE_REQUEST.USER_ROLE_URL,
    api: getRequest,
    payload: {
      types: [
        ACTION_TYPES.FETCH_USER_ROLE_DETAILS_REQUEST,
        ACTION_TYPES.FETCH_USER_ROLE_DETAILS_SUCCESS,
        ACTION_TYPES.FETCH_USER_ROLE_DETAILS_FAILURE
      ],
      query
    }
  };
};

export const userNameListAPICall = ({ id }) => {
  return {
    url: API_URL.SERVICE_REQUEST.LIST_USERNAME_URL.replace(":userRoleId", id),
    api: getRequest,
    payload: {
      types: [
        ACTION_TYPES.FETCH_LIST_USERNAME_DETAILS_REQUEST,
        ACTION_TYPES.FETCH_LIST_USERNAME_DETAILS_SUCCESS,
        ACTION_TYPES.FETCH_LIST_USERNAME_DETAILS_FAILURE
      ]
    }
  };
};

export const assignPatchAPICall = ({ id, userId }) => {
  return {
    url: API_URL.SERVICE_REQUEST.ASSIGN_PATCH_URL.replace(":ticketId", id),
    api: patchRequest,
    payload: {
      types: [
        ACTION_TYPES.ASSIGN_PATCH_DETAILS_REQUEST,
        ACTION_TYPES.ASSIGN_PATCH_DETAILS_SUCCESS,
        ACTION_TYPES.ASSIGN_PATCH_DETAILS_FAILURE
      ],
      body: {
        userId
      }
    }
  };
};

// export const totalTicketListApiCall = (query = {}) => {
//   console.log("query", query)
//   return {
//     url: API_URL.SERVICE_REQUEST.TOTAL_TICKET_LIST,
//     api: getRequest,
//     payload: {
//       types: [
//         ACTION_TYPES.FETCH_TOTAL_TICKET_LIST_DETAILS_REQUEST,
//         ACTION_TYPES.FETCH_TOTAL_TICKET_LIST_DETAILS_SUCCESS,
//         ACTION_TYPES.FETCH_TOTAL_TICKET_LIST_DETAILS_FAILURE
//       ],
//       query
//     }
//   };
// };


export const ticketStatusListApiCall = (query = {}) => {
  return {
    url: API_URL.SERVICE_REQUEST.TOTAL_TICKET_LIST,
    api: getRequest,
    payload: {
      types: [
        ACTION_TYPES.FETCH_TICKET_STATUS_LIST_DETAILS_REQUEST,
        ACTION_TYPES.FETCH_TICKET_STATUS_LIST_DETAILS_SUCCESS,
        ACTION_TYPES.FETCH_TICKET_STATUS_LIST_DETAILS_FAILURE
      ],
      query
    }
  };
};

export const ticketHistoryAPICall = ({ id }) => {
  return {
    url: API_URL.SERVICE_REQUEST.TICKET_HISTORY_URL.replace(":ticketId", id),
    api: getRequest,
    payload: {
      types: [
        ACTION_TYPES.FETCH_TICKET_HISTORY_LIST_DETAILS_REQUEST,
        ACTION_TYPES.FETCH_TICKET_HISTORY_LIST_DETAILS_SUCCESS,
        ACTION_TYPES.FETCH_TICKET_HISTORY_LIST_DETAILS_SUCCESS
      ]
    }
  };
};

export const rejectPatchAPICall = ({ ticketId: id, ticketStatusId }) => {
  return {
    url: API_URL.SERVICE_REQUEST.UPDATE_TICKET_STATUS_URL.replace(":ticketId", id),
    api: patchRequest,
    payload: {
      types: [
        ACTION_TYPES.REJECT_PATCH_DETAILS_REQUEST,
        ACTION_TYPES.REJECT_PATCH_DETAILS_SUCCESS,
        ACTION_TYPES.REJECT_PATCH_DETAILS_FAILURE
      ],
      body: {
        ticketStatusId
      }
    }
  };
};

export const acceptPatchAPICall = ({ ticketId: id, ticketStatusId }) => {
  return {
    url: API_URL.SERVICE_REQUEST.UPDATE_TICKET_STATUS_URL.replace(":ticketId", id),
    api: patchRequest,
    payload: {
      types: [
        ACTION_TYPES.ACCEPT_PATCH_DETAILS_REQUEST,
        ACTION_TYPES.ACCEPT_PATCH_DETAILS_SUCCESS,
        ACTION_TYPES.ACCEPT_PATCH_DETAILS_FAILURE
      ],
      body: {
        ticketStatusId
      }
    }
  };
};

export const closePatchAPICall = ({ ticketId: id, ticketStatusId }) => {
  return {
    url: API_URL.SERVICE_REQUEST.UPDATE_TICKET_STATUS_URL.replace(":ticketId", id),
    api: patchRequest,
    payload: {
      types: [
        ACTION_TYPES.CLOSE_PATCH_DETAILS_REQUEST,
        ACTION_TYPES.CLOSE_PATCH_DETAILS_SUCCESS,
        ACTION_TYPES.CLOSE_PATCH_DETAILS_FAILURE
      ],
      body: {
        ticketStatusId
      }
    }
  };
};

export const fetchFileToDownload = ({ url, params, file }) => {
  let payload = {
    types: [
      ACTION_TYPES.DOWNLOAD_FILE_REQUEST,
      ACTION_TYPES.DOWNLOAD_FILE_SUCCESS,
      ACTION_TYPES.DOWNLOAD_FILE_FAILURE
    ],
    params,
    file
  };
  return {
    url,
    api: getFile,
    payload
  };
};

export const ticketDeleteAPICall = ({ ticketId: id }) => {
  return {
    url: API_URL.SERVICE_REQUEST.TICKET_DELETE_URL.replace(":ticketId", id),
    api: deleteRequest,
    payload: {
      types: [
        ACTION_TYPES.DELETE_TICKET_REQUEST,
        ACTION_TYPES.DELETE_TICKET_SUCCESS,
        ACTION_TYPES.DELETE_TICKET_FAILURE
      ]
    }
  };
};

export const fetchTicketByIdApiCall = ({ id }) => {
  return {
    url: API_URL.SERVICE_REQUEST.TICKET_GET_BY_ID_URL.replace(":ticketId", id),
    api: getRequest,
    payload: {
      types: [
        ACTION_TYPES.FETCH_TICKET_BY_ID_DETAILS_REQUEST,
        ACTION_TYPES.FETCH_TICKET_BY_ID_DETAILS_SUCCESS,
        ACTION_TYPES.FETCH_TICKET_BY_ID_DETAILS_FAILURE
      ]
    }
  };
};

export const editTicketPatchAPICall = ({ ticketId: id, values }) => {
  return {
    url: API_URL.SERVICE_REQUEST.EDIT_TICKET_URL.replace(":ticketId", id),
    api: patchRequest,
    payload: {
      types: [
        ACTION_TYPES.EDIT_TICKET_PATCH_DETAILS_REQUEST,
        ACTION_TYPES.EDIT_TICKET_PATCH_DETAILS_SUCCESS,
        ACTION_TYPES.EDIT_TICKET_PATCH_DETAILS_FAILURE
      ],
      body: values

    }
  };
};


export const fetchTicketStatusFilter = (query = {}) => {
  return {
    url: API_URL.SERVICE_REQUEST.TICKET_STATUS_FILTER_URL,
    api: getRequest,
    payload: {
      types: [
        ACTION_TYPES.FETCH_TICKET_STATUS_FILTER_REQUEST,
        ACTION_TYPES.FETCH_TICKET_STATUS_FILTER_SUCCESS,
        ACTION_TYPES.FETCH_TICKET_STATUS_FILTER_FAILURE
      ],
      query
    }
  };
};


export const controlRoomPostAssignAPICall = (body = {}) => {
  return {
    url: API_URL.SERVICE_REQUEST.CONTROL_ROOM_TICKET_ASSIGN_URL,
    api: postRequest,
    payload: {
      types: [
        ACTION_TYPES.POST_TICKET_STATUS_ASSIGN_REQUEST,
        ACTION_TYPES.POST_TICKET_STATUS_ASSIGN_SUCCESS,
        ACTION_TYPES.POST_TICKET_STATUS_ASSIGN_FAILURE
      ],
      body
    }
  };
};

export const controlRoomPostUnderAnalysisAPICall = (body = {}) => {
  return {
    url: API_URL.SERVICE_REQUEST.CONTROL_ROOM_TICKET_UNDER_ANALYSIS_URL,
    api: postRequest,
    payload: {
      types: [
        ACTION_TYPES.POST_TICKET_STATUS_UNDER_ANALYSIS_REQUEST,
        ACTION_TYPES.POST_TICKET_STATUS_UNDER_ANALYSIS_SUCCESS,
        ACTION_TYPES.POST_TICKET_STATUS_UNDER_ANALYSIS_FAILURE
      ],
      body
    }
  };
};

export const controlRoomPostApprovedAPICall = (body = {}) => {
  return {
    url: API_URL.SERVICE_REQUEST.CONTROL_ROOM_ACCEPT_URL,
    api: postRequest,
    payload: {
      types: [
        ACTION_TYPES.POST_TICKET_STATUS_ACCEPT_REQUEST,
        ACTION_TYPES.POST_TICKET_STATUS_ACCEPT_SUCCESS,
        ACTION_TYPES.POST_TICKET_STATUS_ACCEPT_FAILURE
      ],
      body
    }
  };
};

export const controlRoomPostRejectAPICall = (body = {}) => {
  return {
    url: API_URL.SERVICE_REQUEST.CONTROL_ROOM_REJECT_URL,
    api: postRequest,
    payload: {
      types: [
        ACTION_TYPES.POST_TICKET_STATUS_REJECT_REQUEST,
        ACTION_TYPES.POST_TICKET_STATUS_REJECT_SUCCESS,
        ACTION_TYPES.POST_TICKET_STATUS_REJECT_FAILURE
      ],
      body
    }
  };
};

export const controlRoomPostWipAPICall = (body = {}) => {
  return {
    url: API_URL.SERVICE_REQUEST.CONTROL_ROOM_WORK_IN_PROGRESS_URL,
    api: postRequest,
    payload: {
      types: [
        ACTION_TYPES.POST_TICKET_STATUS_WIP_REQUEST,
        ACTION_TYPES.POST_TICKET_STATUS_WIP_SUCCESS,
        ACTION_TYPES.POST_TICKET_STATUS_WIP_FAILURE
      ],
      body
    }
  };
};

export const controlRoomPostCompletedAPICall = (body = {}) => {
  return {
    url: API_URL.SERVICE_REQUEST.CONTROL_ROOM_COMPLETED_URL,
    api: postRequest,
    payload: {
      types: [
        ACTION_TYPES.POST_TICKET_STATUS_COMPLETED_REQUEST,
        ACTION_TYPES.POST_TICKET_STATUS_COMPLETED_SUCCESS,
        ACTION_TYPES.POST_TICKET_STATUS_COMPLETED_FAILURE
      ],
      body
    }
  };
};

export const controlRoomPostResolvedAPICall = (body = {}) => {
  return {
    url: API_URL.SERVICE_REQUEST.CONTROL_ROOM_RESOLVED_URL,
    api: postRequest,
    payload: {
      types: [
        ACTION_TYPES.POST_TICKET_STATUS_RESOLVED_REQUEST,
        ACTION_TYPES.POST_TICKET_STATUS_RESOLVED_SUCCESS,
        ACTION_TYPES.POST_TICKET_STATUS_RESOLVED_FAILURE
      ],
      body
    }
  };
};

export const controlRoomPostReassignAPICall = (body = {}) => {
  return {
    url: API_URL.SERVICE_REQUEST.CONTROL_ROOM_REASSIGN_URL,
    api: postRequest,
    payload: {
      types: [
        ACTION_TYPES.POST_TICKET_STATUS_REASSIGN_REQUEST,
        ACTION_TYPES.POST_TICKET_STATUS_REASSIGN_SUCCESS,
        ACTION_TYPES.POST_TICKET_STATUS_REASSIGN_FAILURE
      ],
      body
    }
  };
};

export const fetchSlaReportDetailsAPICall = (query = {}) => {
  return {
    url: API_URL.SERVICE_REQUEST.SLA_REPORT_DETAILS_CONTROL_ROOM_URL,
    api: getRequest,
    payload: {
      types: [
        ACTION_TYPES.FETCH_SLA_REPORT_DETAILS_REQUEST,
        ACTION_TYPES.FETCH_SLA_REPORT_DETAILS_SUCCESS,
        ACTION_TYPES.FETCH_SLA_REPORT_DETAILS_FAILURE
      ],
      query
    }
  };
};
