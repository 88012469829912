import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";

const initialState = {
    totalTicketsCount: {
        data: []
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        clear: (state) => {
            // eslint-disable-next-line no-unused-vars
            state = initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.FETCH_TOTAL_TICKETS_COUNT_REQUEST, (state) => {
                state.totalTicketsCount.loading = true;
            })
            .addCase(
                ACTION_TYPES.FETCH_TOTAL_TICKETS_COUNT_SUCCESS,
                (state, action) => {
                    state.totalTicketsCount.data = _.get(action, "payload.response.payLoad", []);
                }
            );
    }
});

export const { actions, reducer } = slice;
