import { postRequest } from "../../../app/axios";
import { API_URL } from "../../../common/apiUrls";
import { ACTION_TYPES } from "./actions";


export const LoginPostCall = (body={}) => {
    return {
        url: API_URL.LOGIN_POST.POST_URL,
        api: postRequest,
        payload: {
            types: [ACTION_TYPES.LOGIN_POST_REQUEST, ACTION_TYPES.LOGIN_POST_SUCCESS, ACTION_TYPES.LOGIN_POST_FAILURE],
            body
        }
    };
};
