
export const HTTP_CONSTANTS = {
  BASE_URL: "http://103.73.188.230:9443",
  //BASE_URL: "http://192.168.134.160:9443",

  HTTP_HEADERS: {
    "Content-Type": "application/json",
    Accept: "application/json"
  }
};


export const FORM_CONTROL_STYLE = { display: "flex", flexDirection: "column", position: "relative", pb: 1.5 };

export default function validate(values) {
  const errors = {};
  if (!values.comments) {
    errors.comments = "please enter your comments";
  }
  return errors;
}
