import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { createHashRouter, Outlet, RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { persistor, store } from "./app/store";
import routes from "./modules/routes";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { theme } from "./assets/theme/base/theme";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { CustomComponents } from "./common/components";
import "./index.css";
import "./i18n";
import { PersistGate } from "redux-persist/integration/react";


const { ReactNotifications } = CustomComponents;
const container = document.getElementById("root");
const root = createRoot(container);
const renderLoader = () => <p>Loading</p>;

root.render(
  //<React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <ReactNotifications />
        <Suspense fallback={renderLoader()}>
          <RouterProvider router={createHashRouter(routes)}></RouterProvider>
          <Outlet />
        </Suspense>
      </ThemeProvider>
    </PersistGate>
  </Provider >
  // </React.StrictMode >
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
