import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {
  FETCH_DISTRICT_DETAILS: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DETAILS`,

  FETCH_DISTRICT_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DETAILS_REQUEST`,
  FETCH_DISTRICT_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DETAILS_SUCCESS`,
  FETCH_DISTRICT_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_DETAILS_FAILURE`,

  FETCH_LOCATION_DETAILS: `${STATE_REDUCER_KEY}/FETCH_LOCATION_DETAILS`,

  FETCH_LOCATION_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_LOCATION_DETAILS_REQUEST`,
  FETCH_LOCATION_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_LOCATION_DETAILS_SUCCESS`,
  FETCH_LOCATION_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_LOCATION_DETAILS_FAILURE`,

  FETCH_ACTION_DETAILS: `${STATE_REDUCER_KEY}/FETCH_ACTION_DETAILS`,

  FETCH_ACTION_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ACTION_DETAILS_REQUEST`,
  FETCH_ACTION_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ACTION_DETAILS_SUCCESS`,
  FETCH_ACTION_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ACTION_DETAILS_FAILURE`,

  FETCH_PROBLEM_CATEGORY_DETAILS: `${STATE_REDUCER_KEY}/FETCH_PROBLEM_CATEGORY_DETAILS`,

  FETCH_PROBLEM_CATEGORY_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_PROBLEM_CATEGORY_DETAILS_REQUEST`,
  FETCH_PROBLEM_CATEGORY_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_PROBLEM_CATEGORY_DETAILS_SUCCESS`,
  FETCH_PROBLEM_CATEGORY_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_PROBLEM_CATEGORY_DETAILS_FAILURE`,

  FETCH_PROBLEM_TYPE_DETAILS: `${STATE_REDUCER_KEY}/FETCH_PROBLEM_TYPE_DETAILS`,

  FETCH_PROBLEM_TYPE_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_PROBLEM_TYPE_DETAILS_REQUEST`,
  FETCH_PROBLEM_TYPE_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_PROBLEM_TYPE_DETAILS_SUCCESS`,
  FETCH_PROBLEM_TYPE_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_PROBLEM_TYPE_DETAILS_FAILURE`,

  FETCH_PROBLEM_TYPE_FILTER_DETAILS: `${STATE_REDUCER_KEY}/FETCH_PROBLEM_TYPE_FILTER_DETAILS`,

  FETCH_PROBLEM_TYPE_FILTER_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_PROBLEM_TYPE_FILTER_DETAILS_REQUEST`,
  FETCH_PROBLEM_TYPE_FILTER_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_PROBLEM_TYPE_FILTER_DETAILS_SUCCESS`,
  FETCH_PROBLEM_TYPE_FILTER_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_PROBLEM_TYPE_FILTER_DETAILS_FAILURE`,

  FETCH_SYSTEM_TYPE_DETAILS: `${STATE_REDUCER_KEY}/FETCH_SYSTEM_TYPE_DETAILS`,

  FETCH_SYSTEM_TYPE_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SYSTEM_TYPE_DETAILS_REQUEST`,
  FETCH_SYSTEM_TYPE_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SYSTEM_TYPE_DETAILS_SUCCESS`,
  FETCH_SYSTEM_TYPE_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SYSTEM_TYPE_DETAILS_FAILURE`,

  FETCH_SYSTEM_STATUS_DETAILS: `${STATE_REDUCER_KEY}/FETCH_SYSTEM_STATUS_DETAILS`,

  FETCH_SYSTEM_STATUS_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SYSTEM_STATUS_DETAILS_REQUEST`,
  FETCH_SYSTEM_STATUS_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SYSTEM_STATUS_DETAILS_SUCCESS`,
  FETCH_SYSTEM_STATUS_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SYSTEM_STATUS_DETAILS_FAILURE`,

  TICKET_POST_DETAILS: `${STATE_REDUCER_KEY}/TICKET_POST_DETAILS`,

  TICKET_POST_REQUEST: `${STATE_REDUCER_KEY}/TICKET_POST_REQUEST`,
  TICKET_POST_SUCCESS: `${STATE_REDUCER_KEY}/TICKET_POST_SUCCESS`,
  TICKET_POST_FAILURE: `${STATE_REDUCER_KEY}/TICKET_POST_FAILURE`,

  CONTROL_ROOM_TICKET_POST_DETAILS: `${STATE_REDUCER_KEY}/CONTROL_ROOM_TICKET_POST_DETAILS`,

  CONTROL_ROOM_TICKET_POST_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/CONTROL_ROOM_TICKET_POST_DETAILS_REQUEST`,
  CONTROL_ROOM_TICKET_POST_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/CONTROL_ROOM_TICKET_POST_DETAILS_SUCCESS`,
  CONTROL_ROOM_TICKET_POST_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/CONTROL_ROOM_TICKET_POST_DETAILS_FAILURE`,

  FETCH_TICKET_BY_ID_DETAILS: `${STATE_REDUCER_KEY}/FETCH_TICKET_BY_ID_DETAILS`,

  FETCH_TICKET_BY_ID_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_TICKET_BY_ID_DETAILS_REQUEST`,
  FETCH_TICKET_BY_ID_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_TICKET_BY_ID_DETAILS_SUCCESS`,
  FETCH_TICKET_BY_ID_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_TICKET_BY_ID_DETAILS_FAILURE`,

  EDIT_TICKET_PATCH_DETAILS: `${STATE_REDUCER_KEY}/EDIT_TICKET_PATCH_DETAILS`,

  EDIT_TICKET_PATCH_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/EDIT_TICKET_PATCH_DETAILS_REQUEST`,
  EDIT_TICKET_PATCH_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/EDIT_TICKET_PATCH_DETAILS_SUCCESS`,
  EDIT_TICKET_PATCH_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/EDIT_TICKET_PATCH_DETAILS_FAILURE`,

  FETCH_ASSIGNDATA_DETAILS: `${STATE_REDUCER_KEY}/FETCH_ASSIGNDATA_DETAILS`,

  FETCH_ASSIGNDATA_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_ASSIGNDATA_DETAILS_REQUEST`,
  FETCH_ASSIGNDATA_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_ASSIGNDATA_DETAILS_SUCCESS`,
  FETCH_ASSIGNDATA_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_ASSIGNDATA_DETAILS_FAILURE`,

  FETCH_USER_ROLE_DETAILS: `${STATE_REDUCER_KEY}/FETCH_USER_ROLE_DETAILS`,

  FETCH_USER_ROLE_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_USER_ROLE_DETAILS_REQUEST`,
  FETCH_USER_ROLE_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_USER_ROLE_DETAILS_SUCCESS`,
  FETCH_USER_ROLE_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_USER_ROLE_DETAILS_FAILURE`,

  FETCH_LIST_USERNAME_DETAILS: `${STATE_REDUCER_KEY}/FETCH_LIST_USERNAME_DETAILS`,

  FETCH_LIST_USERNAME_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_LIST_USERNAME_DETAILS_REQUEST`,
  FETCH_LIST_USERNAME_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_LIST_USERNAME_DETAILS_SUCCESS`,
  FETCH_LIST_USERNAME_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_LIST_USERNAME_DETAILS_FAILURE`,

  ASSIGN_PATCH_DETAILS: `${STATE_REDUCER_KEY}/ASSIGN_PATCH_DETAILS`,

  ASSIGN_PATCH_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/ASSIGN_PATCH_DETAILS_REQUEST`,
  ASSIGN_PATCH_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/ASSIGN_PATCH_DETAILS_SUCCESS`,
  ASSIGN_PATCH_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/ASSIGN_PATCH_DETAILS_FAILURE`,

  // FETCH_TOTAL_TICKET_LIST_DETAILS: `${STATE_REDUCER_KEY}/FETCH_TOTAL_TICKET_LIST_DETAILS`,

  // FETCH_TOTAL_TICKET_LIST_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_TOTAL_TICKET_LIST_DETAILS_REQUEST`,
  // FETCH_TOTAL_TICKET_LIST_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_TOTAL_TICKET_LIST_DETAILS_SUCCESS`,
  // FETCH_TOTAL_TICKET_LIST_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_TOTAL_TICKET_LIST_DETAILS_FAILURE`,

  FETCH_TICKET_STATUS_LIST_DETAILS: `${STATE_REDUCER_KEY}/FETCH_TICKET_STATUS_LIST_DETAILS`,

  FETCH_TICKET_STATUS_LIST_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_TICKET_STATUS_LIST_DETAILS_REQUEST`,
  FETCH_TICKET_STATUS_LIST_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_TICKET_STATUS_LIST_DETAILS_SUCCESS`,
  FETCH_TICKET_STATUS_LIST_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_TICKET_STATUS_LIST_DETAILS_FAILURE`,

  FETCH_TICKET_HISTORY_LIST_DETAILS: `${STATE_REDUCER_KEY}/FETCH_TICKET_HISTORY_LIST_DETAILS`,

  FETCH_TICKET_HISTORY_LIST_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_TICKET_HISTORY_LIST_DETAILS_REQUEST`,
  FETCH_TICKET_HISTORY_LIST_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_TICKET_HISTORY_LIST_DETAILS_SUCCESS`,
  FETCH_TICKET_HISTORY_LIST_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_TICKET_HISTORY_LIST_DETAILS_FAILURE`,

  REJECT_PATCH_DETAILS: `${STATE_REDUCER_KEY}/REJECT_PATCH_DETAILS`,

  REJECT_PATCH_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/REJECT_PATCH_DETAILS_REQUEST`,
  REJECT_PATCH_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/REJECT_PATCH_DETAILS_SUCCESS`,
  REJECT_PATCH_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/REJECT_PATCH_DETAILS_FAILURE`,

  ACCEPT_PATCH_DETAILS: `${STATE_REDUCER_KEY}/ACCEPT_PATCH_DETAILS`,

  ACCEPT_PATCH_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/ACCEPT_PATCH_DETAILS_REQUEST`,
  ACCEPT_PATCH_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/ACCEPT_PATCH_DETAILS_SUCCESS`,
  ACCEPT_PATCH_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/ACCEPT_PATCH_DETAILS_FAILURE`,

  CLOSE_PATCH_DETAILS: `${STATE_REDUCER_KEY}/CLOSE_PATCH_DETAILS`,

  CLOSE_PATCH_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/CLOSE_PATCH_DETAILS_REQUEST`,
  CLOSE_PATCH_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/CLOSE_PATCH_DETAILS_SUCCESS`,
  CLOSE_PATCH_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/CLOSE_PATCH_DETAILS_FAILURE`,


  DOWNLOAD_FILE: `${STATE_REDUCER_KEY}/DOWNLOAD_FILE`,

  DOWNLOAD_FILE_REQUEST: `${STATE_REDUCER_KEY}/DOWNLOAD_FILE_REQUEST`,
  DOWNLOAD_FILE_SUCCESS: `${STATE_REDUCER_KEY}/DOWNLOAD_FILE_SUCCESS`,
  DOWNLOAD_FILE_FAILURE: `${STATE_REDUCER_KEY}/DOWNLOAD_FILE_FAILURE`,

  DELETE_TICKET: `${STATE_REDUCER_KEY}/DELETE_TICKET`,

  DELETE_TICKET_REQUEST: `${STATE_REDUCER_KEY}/DELETE_TICKET_REQUEST`,
  DELETE_TICKET_SUCCESS: `${STATE_REDUCER_KEY}/DELETE_TICKET_SUCCESS`,
  DELETE_TICKET_FAILURE: `${STATE_REDUCER_KEY}/DELETE_TICKET_FAILURE`,

  FETCH_TICKET_STATUS_FILTER: `${STATE_REDUCER_KEY}/FETCH_TICKET_STATUS_FILTER`,

  FETCH_TICKET_STATUS_FILTER_REQUEST: `${STATE_REDUCER_KEY}/FETCH_TICKET_STATUS_FILTER_REQUEST`,
  FETCH_TICKET_STATUS_FILTER_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_TICKET_STATUS_FILTER_SUCCESS`,
  FETCH_TICKET_STATUS_FILTER_FAILURE: `${STATE_REDUCER_KEY}/FETCH_TICKET_STATUS_FILTER_FAILURE`,

  POST_TICKET_STATUS_ASSIGN: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_ASSIGN`,

  POST_TICKET_STATUS_ASSIGN_REQUEST: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_ASSIGN_REQUEST`,
  POST_TICKET_STATUS_ASSIGN_SUCCESS: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_ASSIGN_SUCCESS`,
  POST_TICKET_STATUS_ASSIGN_FAILURE: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_ASSIGN_FAILURE`,

  POST_TICKET_STATUS_UNDER_ANALYSIS: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_UNDER_ANALYSIS`,

  POST_TICKET_STATUS_UNDER_ANALYSIS_REQUEST: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_UNDER_ANALYSIS_REQUEST`,
  POST_TICKET_STATUS_UNDER_ANALYSIS_SUCCESS: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_UNDER_ANALYSIS_SUCCESS`,
  POST_TICKET_STATUS_UNDER_ANALYSIS_FAILURE: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_UNDER_ANALYSIS_FAILURE`,

  POST_TICKET_STATUS_ACCEPT: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_ACCEPT`,

  POST_TICKET_STATUS_ACCEPT_REQUEST: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_ACCEPT_REQUEST`,
  POST_TICKET_STATUS_ACCEPT_SUCCESS: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_ACCEPT_SUCCESS`,
  POST_TICKET_STATUS_ACCEPT_FAILURE: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_ACCEPT_FAILURE`,

  POST_TICKET_STATUS_REJECT: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_REJECT`,

  POST_TICKET_STATUS_REJECT_REQUEST: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_REJECT_REQUEST`,
  POST_TICKET_STATUS_REJECT_SUCCESS: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_REJECT_SUCCESS`,
  POST_TICKET_STATUS_REJECT_FAILURE: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_REJECT_FAILURE`,

  POST_TICKET_STATUS_WIP: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_WIP`,

  POST_TICKET_STATUS_WIP_REQUEST: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_WIP_REQUEST`,
  POST_TICKET_STATUS_WIP_SUCCESS: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_WIP_SUCCESS`,
  POST_TICKET_STATUS_WIP_FAILURE: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_WIP_FAILURE`,

  POST_TICKET_STATUS_COMPLETED: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_COMPLETED`,

  POST_TICKET_STATUS_COMPLETED_REQUEST: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_COMPLETED_REQUEST`,
  POST_TICKET_STATUS_COMPLETED_SUCCESS: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_COMPLETED_SUCCESS`,
  POST_TICKET_STATUS_COMPLETED_FAILURE: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_COMPLETED_FAILURE`,

  POST_TICKET_STATUS_RESOLVED: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_RESOLVED`,

  POST_TICKET_STATUS_RESOLVED_REQUEST: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_RESOLVED_REQUEST`,
  POST_TICKET_STATUS_RESOLVED_SUCCESS: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_RESOLVED_SUCCESS`,
  POST_TICKET_STATUS_RESOLVED_FAILURE: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_RESOLVED_FAILURE`,

  POST_TICKET_STATUS_REASSIGN: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_REASSIGN`,

  POST_TICKET_STATUS_REASSIGN_REQUEST: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_REASSIGN_REQUEST`,
  POST_TICKET_STATUS_REASSIGN_SUCCESS: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_REASSIGN_SUCCESS`,
  POST_TICKET_STATUS_REASSIGN_FAILURE: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_REASSIGN_FAILURE`,


  POST_TICKET_STATUS_REASSIGN_TO_OTHER_GROUP: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_REASSIGN_TO_OTHER_GROUP`,

  POST_TICKET_STATUS_REASSIGN_TO_OTHER_GROUP_REQUEST: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_REASSIGN_TO_OTHER_GROUP_REQUEST`,
  POST_TICKET_STATUS_REASSIGN_TO_OTHER_GROUP_SUCCESS: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_REASSIGN_TO_OTHER_GROUP_SUCCESS`,
  POST_TICKET_STATUS_REASSIGN_TO_OTHER_GROUP_FAILURE: `${STATE_REDUCER_KEY}/POST_TICKET_STATUS_REASSIGN_TO_OTHER_GROUP_FAILURE`,

  FETCH_SLA_REPORT_DETAILS: `${STATE_REDUCER_KEY}/FETCH_SLA_REPORT_DETAILS`,

  FETCH_SLA_REPORT_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_SLA_REPORT_DETAILS_REQUEST`,
  FETCH_SLA_REPORT_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_SLA_REPORT_DETAILS_SUCCESS`,
  FETCH_SLA_REPORT_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_SLA_REPORT_DETAILS_FAILURE`,


  CLEAR_LOCATION_VALUE: `${STATE_REDUCER_KEY}/CLEAR_LOCATION_VALUE`,
  CLEAR_USER_ID_VALUE: `${STATE_REDUCER_KEY}/CLEAR_USER_ID_VALUE`

};

export const getDistrictData = createAction(ACTION_TYPES.FETCH_DISTRICT_DETAILS);
export const getLocationData = createAction(ACTION_TYPES.FETCH_LOCATION_DETAILS);
export const getActionRequiredData = createAction(ACTION_TYPES.FETCH_ACTION_DETAILS);
export const getProblemCategoryData = createAction(ACTION_TYPES.FETCH_PROBLEM_CATEGORY_DETAILS);
export const getProblemTypeData = createAction(ACTION_TYPES.FETCH_PROBLEM_TYPE_DETAILS);
export const getProblemTypeFilterData = createAction(ACTION_TYPES.FETCH_PROBLEM_TYPE_FILTER_DETAILS);
export const getSystemTypeData = createAction(ACTION_TYPES.FETCH_SYSTEM_TYPE_DETAILS);
export const getSystemStatusData = createAction(ACTION_TYPES.FETCH_SYSTEM_STATUS_DETAILS);
export const getTicketByIdData = createAction(ACTION_TYPES.FETCH_TICKET_BY_ID_DETAILS);
export const patchEditTicket = createAction(ACTION_TYPES.EDIT_TICKET_PATCH_DETAILS);
export const postTicketData = createAction(ACTION_TYPES.TICKET_POST_DETAILS);
export const postControlRoomDData = createAction(ACTION_TYPES.CONTROL_ROOM_TICKET_POST_DETAILS);
export const getAssignViewData = createAction(ACTION_TYPES.FETCH_ASSIGNDATA_DETAILS);
export const getUserRoleData = createAction(ACTION_TYPES.FETCH_USER_ROLE_DETAILS);
export const getListUserData = createAction(ACTION_TYPES.FETCH_LIST_USERNAME_DETAILS);
export const patchAssignData = createAction(ACTION_TYPES.ASSIGN_PATCH_DETAILS);
//export const getTotalTicketListData = createAction(ACTION_TYPES.FETCH_TOTAL_TICKET_LIST_DETAILS);
export const getTicketStatusList = createAction(ACTION_TYPES.FETCH_TICKET_STATUS_LIST_DETAILS);
export const getTicketHistoryList = createAction(ACTION_TYPES.FETCH_TICKET_HISTORY_LIST_DETAILS);
export const patchRejectData = createAction(ACTION_TYPES.REJECT_PATCH_DETAILS);
export const patchAcceptData = createAction(ACTION_TYPES.ACCEPT_PATCH_DETAILS);
export const patchCloseData = createAction(ACTION_TYPES.CLOSE_PATCH_DETAILS);
export const getDownloadFile = createAction(ACTION_TYPES.DOWNLOAD_FILE);
export const deleteTicket = createAction(ACTION_TYPES.DELETE_TICKET);
export const clearLocationValue = createAction(ACTION_TYPES.CLEAR_LOCATION_VALUE);
export const clearUserIdValue = createAction(ACTION_TYPES.CLEAR_USER_ID_VALUE);
export const getTicketStatusByFilter = createAction(ACTION_TYPES.FETCH_TICKET_STATUS_FILTER);
export const postTicketStatusToAssign = createAction(ACTION_TYPES.POST_TICKET_STATUS_ASSIGN);
export const postTicketStatusToUnderAnalysis = createAction(ACTION_TYPES.POST_TICKET_STATUS_UNDER_ANALYSIS);
export const postTicketStatusToAccept = createAction(ACTION_TYPES.POST_TICKET_STATUS_ACCEPT);
export const postTicketStatusToReject = createAction(ACTION_TYPES.POST_TICKET_STATUS_REJECT);
export const postTicketStatusToWip = createAction(ACTION_TYPES.POST_TICKET_STATUS_WIP);
export const postTicketStatusToCompleted = createAction(ACTION_TYPES.POST_TICKET_STATUS_COMPLETED);
export const postTicketStatusToResolved = createAction(ACTION_TYPES.POST_TICKET_STATUS_RESOLVED);
export const postTicketStatusToReassign = createAction(ACTION_TYPES.POST_TICKET_STATUS_REASSIGN);
export const postTicketStatusReassignToOtherGroup = createAction(ACTION_TYPES.POST_TICKET_STATUS_REASSIGN);
export const getSlaReport = createAction(ACTION_TYPES.FETCH_SLA_REPORT_DETAILS);
