import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import ForgotPassword from "./ForgotPassword";
import EnterOtpNumber from "./EnterOtpNumber";
import SetNewPassword from "./SetNewPassword";

const steps = [
  {
    title: "Enter Email address",
    component: <ForgotPassword />
  },
  {
    title: "Enter 4 Digit otp code from email",
    component: <EnterOtpNumber />
  },
  {
    title: " Create New Password",
    component: <SetNewPassword />
  }
];

const StepperFunction = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  return (
    <Box
      sx={{
        width: "70%",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "5%"
      }}
    >
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map(({ title }, index) => (
          <Step key={title} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {title}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep < 1 ? (
          <div>
            <ForgotPassword />
          </div>
        ) : activeStep === 1 ? (
          <EnterOtpNumber handleNext={handleNext} />
        ) : (
          <SetNewPassword></SetNewPassword>
        )}
      </div>
    </Box>
  );
};

export default StepperFunction;
