import React, { useEffect, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getTicketHistoryList } from '../actions';
import { createStructuredSelector } from "reselect";
import { useParams } from 'react-router-dom';
import ReactTable from 'common/components/custom/ReactTable';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { getTicketHistoryData } from '../selectors';
import _ from 'lodash';
import { STATE_REDUCER_KEY } from "../constants";

const HistoryPage = (props) => {
  const { isLoading = false } = useSelector((state) => _.get(state, `${STATE_REDUCER_KEY}.ticketHistory`));

  const { ticketId } = useParams();
  const { historyData: { ticketDetails = {}, ticketHistory = [] } = {} } = props;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTicketHistoryList(ticketId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const state = {
    isLoading: isLoading
  };

  const initialState = {
    columnVisibility: { 'mrt-row-actions': false }
  };

  const detailColumns = useMemo(
    () => [
      {
        accessorKey: 'ticketId',
        header: 'Ticket Id',
        size: 50
      },
      {
        accessorKey: 'locationId',
        header: 'Site Id',
        size: 50
      },
      {
        accessorKey: 'districtName',
        header: 'District Name',
        size: 50
      },
      {
        accessorKey: 'locationName',
        header: 'Location Name',
        size: 50
      },
      {
        accessorKey: 'problemTypeId',
        header: 'Problem Type',
        accessorFn: ({ problemTypeId }) => {
          let actions = problemTypeId?.map((value) => value.problemTypes);
          let problemTypes = actions?.toString();
          return problemTypes;
        },
        size: 50
      },
      {
        accessorKey: 'actionRequiredId',
        header: 'Action Required',
        accessorFn: ({ actionRequiredId }) => {
          let actions = actionRequiredId?.map((value) => value.actionRequired);
          let actionRequires = actions?.toString();
          return actionRequires;
        },
        size: 50
      },
      {
        accessorKey: 'createdTime',
        header: 'Created Time',
        accessorFn: (row) => row.createdTime ? dayjs(row.createdTime).format('YYYY-MM-DD HH:mm:ss') : null,
        size: 80
      }
    ],
    []
  );


  const historyColumns = useMemo(
    () => [
      {
        accessorKey: 'assigneduserId',
        header: 'Ticket Created By',
        accessorFn: (row) => row?.assigneduserId?.firstName,
        size: 50
      },
      {
        accessorKey: 'actionTakenBy',
        header: 'Action Taken Person',
        accessorFn: (row) => row?.actionTakenBy?.firstName,
        size: 50
      },
      {
        accessorKey: 'actionTypeId',
        header: 'Action Type',
        accessorFn: (row) => row?.actionTypeId?.actionTypeName,
        size: 50
      },
      {
        accessorKey: 'modifiedDate',
        header: 'Modified Date & Time',
        accessorFn: (row) => row.modifiedDate ? dayjs(row.modifiedDate).format('YYYY-MM-DD HH:mm:ss') : null,
        size: 150
      },
      // {
      //   accessorKey: 'reason',
      //   header: 'Reason',
      //   size: 50
      // },
      {
        accessorKey: 'comments',
        header: 'Additional comments',
        size: 50
      }
    ],
    []
  );
  const ToolbarActions = [];

  ToolbarActions.push(
    <Typography sx={{ fontFamily: "sans-serif" }}>Ticket Id:{ticketId}</Typography>
  );
  return (
    <>
      <Typography variant="h6" sx={{ textAlign: "center", marginBottom: "1%" }}>Ticket Details</Typography>
      <ReactTable {...{ data: ticketDetails, columns: detailColumns, initialState, state, enableCustomPagination: false }} /><br></br>
      <Typography variant="h6" sx={{ textAlign: "center", marginBottom: "1%" }}>Ticket History</Typography>
      <ReactTable {...{ data: ticketHistory, columns: historyColumns, initialState, state, enableCustomPagination: false }} />
    </>
  );
};
const mapStateToProps = createStructuredSelector({
  historyData: getTicketHistoryData
});

export default connect(mapStateToProps)(HistoryPage);


