/* eslint-disable camelcase */
import React from 'react';
import MaterialReactTable from 'material-react-table';
import { Box, Grid, InputLabel, MenuItem, Paper, Select, TablePagination } from '@mui/material';
import { COMMON_TABLE_PROPS } from 'modules/common/common/constants';


const getOptions = (totalRecords, pageSize) => {
  let options = [];
  for (let i = 1; i <= Math.ceil(totalRecords / pageSize); i++) {
    options.push(<MenuItem value={i}>{i}</MenuItem>);
  }
  return options;
};


const ReactTable = ({ columns, data, initialState, ToolbarActions, internalActions, displayColumnDefOptions, enableCustomPagination = false,
  handleChangePage, rowsPerPageOptions, handleChangeRowsPerPage = COMMON_TABLE_PROPS.ROWS_PER_PAGE,
  totalRecords, pageSize, pageNo = 0, ...rest }) => {

  return (
    <Paper>
      <MaterialReactTable
        muiTableBodyProps={{
          sx: {
            '& tr:nth-of-type(odd)': {
              backgroundColor: '#f2f2f2'
            }
          }
        }}
        initialState={initialState}
        columns={columns}
        data={data}
        enableColumnActions={false}
        enableGlobalFilter={false}
        enableHiding={false}
        enableColumnFilters={false}
        enableSorting={false}
        showProgressBars={true}
        enableFullScreenToggle={false}
        enableRowActions
        enableStickyHeader
        displayColumnDefOptions={displayColumnDefOptions}
        renderTopToolbarCustomActions={() => < Box sx={{ display: "flex", gap: "1rem", p: "4px" }}>
          {ToolbarActions}
        </Box>
        }
        renderToolbarInternalActions={() => < Box sx={{ display: "flex", gap: "1rem", p: "4px" }}>
          {internalActions}
        </Box>
        }
        enableBottomToolbar={false}
        enableDensityToggle={false}
        manualPagination
        rowCount={pageSize}
        {...rest}
      />
      {
        enableCustomPagination && <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <>
            <InputLabel id="go-to-label">Go to Page </InputLabel>
            <Select
              size="small"
              id="go-to-select"
              value={pageNo + 1}
              onChange={(e) => {
                let pageNumber = e.target.value || 1;
                handleChangePage(e, pageNumber - 1);
              }}
            >
              {getOptions(totalRecords, pageSize)}
            </Select>
          </>
          <TablePagination
            component="div"
            count={totalRecords}
            page={pageNo}
            onPageChange={handleChangePage}
            rowsPerPage={pageSize}
            rowsPerPageOptions={rowsPerPageOptions}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton={true}
            showLastButton={true}
          />
        </Grid>
      }
    </Paper>);
};

export default ReactTable;
