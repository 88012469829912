import React from "react";
import { Field, Form, reduxForm } from "redux-form";
import { MuiComponents, CustomComponents } from "../../../../common/components";
import validate from "../../validations/SetPasswordValidation";
const { RenderTextField } = CustomComponents;

const {
  Grid,
  Card,
  Container,
  FormControl,
  Typography,
  Button
} = MuiComponents;

const handleSubmitForm = () => {

};

const style = {
  cardStyle: {
    p: 2,
    width: "40rem",
    height: "18rem",
    backgroundColor: "secondary.main",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    marginTop: "10%"
  }
};


const SetNewPassword = (props) => {
  const { handleSubmit } = props;
  return (
    <Grid>
      <Form onSubmit={handleSubmit(handleSubmitForm)}>
        <Container maxWidth="md">
          <Card sx={style.cardStyle}>
            <Grid item xs={12}>
              <Container maxWidth="md">
                <Grid container spacing={2}>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={8}>
                    <Typography
                      variant="h5"
                      color="primary.dark"
                      sx={{ textAlign: "center", pt: 2 }}
                    >
                      Set New Password
                    </Typography>
                  </Grid>
                  <Grid item xs={2}></Grid>
                </Grid>
              </Container>
            </Grid>

            <Grid item xs={12}>
              <Container maxWidth="md">
                <Grid container spacing={2}>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={8}>
                    <FormControl
                      sx={{
                        pt: 3,
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto"
                      }}
                    >
                      <Field
                        className="form-control"
                        name="NewPassword"
                        variant="outlined"
                        component={RenderTextField}
                        placeholder="Enter New Password"
                        type="text"
                        sx={{ width: "25rem", backgroundColor: "white" }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}></Grid>
                </Grid>
              </Container>
            </Grid>

            <Grid item xs={12}>
              <Container maxWidth="md">
                <Grid container spacing={2}>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={8}>
                    <FormControl
                      sx={{
                        pt: 4,
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto"
                      }}
                    >
                      <Field
                        className="form-control"
                        name="confirmPassword"
                        variant="outlined"
                        component={RenderTextField}
                        placeholder="Confirm New Password"
                        type="text"
                        sx={{ width: "25rem", backgroundColor: "white" }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}></Grid>
                </Grid>
              </Container>
            </Grid>

            <Grid sx={{ pt: 3 }}>
              <Button
                type="submit"
                sx={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto"
                }}
              >
                confirm
              </Button>
            </Grid>
          </Card>
        </Container>
      </Form>
    </Grid>
  );
};

export default reduxForm({
  form: "new password form",
  validate
})(SetNewPassword);

