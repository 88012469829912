import { getRequest } from "../../../app/axios";
import { API_URL } from "../../../common/apiUrls";
import { ACTION_TYPES } from "./actions";

export const totalTicketCountAPICall = (query = {}) => {
    return {
        url: API_URL.SERVICE_REQUEST.TOTAL_TICKETS_COUNT_DASHBOARD,
        api: getRequest,
        payload: {
            types: [
                ACTION_TYPES.FETCH_TOTAL_TICKETS_COUNT_REQUEST,
                ACTION_TYPES.FETCH_TOTAL_TICKETS_COUNT_SUCCESS,
                ACTION_TYPES.FETCH_TOTAL_TICKETS_COUNT_FAILURE
            ],
            query
        }
    };
};

