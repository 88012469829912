import { getRequest } from "../../../app/axios";
import { API_URL } from "../../../common/apiUrls";
import { ACTION_TYPES } from "./actions";


export const districtWiseCameraCountAPICall = (query) => {
    return {
        url: API_URL.SERVICE_REQUEST.DISTRICT_WISE_CAMERA_COUNT_URL,
        api: getRequest,
        payload: {
            types: [
                ACTION_TYPES.FETCH_DISTRICT_WISE_CAMERA_REQUEST,
                ACTION_TYPES.FETCH_DISTRICT_WISE_CAMERA_SUCCESS,
                ACTION_TYPES.FETCH_DISTRICT_WISE_CAMERA_FAILURE
            ],
            query
        }
    };
};

export const locationWiseCameraDetailsAPICall = (query) => {
    return {
        url: API_URL.SERVICE_REQUEST.CAMERA_LOCATION_WISE_LIST_URL,
        api: getRequest,
        payload: {
            types: [
                ACTION_TYPES.FETCH_LOCATION_WISE_CAMERA_REQUEST,
                ACTION_TYPES.FETCH_LOCATION_WISE_CAMERA_SUCCESS,
                ACTION_TYPES.FETCH_LOCATION_WISE_CAMERA_FAILURE
            ],
            query
        }
    };
};

export const cameraDetailsAPICall = (query) => {
    return {
        url: API_URL.SERVICE_REQUEST.CAMERA_ASSET_DETAILS_URL,
        api: getRequest,
        payload: {
            types: [
                ACTION_TYPES.FETCH_CAMERA_DETAILS_REQUEST,
                ACTION_TYPES.FETCH_CAMERA_DETAILS_SUCCESS,
                ACTION_TYPES.FETCH_CAMERA_DETAILS_FAILURE
            ],
            query
        }
    };
};
