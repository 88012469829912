import { all, call, takeLatest } from "redux-saga/effects";
import { handleAPIRequest } from "utils/http";
import { ACTION_TYPES } from "./actions";
import { totalTicketCountAPICall } from "./api";

export function* fetchTotalTicketCount(action) {
    let request = action.payload;
    yield call(handleAPIRequest, totalTicketCountAPICall, request);
}


export default function* serviceCreatePageSaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_TOTAL_TICKETS_COUNT, fetchTotalTicketCount)

    ]);
}
