export const API_URL = {
  LOGIN_POST: {
    POST_URL: "api/auth/login"
  },

  SERVICE_REQUEST: {
    DISTRICT_LIST_URL: "serviceDesk/listAllDistricts",
    LOCATION_LIST_URL: "serviceDesk/listAllLocations",
    PROBLEM_CATEGORY_URL: "ticket/problem-category/list/all",
    PROBLEM_TYPE_URL: "serviceDesk/listProblemTypes/:problemsCategoryId",
    PROBLEM_TYPE_FILTER_URL: "ticket/problem-type/list/all",
    SYSTEM_TYPE_URL: "serviceDesk/listAllSystemTypes",
    SYSTEM_STATUS_URL: "serviceDesk/listWorkingStatus",
    ACTION_REQUIRED_URL: "serviceDesk/listActionRequired",
    POST_URL: "serviceDesk/saveTicket",
    CONTROL_ROOM_POST_URL: "ticket/control-room/save",
    ASSIGNVIEW_URL: "serviceDesk/assignedTicketDetails/:ticketId",
    USER_ROLE_URL: "serviceDesk/listUserRoles",
    LIST_USERNAME_URL: "serviceDesk/listAllUsersByRole/:userRoleId",
    ASSIGN_PATCH_URL: "serviceDesk/updateTicket/:ticketId",
    TOTAL_TICKET_LIST: "ticket/list/all",
    TOTAL_TICKETS_COUNT_DASHBOARD: "serviceDesk/getTicketCountforSDC",
    TICKET_HISTORY_URL: "serviceDesk/getTicketHistory/:ticketId",
    UPDATE_TICKET_STATUS_URL: "serviceDesk/updateTicketStatus/:ticketId",
    EXCEL_EXPORT_URL: "report/excel-download/tickets/list/all",
    TICKET_DELETE_URL: "serviceDesk/deleteTicket/:ticketId",
    TICKET_GET_BY_ID_URL: "serviceDesk/listEditTicketDetails/:ticketId",
    EDIT_TICKET_URL: "serviceDesk/editTicket/:ticketId",
    DASHBOARD_DATA_URL: "dashboard/list/all",
    TICKET_STATUS_FILTER_URL: "ticket-status/list/all",
    CONTROL_ROOM_TICKET_ASSIGN_URL: "ticket/control-room/assign",
    CONTROL_ROOM_TICKET_UNDER_ANALYSIS_URL: "ticket/control-room/under-analysis",
    CONTROL_ROOM_ACCEPT_URL: "ticket/control-room/approve",
    CONTROL_ROOM_REJECT_URL: "ticket/control-room/reject",
    CONTROL_ROOM_WORK_IN_PROGRESS_URL: "ticket/control-room/work-in-progress",
    CONTROL_ROOM_COMPLETED_URL: "ticket/control-room/completed",
    CONTROL_ROOM_RESOLVED_URL: "ticket/control-room/resolved",
    CONTROL_ROOM_REASSIGN_URL: "ticket/control-room/re-assign",
    CAMERA_TICKETS_COUNT_URL: "dashboard/camera-tickets-count",
    CCR_TICKETS_COUNT_URL: "dashboard/ccr-tickets-count",
    DCR_TICKETS_COUNT_URL: "dashboard/dcr-tickets-count",
    CCR_TICKETS_COUNT_FOR_CCR_DASHBOARD_URL: "dashboard/ccr-tickets-count-statuswise",
    DCR_TICKETS_COUNT_FOR_DCR_DASHBOARD_URL: "dashboard/dcr-tickets-count-statuswise",
    SDC_TICKETS_COUNT_FOR_SDC_DASHBOARD_URL: "dashboard/camera-tickets-count-statuswise",
    CAMERA_TYPE_WISE_COUNT_URL: "dashboard/camera-typewise-count",
    IT_AND_NON_IT_COUNT_URL: "dashboard/user-statuswise-ticket-count",
    DISTRICT_WISE_CAMERA_COUNT_URL: "master/location/list/all/district-wise-camera-cound",
    CAMERA_LOCATION_WISE_LIST_URL: "master/location/list/all/district-wise-camera-details",
    CAMERA_ASSET_DETAILS_URL: "master/location-asset/list/all/camera-details-by-locationid",
    SLA_REPORT_DETAILS_CONTROL_ROOM_URL: "report/control-room/list/all",
    SLA_EXCEL_EXPORT_URL: "report/excel-download/sla-report/list/all"
  }
};


