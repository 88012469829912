import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";

const initialState = {
    userDetails: {
        data: {}
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(
                ACTION_TYPES.LOGIN_POST_SUCCESS,
                (state, action) => {
                    state.userDetails.data = _.get(action, "payload.response.payLoad", []);
                   //console.log("response value", state.userDetails.data)
                }
            );
    }
});

export const { actions, reducer } = slice;
