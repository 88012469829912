import { handleAPIRequest } from "utils/http";
import { ACTION_TYPES } from "./actions";
import { all, call, takeLatest } from "redux-saga/effects";
import { cameraDetailsAPICall, districtWiseCameraCountAPICall, locationWiseCameraDetailsAPICall } from "./api";

export function* fetchDistrictWiseCountData() {
    yield call(handleAPIRequest, districtWiseCameraCountAPICall);
}

export function* fetchLocationWiseData(action) {
    const districtId = action.payload;
    yield call(handleAPIRequest, locationWiseCameraDetailsAPICall, { districtId, moduleId: 1 });
}

export function* fetchCameraDetailsData(action) {
    const { locationId = "" } = action.payload;
    yield call(handleAPIRequest, cameraDetailsAPICall, { locationId });
}


export default function* mapLocationSaga() {
    yield all([
        takeLatest(ACTION_TYPES.FETCH_DISTRICT_WISE_CAMERA_DETAILS, fetchDistrictWiseCountData),
        takeLatest(ACTION_TYPES.FETCH_LOCATION_WISE_CAMERA_DETAILS, fetchLocationWiseData),
        takeLatest(ACTION_TYPES.FETCH_CAMERA_DETAILS, fetchCameraDetailsData)

    ]);
}
