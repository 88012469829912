import MuiAppBar from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import {
  Tooltip,
  Collapse,
  Box,
  Drawer,
  CssBaseline,
  Toolbar,
  List,
  ListItem,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
  Card,
  Container,
  Grid,
  InputAdornment,
  Typography,
  IconButton,
  OutlinedInput,
  FormControl,
  TextField,
  Button,
  Link,
  Switch
} from "@mui/material";

const MuiComponents = {
  Tooltip,
  Collapse,
  styled,
  useTheme,
  Box,
  Drawer,
  CssBaseline,
  MuiAppBar,
  Toolbar,
  List,
  ListItem,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
  Card,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  Typography,
  IconButton,
  Button,
  OutlinedInput,
  TextField,
  Link,
  Switch
};
export default MuiComponents;

