import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { formatPagination } from "modules/common/common/constants";
import { COMMON_TABLE_PROPS } from "modules/common/common/constants";
import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";

const initialState = {

  table: {
    tableProperties: {
      pageNo: 0,
      totalRecords: 0,
      pageSize: COMMON_TABLE_PROPS.ROWS_PER_PAGE[0]
    }
  },

  ticketGetById: {
    data: {}
  },

  assignGetById: {
    requestInProgress: false,
    data: {
      listUsernameData: []
    }
  },
  districtDropdown: {
    data: []
  },
  locationDropdown: {
    data: []
  },
  actionRequiredDropdown: {
    data: []
  },
  problemTypeDropdown: {
    data: []
  },
  problemTypeFilterData: {
    loading: false,
    data: []
  },
  problemCategoryDropdown: {
    data: []
  },
  systemTypeDropdown: {
    data: []
  },
  systemStatusDropdown: {
    data: []
  },

  assignViewData: {
    data: []
  },

  userRoleData: {
    data: []
  },

  listUsernameData: {
    data: []
  },

  locationIdvalue: {
    data: []
  },

  totalTicketsList: {
    data: [],
    isLoading: false
  },
  ticketHistory: {
    isLoading: false,
    data: []
  },
  ticketStatusFilter: {
    data: []
  },
  slaReportDetails: {
    isLoading: false,
    data: []
  }
};
//state.totalTicketsList.isLoading = false;
const slice = createSlice({
  initialState,
  name: STATE_REDUCER_KEY,
  reducers: {
    clear: () => initialState,
    setRowsPerPage: (state, action) => {
      state.table.tableProperties = action.payload;
    },
    setPage: (state, action) => {
      state.table.tableProperties.pageNo = action.payload;
    },
    resetPagination: (state) => {
      state.table.tableProperties = initialState.table.tableProperties;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(ACTION_TYPES.FETCH_TICKET_BY_ID_DETAILS_REQUEST, (state) => {
        state.ticketGetById.loading = true;
      })
      .addCase(
        ACTION_TYPES.FETCH_TICKET_BY_ID_DETAILS_SUCCESS,
        (state, action) => {
          state.ticketGetById.data = _.get(action, "payload.response", []);
        }
      )
      .addCase(ACTION_TYPES.FETCH_DISTRICT_DETAILS_REQUEST, (state) => {
        state.districtDropdown.loading = true;
      })
      .addCase(ACTION_TYPES.FETCH_DISTRICT_DETAILS_SUCCESS, (state, action) => {
        state.districtDropdown.data = _.get(
          action,
          "payload.response.payLoad",
          []
        );
      })
      .addCase(ACTION_TYPES.FETCH_LOCATION_DETAILS_REQUEST, (state) => {
        state.locationDropdown.loading = true;
      })
      .addCase(ACTION_TYPES.FETCH_LOCATION_DETAILS_SUCCESS, (state, action) => {
        state.locationDropdown.data = _.get(
          action,
          "payload.response.payLoad",
          []
        );
      })
      .addCase(ACTION_TYPES.FETCH_ACTION_DETAILS_REQUEST, (state) => {
        state.actionRequiredDropdown.loading = true;
      })
      .addCase(ACTION_TYPES.FETCH_ACTION_DETAILS_SUCCESS, (state, action) => {
        state.actionRequiredDropdown.data = _.get(
          action,
          "payload.response.payLoad",
          []
        );
      })
      .addCase(
        ACTION_TYPES.FETCH_PROBLEM_CATEGORY_DETAILS_REQUEST,
        (state) => {
          state.problemCategoryDropdown.loading = true;
        }
      )
      .addCase(
        ACTION_TYPES.FETCH_PROBLEM_CATEGORY_DETAILS_SUCCESS,
        (state, action) => {
          state.problemCategoryDropdown.data = _.get(
            action,
            "payload.response.payLoad",
            []
          );
        }
      )
      .addCase(
        ACTION_TYPES.FETCH_PROBLEM_TYPE_DETAILS_REQUEST,
        (state) => {
          state.problemTypeDropdown.loading = true;
        }
      )
      .addCase(
        ACTION_TYPES.FETCH_PROBLEM_TYPE_DETAILS_SUCCESS,
        (state, action) => {
          let data = _.get(
            action,
            "payload.response.payLoad.[0]",
            []
          );
          state.problemTypeDropdown.data = {
            ...state.problemTypeDropdown.data,
            [data.id]: data.data
          };
        }
      )
      .addCase(
        ACTION_TYPES.FETCH_PROBLEM_TYPE_FILTER_DETAILS_REQUEST,
        (state) => {
          state.problemTypeFilterData.loading = true;
        }
      )
      .addCase(
        ACTION_TYPES.FETCH_PROBLEM_TYPE_FILTER_DETAILS_SUCCESS,
        (state, action) => {
          state.problemTypeFilterData.data = _.get(
            action,
            "payload.response.payLoad",
            []
          );
        }
      )
      .addCase(
        ACTION_TYPES.FETCH_SYSTEM_TYPE_DETAILS_REQUEST,
        (state) => {
          state.systemTypeDropdown.loading = true;
        }
      )
      .addCase(
        ACTION_TYPES.FETCH_SYSTEM_TYPE_DETAILS_SUCCESS,
        (state, action) => {
          state.systemTypeDropdown.data = _.get(
            action,
            "payload.response.payLoad",
            []
          );
        }
      )
      .addCase(
        ACTION_TYPES.FETCH_SYSTEM_STATUS_DETAILS_REQUEST,
        (state) => {
          state.systemStatusDropdown.loading = true;
        }
      )
      .addCase(
        ACTION_TYPES.FETCH_SYSTEM_STATUS_DETAILS_SUCCESS,
        (state, action) => {
          state.systemStatusDropdown.data = _.get(
            action,
            "payload.response.payLoad",
            []
          );
        }
      )
      .addCase(
        ACTION_TYPES.FETCH_ASSIGNDATA_DETAILS_REQUEST,
        (state) => {
          state.assignViewData.loading = true;
        }
      )
      .addCase(
        ACTION_TYPES.FETCH_ASSIGNDATA_DETAILS_SUCCESS,
        (state, action) => {
          state.assignViewData.data = _.get(
            action,
            "payload.response.payLoad[0]",
            {}
          );
        }
      )
      .addCase(
        ACTION_TYPES.FETCH_USER_ROLE_DETAILS_REQUEST,
        (state) => {
          state.userRoleData.loading = true;
        }
      )
      .addCase(
        ACTION_TYPES.FETCH_USER_ROLE_DETAILS_SUCCESS,
        (state, action) => {
          state.userRoleData.data = _.get(
            action,
            "payload.response.payLoad",
            []
          );
        }
      )
      .addCase(
        ACTION_TYPES.FETCH_LIST_USERNAME_DETAILS_REQUEST,
        (state) => {
          state.listUsernameData.loading = true;
        }
      )
      .addCase(
        ACTION_TYPES.FETCH_LIST_USERNAME_DETAILS_SUCCESS,
        (state, action) => {
          state.listUsernameData.data = _.get(
            action,
            "payload.response.payLoad",
            []
          );
        }
      )
      // .addCase(
      //   ACTION_TYPES.FETCH_TOTAL_TICKET_LIST_DETAILS_REQUEST,
      //   (state) => {
      //     state.totalTicketsList.isLoading = true;
      //   }
      // )
      // .addCase(
      //   ACTION_TYPES.FETCH_TOTAL_TICKET_LIST_DETAILS_SUCCESS,
      //   (state, action) => {
      //     state.table.tableProperties = formatPagination(action.response.payLoad);
      //     console.log("from slice", state.table.tableProperties)
      //     state.totalTicketsList.data = _.get(
      //       action,
      //       "payload.response.payLoad",
      //       []
      //     );
      //     state.totalTicketsList.isLoading = false;
      //   }
      // )
      .addCase(
        ACTION_TYPES.FETCH_TICKET_STATUS_LIST_DETAILS_REQUEST,
        (state) => {
          state.totalTicketsList.isLoading = true;
        }
      )
      .addCase(
        ACTION_TYPES.FETCH_TICKET_STATUS_LIST_DETAILS_SUCCESS,
        (state, action) => {
          state.table.tableProperties = formatPagination(action.payload.response);
          state.totalTicketsList.data = _.get(
            action,
            "payload.response.payLoad",
            []
          );
          state.totalTicketsList.isLoading = false;
        }
      )
      .addCase(
        ACTION_TYPES.FETCH_TICKET_STATUS_LIST_DETAILS_FAILURE,
        (state) => {
          state.totalTicketsList.isLoading = false;
        }
      )
      .addCase(
        ACTION_TYPES.FETCH_TICKET_HISTORY_LIST_DETAILS_REQUEST,
        (state) => {
          state.ticketHistory.isLoading = true;
        }
      )
      .addCase(
        ACTION_TYPES.FETCH_TICKET_HISTORY_LIST_DETAILS_SUCCESS,
        (state, action) => {
          state.ticketHistory.data = _.get(
            action,
            "payload.response.payLoad",
            []
          );
          state.ticketHistory.isLoading = false;
        }
      )
      .addCase(
        ACTION_TYPES.FETCH_TICKET_HISTORY_LIST_DETAILS_FAILURE,
        (state) => {
          state.ticketHistory.isLoading = false;
        }
      )
      .addCase(
        ACTION_TYPES.FETCH_TICKET_STATUS_FILTER_REQUEST,
        (state) => {
          state.ticketStatusFilter.loading = true;
        }
      )
      .addCase(
        ACTION_TYPES.FETCH_TICKET_STATUS_FILTER_SUCCESS,
        (state, action) => {
          state.ticketStatusFilter.data = _.get(
            action,
            "payload.response.payLoad.content",
            []
          );
        }
      )
      .addCase(
        ACTION_TYPES.CLEAR_LOCATION_VALUE,
        (state) => {
          state.locationDropdown.data = [];
        }
      )
      .addCase(
        ACTION_TYPES.CLEAR_USER_ID_VALUE,
        (state) => {
          state.listUsernameData.data = [];
        }
      )
      .addCase(
        ACTION_TYPES.FETCH_SLA_REPORT_DETAILS_REQUEST,
        (state) => {
          state.slaReportDetails.isLoading = true;
        }
      )
      .addCase(
        ACTION_TYPES.FETCH_SLA_REPORT_DETAILS_SUCCESS,
        (state, action) => {
          state.table.tableProperties = formatPagination(action.payload.response);
          state.slaReportDetails.data = _.get(
            action,
            "payload.response.payLoad.content",
            []
          );
          state.slaReportDetails.isLoading = false;
        }
      );
  }
});

export const { actions, reducer } = slice;
