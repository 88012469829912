/* eslint-disable camelcase */
export const STATE_REDUCER_KEY = "common";


export const COMMON_TABLE_PROPS = {
    ROWS_PER_PAGE: [25, 50, 100]
};


export const formatPagination = (response = {}) => {
    const { payLoad = {} } = response;
    //  let pageSize = COMMON_TABLE_PROPS.ROWS_PER_PAGE[0];
    const { totalRecords = 0, pageNo = 0 } = payLoad;
    //console.log("from format function", totalRecords, pageNo)
    return { totalRecords, pageNo };
};

export const USER_ROLE_ID = {
    SDC_ROLE_ID: 4,
    DCR_ROLE_ID: 6,
    CCR_ROLE_ID: 10,
    SERVICE_MANAGER_ROLE_ID: 9,
    DATA_CENTER_GROUP_ROLE_ID: 7,
    PERMIS_SUPPORT_GROUP_ROLE_ID: 8
};

export const SDC_STATUS_ID = {
    OPEN_STATUS_ID: 1,
    PENDING_STATUS_ID: 2,
    WIP_STATUS_ID: 3,
    RESOLVED_STATUS_ID: 4,
    CLOSED_STATUS_ID: 5,
    TRANSFERRED_STATUS_ID: 6,
    VIOLATED_STATUS_ID: 7,
    UNASSIGNED_STATUS_ID: 8
};

export const OTHER_STATUS_ID = {
    PENDING_STATUS_ID: 9,
    ASSIGNED_STATUS_ID: 10,
    UNDER_ANALYSIS_STATUS_ID: 11,
    APPROVED_STATUS_ID: 12,
    REJECTED_STATUS_ID: 13,
    WIP_STATUS_ID: 14,
    RESOLVED_STATUS_ID: 16,
    REASSIGNED_STATUS_ID: 17,
    REASSIGN_TO_OTHER_CATEGORY_STATUS_ID: 18
};
