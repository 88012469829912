import { flow } from "lodash";
import { STATE_REDUCER_KEY } from "./constants";

const getStatusCount = (state) => state[STATE_REDUCER_KEY];

const totalStatusCount = (count) => count?.totalTicketCount?.data;
export const getTotalStatusCount = flow(getStatusCount, totalStatusCount);

const dashboardData = (count) => count?.dashboardData?.data;
export const getDashboardDetails = flow(getStatusCount, dashboardData);

const cameraTicketCount = (count) => count?.cameraTicketCount?.data;
export const getCameraTicketCounts = flow(getStatusCount, cameraTicketCount);

const ccrTicketCount = (count) => count?.ccrTicketCount?.data;
export const getCcrTicketCounts = flow(getStatusCount, ccrTicketCount);

const dcrTicketCount = (count) => count?.dcrTicketCount?.data;
export const getDcrTicketCounts = flow(getStatusCount, dcrTicketCount);

const cameraTypeWiseCount = (count) => count?.cameraTypeWiseCount?.data;
export const getCameraTypeWiseCounts = flow(getStatusCount, cameraTypeWiseCount);

const itAndNonItCount = (count) => count?.itAndNonItCount?.data;
export const getItAndNonItDataCount = flow(getStatusCount, itAndNonItCount);


const sdcDashboardCountData = (count) => count?.sdcDashboardCount?.data;
export const getSdcDashboardDetails = flow(getStatusCount, sdcDashboardCountData);

const ccrDashboardCountData = (count) => count?.ccrDashboardCount?.data;
export const getCcrDashboardDetails = flow(getStatusCount, ccrDashboardCountData);

const dcrDashboardCountData = (count) => count?.dcrDashboardCount?.data;
export const getDcrDashboardDetails = flow(getStatusCount, dcrDashboardCountData);
