import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect, useDispatch } from 'react-redux';
import { MuiComponents } from "../../../../../common/components";
import { getAssignViewData } from '../../actions';
import { getAssignDetails } from '../../selectors';
import { useParams } from 'react-router-dom';

const { Card, Grid, Typography } = MuiComponents;

const AssignView = (props) => {
  const { ticketId } = useParams();
  const { assignDetails } = props;
  const { ticketId: id = '', districtId = '', locationId = {}, location = '' } = assignDetails;
  //const { locationName = " "} = locationId;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAssignViewData(ticketId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card sx={{ height: "13rem" }}>
        <Typography variant="h6" sx={{ textAlign: "center", mt: "3%" }}>
          Ticket Details
        </Typography>
        <Grid container spacing={2} sx={{ mt: "2%" }}>
          <Grid item xs={12} sm={1} md={2}></Grid>
          <Grid item xs={12} sm={2} md={5} >
            <Typography>TicketId: {id}</Typography>
          </Grid>
          <Grid item xs={12} sm={2} md={5} >
            <Typography>District: {districtId.districtName}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: "2%" }}>
          <Grid item xs={12} sm={2} md={2}></Grid>
          <Grid item xs={12} sm={2} md={5}>
            <Typography>Location: {location}</Typography>
          </Grid>
          <Grid item xs={12} sm={2} md={5}>
            <Typography>site Id: {locationId.locationId}</Typography>
          </Grid>

        </Grid>
      </Card>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  assignDetails: getAssignDetails
});


export default connect(mapStateToProps)(AssignView);


