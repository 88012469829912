export default function validate(values) {
  const errors = {};
  if (!values.NewPassword) {
    errors.NewPassword = "please enter your new password";
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = "please confirm your new password";
  }
  return errors;
  }
