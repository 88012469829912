export const STATE_REDUCER_KEY = "dashboard";

const commonConfig = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
        legend: {
            position: 'bottom',
            display: true,
            labels: {
                font: {
                    size: 14
                }
            }
        },
        title: {
            display: false
        },
        tooltip: {
            bodyFont: {
                size: 16
            },
            titleFont: {
                size: 18
            }
        },
        datalabels: {
            anchor: 'end',
            align: 'end',
            font: {
                weight: 'bold'
            }
        }
    },
    layout: {
        padding: {
            left: 50,
            right: 50,
            top: 30,
            bottom: 10
        }
    }
};

export const chartConfig = {
    ...commonConfig,
    datasets: {
        doughnut: {
            backgroundColor: ['#ff3333', '#993333', '#009999', '#00b300', '#202C59', '#F0A202', '#826AED', '#254441', '#99004d']
        }
    }
};

export const sdcChartConfig = {
    ...commonConfig,
    datasets: {
        doughnut: {
            backgroundColor: ['#ff3333', '#993333', '#00b300', '#202C59', '#F0A202', '#826AED', '#254441', '#99004d']
        }
    }
};

export const itNonItChartConfig = {
    ...commonConfig,
    datasets: {
        doughnut: {
            backgroundColor: ['#ff3333', '#993333', '#00b300', '#202C59', '#F0A202', '#826AED', '#254441', '#99004d']
        }
    }
};

export const barConfig = {
    ...commonConfig,
    indexAxis: 'x',
    elements: {
        bar: {
            borderWidth: 2
        }
    },
    datasets: {
        bar: {
            maxBarThickness: 50,
            backgroundColor: ['#D5573B', '#F0A202', '#202C59', '#826AED', '#FF6B6C', '#A5D8FF', '#254441', '#B9D8C2']
        }
    }
};

export const paperStyle = { p: '30px', m: 2, borderRadius: 6, overflow: 'hidden', height: "300%" };


export const Config = {
    indexAxis: 'x',
    scales: {
        yAxes: {
            title: {
                display: true,
                text: 'count',
                font: {
                    size: 12
                }
            },
            ticks: {
                font: {
                    size: 12
                }
            }
        },
        xAxes: {
            ticks: {
                font: {
                    size: 12
                },
                maxRotation: 90,
                minRotation: 90
            }
        }
    }
};


export const formatFunction = (cameraStatusAndCount) => {
    const data = {
        labels: cameraStatusAndCount?.map(({ cameraType }) => cameraType) || [],
        datasets: [
            {
                label: 'UP',
                data: cameraStatusAndCount?.map(({ upCameras }) => upCameras) || [],
                backgroundColor: '#00cc00'
            },
            {
                label: 'DOWN',
                data: cameraStatusAndCount?.map(({ downCameras }) => downCameras) || [],
                backgroundColor: '#ff0000'
            }
        ]
    };
    return { data };
};


export const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
};


export const bgColor = [
    { id: "Approved", color: '#ff3333' },
    { id: "Assigned", color: '#993333' },
    { id: "Reassign To Other Category", color: '#202C59' },
    { id: "Reassigned", color: '#00b300' },
    { id: "Rejected", color: '#F0A202' },
    { id: "Resolved", color: '#826AED' },
    { id: "Under Analysis", color: '#254441' },
    { id: "WIP", color: '#99004d' },
    { id: "Pending", color: '#009999' }
];
export const slaCardColor = [
    { id: "SLA Breached Count", color: "#2c3e50" }
];

export const bgDashboardColor = [
    { id: "WorkInProgress", color: '#ff3333' },
    { id: "Open", color: '#993333' },
    { id: "Transfered", color: '#00b300' },
    { id: "Closed", color: '#202C59' },
    { id: "Violated", color: '#F0A202' },
    { id: "UnAssigned", color: '#826AED' },
    { id: "Resolved", color: '#254441' },
    { id: "Pending", color: '#99004d' }

];


export const ticketCountFormat = (values) => {
    let finalArray = [];

    let totalTicket = values[0]?.totalTickets;
    let closedTicket = values[0]?.closedTickets;
    let openTicket = values[0]?.openTickets;

    finalArray.push({ title: "Total Tickets", value: totalTicket });
    finalArray.push({ title: "Opened Tickets", value: openTicket });
    finalArray.push({ title: "Closed Tickets", value: closedTicket });
    // console.log("finalArray", finalArray)
    return finalArray;
};
