import { FormHelperText } from "@mui/material";
import React from "react";
import OtpInput from "react-otp-input";

const OtpInputField = ({
  input,
  numInputs,
  name,
  meta: { touched, error },
  ...custom
}) => {
  return (
    <div>
      {" "}
      <OtpInput
        {...input}
        {...custom}
        name={name}
        error={touched && error}
        isInputNum={true}
        shouldAutoFocus={true}
        numInputs={numInputs}
        separator={<span style={{ width: "20px" }}></span>}
        inputStyle={{
          border: "1px solid transparent",
          borderRadius: "8px",
          width: "50px",
          height: "50px",
          fontSize: "12px",
          color: "secondary.contrastText",
          fontWeight: "400",
          caretColor: "blue"
        }}
      />
       {!!touched && (
        <FormHelperText error sx={{ position: "absolute" }}>
          {error}
        </FormHelperText>
      )}
    </div>
  );
};

export default OtpInputField;
