import React, { useEffect, useMemo } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getSlaReportDetails } from '../selectors';
import { getDownloadFile, getSlaReport } from '../actions';
import _ from 'lodash';
import { STATE_REDUCER_KEY } from '../constants';
import ReactTable from 'common/components/custom/ReactTable';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { COMMON_TABLE_PROPS } from 'modules/common/common/constants';
import DottedMenu from 'common/components/custom/DottedMenu';
import { MuiIcons } from 'common/components';
import { commonSliceActions } from 'modules/common/common/actions';
import { actions as sliceActions } from "../slice";
import Filter from 'common/components/custom/form-components/Filter';
import { STATE_REDUCER_KEY as COMMON_KEY } from "../../../common/common/constants";
import { API_URL } from 'common/apiUrls';

const { HistoryIcon, FileDownloadIcon } = MuiIcons;

const SlaReportPage = (props) => {
    const dispatch = useDispatch();
    const { slaReportData = {} } = props;
    const { filterValues = {} } = useSelector((state) => _.get(state, `${COMMON_KEY}`));
    const { isLoading = false } = useSelector((state) => _.get(state, `${STATE_REDUCER_KEY}.slaReportDetails`));
    const { table: { tableProperties: { pageNo, totalRecords, pageSize = COMMON_TABLE_PROPS.ROWS_PER_PAGE[0] } = {} } = {} } = useSelector(state => state[STATE_REDUCER_KEY]);

    useEffect(() => {
        dispatch(getSlaReport({ filterValues }));
        return () => {
            dispatch(sliceActions.resetPagination());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValues]);

    const state = {
        isLoading: isLoading
    };

    const initialState = {
        columnOrder: [
            'ticketId',
            'locationId',
            'problemType',
            'ticketStatus',
            'ticketRemark',
            'createdTime',
            'closingComments',
            'closingTime',
            'resolutionTime',
            'mrt-row-actions'
        ]
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: 'ticketId',
                header: 'Ticket Id',
                size: 60
            },
            {
                accessorKey: 'locationId',
                header: 'Location Id',
                size: 60
            },
            {
                accessorKey: 'problemType',
                header: 'Problem Type',
                size: 60
            },
            {
                accessorKey: 'ticketStatus',
                header: 'Ticket Status',
                size: 60
            },

            {
                accessorKey: 'ticketRemark',
                header: 'Remark',
                size: 60
            },
            {
                accessorKey: 'createdTime',
                header: 'Created Time',
                accessorFn: (row) => row.createdTime ? dayjs(row.createdTime).format('YYYY-MM-DD HH:mm:ss') : null,
                size: 60
            },
            {
                accessorKey: 'closingComments',
                header: 'Closing Comments',
                size: 60
            },
            {
                accessorKey: 'closingTime',
                header: 'Closing Time',
                accessorFn: (row) => row.closingTime ? dayjs(row.closingTime).format('YYYY-MM-DD HH:mm:ss') : null,
                size: 60
            },
            {
                accessorKey: 'resolutionTime',
                header: 'Time Taken (Hrs)',
                size: 60
            }
        ],
        []
    );

    let actions = (row) => {
        let customActions = [];
        customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
        return customActions;
    };

    const displayColumnDefOptions = {
        "mrt-row-actions": {
            Cell: ({ row }) => <DottedMenu actions={actions(row)} />
        }
    };

    const ToolbarActions = [];
    const internalActions = [];
    ToolbarActions.push(
        <Typography variant="h6" sx={{ fontFamily: "sans-serif" }}>SLA Report</Typography>
    );

    internalActions.push(
        <Tooltip arrow title="Download Excel">
            <IconButton
                onClick={() => dispatch(getDownloadFile({ url: API_URL.SERVICE_REQUEST.SLA_EXCEL_EXPORT_URL, file: { fileName: "Tickets Report", ext: "xls" }, filter: { filterValues } }))}
            >
                <FileDownloadIcon />
            </IconButton>
        </Tooltip>
    );

    const handleChangePage = (e, newPage) => {
        dispatch(sliceActions.setPage(newPage));
        dispatch(getSlaReport({ filterValues }));
    };

    const handleChangeRowsPerPage = (e) => {
        dispatch(sliceActions.setRowsPerPage({ pageSize: e.target.value, totalRecords: 0, pageNo: 0 }));
        dispatch(getSlaReport({ filterValues }));
    };

    let filterFlags = {
        startDateFlag: true,
        endDateFlag: true,
        ticketIdFlag: false,
        issueTypeFlag: false,
        problemTypeFlag: false,
        problemCategoryFlag: false,
        assignFlag: true,
        districtFlag: false,
        siteIdFlag: true,
        statusFlag: false,
        hourFlag: true
    };

    return (
        <>
            <Grid item xs={12} sm={12} md={12}>
                <Filter filterFlags={filterFlags} />
                <ReactTable {...{
                    data: slaReportData,
                    columns,
                    state,
                    initialState,
                    totalRecords,
                    pageSize,
                    pageNo,
                    displayColumnDefOptions,
                    ToolbarActions,
                    internalActions,
                    handleChangePage,
                    handleChangeRowsPerPage,
                    enableCustomPagination: true
                }} />
            </Grid>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    slaReportData: getSlaReportDetails
});


export default connect(mapStateToProps)(SlaReportPage);


