import { Card, CardContent, Grid, Typography } from "@mui/material";
import Animate from "../Animate";
import { DoubleArrow } from "@mui/icons-material";
import { actions as commonSliceActions } from "../../../modules/common/common/slice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";


const cardStyle = { backgroundColor: '#FFFF', borderRadius: 6, textAlign: 'center', height: "100%", p: 1, border: '2px solid #e9ac7a96' };

const CustomCard = ({ id, label = "", label1 = "", label2 = "",
    label3 = "", label4 = "", label5 = "", total = "", closed = "",
    opened = "", up = "", down = "", count = "", showMoreInfo = false,
    navigateInfo: { dashboardFilters = {} } = {}, navigateTitle = "", slaReport = false, slaPath = "",
    colors = ["#a34129", "#db8670"], path = "/sdc/service-request" }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const hex2rgba = (hex, alpha = 1) => {
        const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
        return `rgba(${r},${g},${b},${alpha})`;
    };

    return (
        <Card sx={{ ...cardStyle }}>
            <CardContent>
                <Grid item xs={12} sx={{ ml: 1 }}>
                    <Typography id={id}> <b>{label}{count}</b></Typography>
                </Grid>
                {label1 && <Grid item xs={12} sx={{ ml: 1 }}>
                    <Typography id={id}> {label1}-{total || 0}</Typography>
                </Grid>}
                {label2 && <Grid item xs={12} sx={{ ml: 1 }}>
                    <Typography id={id}>{label2}={opened || 0}</Typography>
                </Grid>}
                {label3 && <Grid item xs={12} sx={{ ml: 1 }}>
                    <Typography id={id}>{label3}={closed || 0}</Typography>
                </Grid>}
                {label4 && <Grid item xs={12} sx={{ ml: 1 }}>
                    <Typography id={id}>{label4}={up || 0}</Typography>
                </Grid>}
                {label5 && <Grid item xs={12} sx={{ ml: 1 }}>
                    <Typography id={id}>{label5}={down || 0}</Typography>
                </Grid>}
            </CardContent>
            {showMoreInfo && <Grid item xs={12} sx={{
                borderRadius: 3,
                cursor: "pointer",
                color: "white", background: `linear-gradient(45deg, ${hex2rgba(colors[1])}, ${hex2rgba(colors[0])})`
            }}>
                <div
                    onClick={() => {
                        { dashboardFilters && dispatch(commonSliceActions.setCommonFilters(dashboardFilters)); }
                        {
                            slaReport ? navigate({
                                pathname: slaPath
                            }) : navigate({
                                pathname: path,
                                search: `?role=${navigateTitle}`
                            });
                        }
                    }}
                >
                    <Typography align='right' variant='h6' alignItems='center'>
                        <Animate.move x={-15}>
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap"
                            }}>
                                <DoubleArrow />
                                <Typography sx={{ ml: 1, mr: 1, mt: 0.5 }}>More Info</Typography>
                                <DoubleArrow />
                            </div>
                        </Animate.move>
                    </Typography>
                </div>
            </Grid>}
        </Card >
    );
};

export default CustomCard;
