import { createTheme } from "@mui/material/styles";

const palette = {
  primary: {
    light: "#757ce8",
    main: "#000",
    dark: "#002884",
    contrastText: "#fff"
  },
  secondary: {
    light: "#ff7961",
    main: "#D9D9D9",
    dark: "#ba000d",
    contrastText: "#000"
  }
};

export const theme = createTheme({
  palette,

  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(",")
  },

  components: {

    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: "#1a1a1a"
          }
        }
      }
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& :-webkit-autofill': {
            '-webkit-text-fill-color': '#000000',
            '-webkit-box-shadow': '0 0 0 1000px #ffffff inset'
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& :-webkit-autofill': {
            '-webkit-text-fill-color': '#000000',
            '-webkit-box-shadow': '0 0 0 1000px #e6e6e6 inset'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          color: palette.primary.contrastText,
          backgroundColor: palette.primary.main,

          "&:hover": {
            backgroundColor: palette.primary.dark
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: "100%"
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          "@global": {
            "*::-webkit-scrollbar": {
              width: "5px"
            },
            "*::-webkit-scrollbar-track": {
              background: "#E4EFEF"
            },
            "*::-webkit-scrollbar-thumb": {
              background: "#1D388F61",
              borderRadius: "2px"
            }
          }
        }
      }
    }
  }
});
