import axios from "axios";
import queryString from "query-string";
import { HTTP_CONSTANTS } from "../common/constants";

const axiosClient = axios.create({
  headers: HTTP_CONSTANTS.HTTP_HEADERS
});

let baseURL = HTTP_CONSTANTS.BASE_URL;

axiosClient.defaults.withCredentials = false;

export const getRequest = (URL, payload) => {
  let { query = {} } = payload;
  return axiosClient
    .get(queryString.stringifyUrl({ url: `${baseURL}/${URL}`, query }))
    .then((response) => ({
      response
    }))
    .catch((error) => ({ error }));
};

export const postRequest = (URL, payload) => {
  let { body = {} } = payload;
  return axiosClient
    .post(`${baseURL}/${URL}`, body)
    .then((response) => {
      return { response };
    })
    .catch((error) => ({ error }));
};

export const patchRequest = (URL, payload) => {
  let { body = {} } = payload;
  return axiosClient
    .patch(`${baseURL}/${URL}`, body)
    .then((response) => response)
    .catch((error) => ({ error }));
};

export const deleteRequest = (URL, payload) => {
  let { query = {} } = payload;
  return axiosClient
    .delete(queryString.stringifyUrl({ url: `${baseURL}/${URL}`, query }))
    .then((response) => response)
    .catch((error) => ({ error }));
};


export const getFile = (URLS, payload) => {
  const { params = {} } = payload;
  const param = { ...params };
  let { fileName, ext } = payload.file;
  return axiosClient({
    method: 'GET',
    url: queryString.stringifyUrl({ url: `${baseURL}/${URLS}`, query: param }),
    responseType: 'blob'
  })
    .then(response => {
      const type = "application/vnd.ms-excel";
      const blob = new Blob([response.data], { type: type });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${fileName}.${ext}`;
      link.click();
      return ({
        response: {}
      });
    });
};
