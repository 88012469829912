
export default function validate(values) {
  const errors = {};
  if (!values.userName) {
    errors.userName = "please enter your username";
  }
  if (!values.password) {
    errors.password = "please enter your password";
  }
  return errors;
  }
