import React from "react";
import { Link } from "react-router-dom";
import { Field, Form, reduxForm } from "redux-form";
import { MuiComponents, MuiIcons, CustomComponents } from "../../../../common/components";
import validate from "../../validations/ForgetPasswordValidation";

const { RenderTextField } = CustomComponents;

const {
  Grid,
  Card,
  Container,
  FormControl,
  InputAdornment,
  Typography,
  Button
} = MuiComponents;

const { EmailIcon } = MuiIcons;

const style = {
  cardStyle: {
    p: 2,
    width: "40rem",
    height: "18rem",
    backgroundColor: "secondary.main",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    marginTop: "10%"
  }
};


const handleSubmitForm = () => {
};
const ForgotPassword = (props) => {

  const { handleSubmit } = props;
  return (
    <Grid>
      <Form onSubmit={handleSubmit(handleSubmitForm)}>
        <Container maxWidth="md">
          <Card sx={style.cardStyle}>
            <Grid item xs={12}>
              <Container maxWidth="md">
                <Grid container spacing={2}>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="h5"
                      color="primary.dark"
                      sx={{ textAlign: "center", pt: 2 }}
                    >
                      Forgot Password
                    </Typography>
                  </Grid>
                  <Grid item xs={4}></Grid>
                </Grid>
              </Container>
            </Grid>

            <Grid item xs={12}>
              <Container maxWidth="md">
                <Grid container spacing={2}>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={8}>
                    <Typography
                      color="secondary.contrastText"
                      sx={{ pt: 2, textAlign: "center" }}
                    >
                      Enter your email address for verification process. We will
                      send 4 digit verification code
                    </Typography>
                  </Grid>
                  <Grid item xs={2}></Grid>
                </Grid>
              </Container>
            </Grid>

            <Grid item xs={12}>
              <Container maxWidth="md">
                <Grid container spacing={2}>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={8}>
                    <FormControl
                      sx={{
                        display: "block",
                        margin: "auto",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: "20px"
                      }}
                    >
                      <Field
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailIcon />
                            </InputAdornment>
                          )
                        }}
                        className="form-control"
                        name="email"
                        variant="outlined"
                        component={RenderTextField}
                        placeholder="Email Address"
                        type="text"
                        sx={{ width: "25rem", backgroundColor: "white" }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}></Grid>
                </Grid>
              </Container>
            </Grid>

            <Grid sx={{ pt: 3 }}>
              <Button
                type="submit"
                sx={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto"
                }}
              >
                Submit
              </Button>
              <Link
                to="/"
                style={{
                  display: "block",
                  textDecoration: "none",
                  textAlign: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "10px",
                  color: "primary.dark",
                  "&:hover": {
                    color: "secondary.light"
                  }
                }}
              >
                Login in?
              </Link>
            </Grid>
          </Card>
        </Container>
      </Form>
    </Grid>
  );
};

export default reduxForm({
  form: "forgot password form",
  validate
})(ForgotPassword);

