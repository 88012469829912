import { flow } from "lodash";
import { STATE_REDUCER_KEY } from "./constants";

const getServiceRequest = (state) => state[STATE_REDUCER_KEY];

const districtDropdown = (service) => service?.districtDropdown?.data;
export const getDistrictDropdown = flow(getServiceRequest, districtDropdown);

const actionRequiredDropdown = (service) => service?.actionRequiredDropdown?.data;
export const getActionDropdown = flow(getServiceRequest, actionRequiredDropdown);

const locationDropdown = (service) => service?.locationDropdown?.data;
export const getLocationDropdown = flow(getServiceRequest, locationDropdown);

const problemCategoryDropdown = (service) => service?.problemCategoryDropdown?.data;
export const getProblemCategoryDropdown = flow(getServiceRequest, problemCategoryDropdown);

const problemTypeDropdown = (service) => service?.problemTypeDropdown?.data;
export const getProblemTypeDropdown = flow(getServiceRequest, problemTypeDropdown);

const problemTypeFilter = (service) => service?.problemTypeFilterData?.data;
export const problemTypeFilterData = flow(getServiceRequest, problemTypeFilter);

const systemTypeDropdown = (service) => service?.systemTypeDropdown?.data;
export const getSystemTypeDropdown = flow(getServiceRequest, systemTypeDropdown);

const systemStatusDropdown = (service) => service?.systemStatusDropdown?.data;
export const getSystemStatusDropdown = flow(getServiceRequest, systemStatusDropdown);

const assignViewData = (service) => service?.assignViewData?.data;
export const getAssignDetails = flow(getServiceRequest, assignViewData);

const userRoleData = (service) => service?.userRoleData?.data;
export const getUserRolesData = flow(getServiceRequest, userRoleData);

const listUsernameData = (service) => service?.listUsernameData?.data;
export const getListUsernameData = flow(getServiceRequest, listUsernameData);

const listAllTicketDetails = (service) => service?.totalTicketsList?.data;
export const getTotalTicketsData = flow(getServiceRequest, listAllTicketDetails);

const ticketHistoryList = (service) => service?.ticketHistory?.data;
export const getTicketHistoryData = flow(getServiceRequest, ticketHistoryList);

const ticketGetById = (service) => service?.ticketGetById?.data;
export const getTicketGetById = flow(getServiceRequest, ticketGetById);

const ticketStatusFilter = (service) => service?.ticketStatusFilter?.data;
export const getTicketStatusFilter = flow(getServiceRequest, ticketStatusFilter);

const listingPagination = (service) => service?.table?.tableProperties;
export const getPagintation = flow(getServiceRequest, listingPagination);

const slaReportDetails = (service) => service?.slaReportDetails?.data;
export const getSlaReportDetails = flow(getServiceRequest, slaReportDetails);


