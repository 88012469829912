import * as React from "react";
import { Button, Card, Grid } from "@mui/material";
import { Field, Form, reduxForm } from "redux-form";
import RenderTextField from "./TextField";
import RenderSelectField from "./Dropdown";
import DateField from "./DateField";
import { connect, useDispatch } from "react-redux";
import { setFilterData } from "modules/common/common/actions";
import dayjs from "dayjs";
import validate from "../validation/dateFieldValidation";
import _ from "lodash";
import { createStructuredSelector } from "reselect";
import { getDistrictDropdown } from "modules/admin/serviceRequest/selectors";
import { getLocationDropdown } from "modules/admin/serviceRequest/selectors";
import { getProblemCategoryDropdown } from "modules/admin/serviceRequest/selectors";
import { getProblemCategoryData } from "modules/admin/serviceRequest/actions";
import { getDistrictData } from "modules/admin/serviceRequest/actions";
import { getLocationData } from "modules/admin/serviceRequest/actions";
import { actions as commonActions } from "../../../../modules/common/common/slice";
import { actions as serviceActions } from "../../../../modules/admin/serviceRequest/slice";
import { problemTypeFilterData } from "modules/admin/serviceRequest/selectors";
import { getProblemTypeFilterData } from "modules/admin/serviceRequest/actions";
import { useLocation, useParams } from "react-router-dom";
import { getUserDetailsData } from "modules/common/Login/selectors";
import { getTicketStatusFilter } from "modules/admin/serviceRequest/selectors";
import { getTicketStatusByFilter } from "modules/admin/serviceRequest/actions";
import { USER_ROLE_ID } from "modules/common/common/constants";


const Filter = (props) => {
  let { id: crModuleId = "", slaId = "" } = useParams();
  const search = useLocation().search;
  const location = useLocation();
  const dispatch = useDispatch();

  let roleName;
  roleName = new URLSearchParams(search).get("role");
  const { handleSubmit,
    filterFlags,
    reset, problemCategory = [],
    districtList = [],
    locationList = [],
    problemTypeData = [],
    ticketStatus = [],
    handleClose,
    userDetails: { userDetails: userData } = {},
    change
  } = props;
  const { userRoles = [] } = userData;

  const moduleId = userRoles[0]?.moduleId;

  const categoryChange = (val) => {
    dispatch(getProblemTypeFilterData({ val }));
  };

  const districtChange = (val) => {
    if (roleName === "sdc") {
      dispatch(getLocationData({ crModuleId: 1, val }));
    } else if (roleName === "dcr" || roleName === "ccr") {
      dispatch(getLocationData({ crModuleId: 2, val }));
    } else if (location.pathname === "/sdc/dashboard") {
      return "";
    } else {
      dispatch(getLocationData({ crModuleId, val }));
    }
  };
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);

  const { startDateFlag = true,
    endDateFlag = true,
    ticketIdFlag = true,
    problemTypeFlag = true,
    problemCategoryFlag = true,
    districtFlag = true,
    siteIdFlag = true,
    statusFlag = true,
    hourFlag = true } = filterFlags;

  const handleSubmitForm = (value) => {
    const { ...rest } = value;
    let startDate = fromDate ? dayjs(fromDate).valueOf() : null;
    let endDate = toDate ? dayjs(toDate).valueOf() + 24 * 60 * 60 * 1000 : null;
    dispatch(setFilterData({ finalValues: _.omitBy({ ...rest, startDate, endDate }, _.isNil) }));
    { handleClose && handleClose(); }
  };


  React.useEffect(() => {
    if (userRoles[0].userRoleId === USER_ROLE_ID?.PERMIS_SUPPORT_GROUP_ROLE_ID) {
      dispatch(getProblemTypeFilterData(8));
    } else if (userRoles[0].userRoleId === USER_ROLE_ID?.DATA_CENTER_GROUP_ROLE_ID) {
      dispatch(getProblemTypeFilterData(7));
    }
    // dispatch(setFilterData({}));
    // destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  React.useEffect(() => {
    if (slaId) {
      dispatch(getLocationData({ slaId }));
      dispatch(getTicketStatusByFilter({ slaId }));
      dispatch(getProblemTypeFilterData({ slaId }));
    } else {
      if (roleName === "sdc") {
        dispatch(getProblemCategoryData({ moduleId, crModuleId: 1 }));
        dispatch(getTicketStatusByFilter({ crModuleId: 1 }));
        dispatch(getDistrictData());
      } else if (roleName === "dcr" || roleName === "ccr") {
        dispatch(getProblemCategoryData({ moduleId, crModuleId: 2 }));
        dispatch(getTicketStatusByFilter({ crModuleId: 2 }));
        dispatch(getDistrictData());
      } else {
        dispatch(getProblemCategoryData({ moduleId, crModuleId }));
        dispatch(getTicketStatusByFilter({ crModuleId }));
        dispatch(getDistrictData());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crModuleId, slaId]);

  React.useEffect(() => {
    return () => {
      setFromDate(null);
      setToDate(null);
      change("toDate", "");
      change("TicketId", "");
      change("problemTypeId", "");
      change("problemsCategoryId", "");
      change("ticketStatusId", "");
      change("districtId", "");
      change("locationId", "");
      if (location.pathname === "/sdc/dashboard") {
        return "";
      } else {
        dispatch(commonActions.resetCommonFilters());
      }
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Card sx={{ backgroundColor: "#e6e6e6", mb: "1.5%" }}>
      <Form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid sx={{ margin: 2 }}>
          <Grid container spacing={2}>
            {startDateFlag && <Grid item xs={12} sm={3} md={3}>
              <DateField
                className="form-control"
                name="fromDate"
                value={fromDate}
                onChange={(e) => setFromDate(e)}
                onClear={() => setFromDate(null)}
                label="Start Date" />
            </Grid>}
            {endDateFlag && <Grid item xs={12} sm={3} md={3}>
              <DateField
                className="form-control"
                name="toDate"
                value={toDate}
                onChange={(e) => setToDate(e)}
                onClear={() => setToDate(null)}
                label="End Date" />
            </Grid>}

            {ticketIdFlag && <Grid item xs={12} sm={3} md={3}>
              <Field
                className="form-control"
                name="TicketId"
                component={RenderTextField}
                label="Ticket Id"
                variant="standard"
              />
            </Grid>}

            {userRoles[0].userRoleId !== 7
              && userRoles[0].userRoleId !== 8
              && problemCategoryFlag && <Grid item xs={12} sm={3} md={3}>
                <Field
                  name="problemsCategoryId"
                  component={RenderSelectField}
                  label="Problem Category"
                  variant="standard"
                  onChange={categoryChange}
                >
                  {problemCategory}
                </Field>
              </Grid>
            }

            {problemTypeFlag && <Grid item xs={12} sm={3} md={3}>
              <Field
                name="problemTypeId"
                component={RenderSelectField}
                label="Problem Type"
                variant="standard"
              >
                {problemTypeData}
              </Field>
            </Grid>
            }
            {userRoles[0].userRoleId !== 6 && districtFlag && <Grid item xs={12} sm={3} md={3}>
              <Field
                name="districtId"
                component={RenderSelectField}
                label="District"
                variant="standard"
                onChange={districtChange}
              >
                {districtList}
              </Field>
            </Grid>
            }
            {userRoles[0].userRoleId !== 6 && siteIdFlag && <Grid item xs={12} sm={3} md={3}>
              <Field
                name="locationId"
                component={RenderSelectField}
                label="Location"
                variant="standard"
              >
                {locationList}
              </Field>
            </Grid>}

            {statusFlag && <Grid item xs={12} sm={3} md={3}>
              <Field
                name="ticketStatusId"
                component={RenderSelectField}
                label="Ticket Status"
                variant="standard"
              >
                {ticketStatus}
              </Field>
            </Grid>}

            {hourFlag && <Grid item xs={12} sm={3} md={3}>
              <Field
                name="resolutionTime"
                component={RenderSelectField}
                label="Breached Hours"
                variant="standard"
              >
                {[{ id: 48, name: "48+ hours" },
                { id: 72, name: "72+ hours" }]}
              </Field>
            </Grid>}

          </Grid>
        </Grid>

        <Grid container style={{ textAlign: "center" }} spacing={2}>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Button size="small" type="submit" sx={{ mr: 1 }}>search</Button>
            <Button size="small" color="error" sx={{ ml: 1 }} onClick={() => {
              dispatch(commonActions.resetCommonFilters());
              setFromDate(null);
              setToDate(null);
              reset();
              dispatch(serviceActions.resetPagination());
              { handleClose && handleClose(); }
            }}>Reset</Button>
          </Grid>
        </Grid>
      </Form>
    </Card>
  );
};

const mapStateToProps = createStructuredSelector({
  districtList: getDistrictDropdown,
  locationList: getLocationDropdown,
  ticketStatus: getTicketStatusFilter,
  problemCategory: getProblemCategoryDropdown,
  problemTypeData: problemTypeFilterData,
  userDetails: getUserDetailsData
});


export default connect(mapStateToProps)(
  reduxForm({
    form: "filterForm",
    validate
  })(Filter)
);
