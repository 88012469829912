import React from "react";
import { CustomComponents } from "../common/components";
import { StepperFunction } from "./common/Login/components";
import SdcLayout from "./layouts/SdcLayout/components/SdcLayout";
import { CreatePage, HistoryPage, SlaReportPage, ViewPage } from "./admin/serviceRequest/components";
import AssignPage from "./admin/serviceRequest/components/AssignPage";
import App from "App";
import Dashboard from "./common/dashboard/components";
import Location from "./common/MapLocation/components";

const { RootBoundary } = CustomComponents;

const routes = [
  {
    path: "/",
    element: <App />,
    errorElement: <RootBoundary />
  },
  {
    path: "/forget-password",
    element: <StepperFunction />,
    errorElement: <RootBoundary />
  },
  {
    path: "/sdc",
    element: <SdcLayout />,
    errorElement: <RootBoundary />,
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
        errorElement: <RootBoundary />
      },
      {
        path: "camera-location",
        element: <Location />,
        errorElement: <RootBoundary />
      },
      {
        path: "service-request/:id",
        element: <ViewPage />,
        errorElement: <RootBoundary />,
        children: []
      },
      {
        path: "service-request",
        element: <ViewPage />,
        errorElement: <RootBoundary />,
        children: []
      },
      {
        path: "service-request/create-ticket",
        element: <CreatePage />,
        errorElement: <RootBoundary />
      },
      {
        path: "service-request/:statusId/edit-ticket/:ticketId",
        element: <CreatePage />,
        errorElement: <RootBoundary />
      },
      {
        path: "service-request/edit-ticket/:ticketId",
        element: <CreatePage />,
        errorElement: <RootBoundary />
      },
      {
        path: "service-request/:statusId/:assign/:ticketId",
        element: <AssignPage />,
        errorElement: <RootBoundary />
      },
      {
        path: "service-request/assign/:ticketId",
        element: <AssignPage />,
        errorElement: <RootBoundary />
      },
      {
        path: "service-request/:status",
        element: <ViewPage />,
        errorElement: <RootBoundary />,
        children: []
      },
      {
        path: "service-request/history/:ticketId",
        element: <HistoryPage />,
        errorElement: <RootBoundary />,
        children: []
      },
      {
        path: "service-request/5/history/:ticketId",
        element: <HistoryPage />,
        errorElement: <RootBoundary />,
        children: []
      },
      {
        path: "service-request/sla-report/:slaId",
        element: <SlaReportPage />,
        errorElement: <RootBoundary />,
        children: []
      }
    ]
  }
];

export default routes;
