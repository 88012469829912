import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { connect } from "react-redux";
import { Field, Form, reduxForm } from "redux-form";
import RenderTextArea from "./form-components/TextArea";
import { useDispatch } from "react-redux";

const PopUp = (props) => {
    const { handleSubmit, show, handleClose, dispatchAction, ticketId, actionText = "" } = props;
    const dispatch = useDispatch();
    const handleSubmitForm = (val) => {
        dispatch(dispatchAction({ val, ticketId, actionText }));
    };

    return (
        <div>
            <Dialog open={show} onClose={handleClose}>
                <DialogTitle>Enter Your Comments</DialogTitle>
                <Form onSubmit={handleSubmit(handleSubmitForm)} >
                    <DialogContent>
                        <Field
                            name="comments"
                            component={RenderTextArea}
                            label="comments"
                            variant="standard"
                            rows={8} cols={50}
                        >
                        </Field>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={() => {
                            handleClose();
                        }} type="submit">Submit</Button>
                    </DialogActions>
                </Form>
            </Dialog>
        </div>
    );
};

export default connect()(
    reduxForm({
        form: "PopUpForm",
        enableReinitialize: true
    })(PopUp)
);
