/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import Swal from "sweetalert2";
import { createStructuredSelector } from "reselect";
import { connect, useDispatch, useSelector } from "react-redux";
import { CustomComponents, MuiComponents, MuiIcons } from "../../../../common/components";
import { deleteTicket, getDownloadFile, getTicketStatusList, patchAcceptData, patchCloseData, patchRejectData, postTicketStatusReassignToOtherGroup, postTicketStatusToAccept, postTicketStatusToAssign, postTicketStatusToCompleted, postTicketStatusToReassign, postTicketStatusToReject, postTicketStatusToResolved, postTicketStatusToUnderAnalysis, postTicketStatusToWip } from "../actions";
import { getTotalTicketsData } from "../selectors";
import DottedMenu from "common/components/custom/DottedMenu";
import { commonSliceActions } from "modules/common/common/actions";
import { actions as commonSlice } from "../../../common/common/slice";
import { API_URL } from "common/apiUrls";
import { STATE_REDUCER_KEY, getTitleStatus } from "../constants";
import { COMMON_TABLE_PROPS, STATE_REDUCER_KEY as COMMON_KEY, USER_ROLE_ID, SDC_STATUS_ID, OTHER_STATUS_ID } from "../../../common/common/constants";
import { actions as sliceActions } from "../slice";
import dayjs from "dayjs";
import { getUserDetailsData } from "modules/common/Login/selectors";
import PopUp from "common/components/custom/PopUp";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import CommentIcon from '@mui/icons-material/Comment';
import { useLocation, useParams } from "react-router-dom";

const { EditIcon, AddCircleOutlineIcon, FileDownloadIcon,
  HistoryIcon, AssignmentIndIcon,
  CancelIcon, ThumbDownAltIcon, ThumbUpAltIcon } = MuiIcons;

const { Grid, IconButton, Tooltip, Typography } = MuiComponents;
const { Filter, ReactTable } = CustomComponents;

const ViewPage = (props) => {

  const { id: crModuleId = "" } = useParams();

  const dispatch = useDispatch();
  const search = useLocation().search;
  //const location = useLocation();
  const { isLoading = false } = useSelector((state) => _.get(state, `${STATE_REDUCER_KEY}.totalTicketsList`));
  const { filterValues = {}, filterValues: { ticketStatusId: status = "" } = {} } = useSelector((state) => _.get(state, `${COMMON_KEY}`));
  const { table: { tableProperties: { pageNo, totalRecords, pageSize = COMMON_TABLE_PROPS?.ROWS_PER_PAGE[0] } = {} } = {} } = useSelector(state => state[STATE_REDUCER_KEY]);
  const statusName = new URLSearchParams(search).get("status");
  const roleName = new URLSearchParams(search).get("role");
  const { totalTicketsData: { content: ticketData = [] }, userDetails: { userDetails: userData = {} } = {}, userDetails: { userDetails: { userId = "" } = {} } = {} } = props;
  const { userRoles = [] } = userData;
  const moduleId = userRoles[0]?.moduleId;

  let statusId;
  if (userRoles[0]?.userRoleId === USER_ROLE_ID?.SDC_ROLE_ID) {
    let getStringForSdcDashboard = (value) => {
      switch (value) {
        case "Open":
          return 1;
        case "Pending":
          return 2;
        case "WorkInProgress":
          return 3;
        case "Resolved":
          return 4;
        case "Closed":
          return 5;
        case "Transfered":
          return 6;
        case "Violated":
          return 7;
        case "UnAssigned":
          return 8;
        default:
          return "";
      }
    };
    statusId = getStringForSdcDashboard(statusName);

  } else {

    let getStringForDashboardData = (value) => {
      switch (value) {
        case "Approved":
          return 12;
        case "Assigned":
          return 10;
        case "Pending":
          return 9;
        case "Reassigned":
          return 17;
        case "Reassign To Other Category":
          return 18;
        case "Rejected":
          return 13;
        case "Resolved":
          return 16;
        case "Under Analysis":
          return 11;
        case "WIP":
          return 14;
        default:
          return "";
      }
    };

    statusId = getStringForDashboardData(statusName);
  }

  const state = {
    columnVisibility: {
      //ticketStatusName: status ? false : true,
      assignedPerson: Number(status) === 8 ? false : true
    },
    isLoading: isLoading
  };
  const [show, setShow] = useState(false);
  const [ticketNo, setTicketNo] = useState();
  const [stringData, setStringData] = useState("");
  const initialState = {
    columnOrder: [
      'ticketId',
      'ticketStatusName',
      'locationId',
      'districtName',
      'locationName',
      'userName',
      'actionRequiredId',
      'problemTypeId',
      "contactName",
      "contactNumber",
      "contactEmail",
      'createdTime',
      'mrt-row-actions'
    ]
  };

  useEffect(() => {
    if (statusName) {
      dispatch(getTicketStatusList({ filterValues, moduleId, userId, status, ticketStatusId: statusId }));
    } else {
      dispatch(getTicketStatusList({ filterValues, moduleId, userId, status, crModuleId, roleName }));
      dispatch(sliceActions.resetPagination());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues, crModuleId]);

  useEffect(() => {
    return () => {
      dispatch(sliceActions.resetPagination());
      dispatch(commonSlice.resetCommonFilters());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // let ticketStatus;
  if (userRoles[0]?.userRoleId === USER_ROLE_ID.DATA_CENTER_GROUP_ROLE_ID || userRoles[0]?.userRoleId === USER_ROLE_ID.PERMIS_SUPPORT_GROUP_ROLE_ID) {
    //ticketStatus = ticketData[0]?.ticketStatusId;
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'ticketId',
        header: 'Ticket Id',
        size: 50,
        muiTableHeadCellProps: {
          align: 'center'
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'locationId',
        header: 'Site Id',
        size: 100
      },
      {
        accessorKey: 'districtName',
        header: 'District Name',
        size: 70
      },
      {
        accessorKey: 'locationName',
        header: 'Location Name',
        size: 70
      },
      {
        accessorKey: 'userName',
        header: 'Assigned Person',
        size: 70
      },
      {
        accessorKey: 'createdTime',
        header: 'Generated Date & Time',
        accessorFn: (row) => row.createdTime ? dayjs(row.createdTime).format('YYYY-MM-DD HH:mm:ss') : null,
        size: 90
      },
      {
        accessorKey: 'ticketStatusName',
        header: 'Ticket status',
        size: 70

      },
      {
        accessorKey: 'contactName',
        header: 'Contact Name',
        size: 70

      },
      {
        accessorKey: 'contactNumber',
        header: 'Contact Number',
        size: 70

      },
      {
        accessorKey: 'contactEmail',
        header: 'Contact Email',
        size: 70

      },
      {
        accessorFn: ({ actionRequiredId }) => {
          let actions = actionRequiredId?.map((value) => value.actionRequired);
          let actionRequires = actions?.toString();
          return actionRequires;
        },
        id: 'actionRequiredId',
        header: 'Action Required',
        size: 70
      },
      {
        accessorFn: ({ problemTypeId }) => {
          let problems = problemTypeId?.map((value) => value.problemTypes);
          let problemsData = problems?.toString();
          return problemsData;
        },
        id: 'problemTypeId',
        header: 'Problem Types',
        size: 70
      }
    ],
    []
  );

  const handleDelete = (ticketId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this ticket!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: "Cancel",
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteTicket({ ticketId, status }));
      }
    });
  };

  const handleChangePage = (e, newPage) => {
    dispatch(sliceActions.setPage(newPage));
    dispatch(getTicketStatusList({ filterValues, moduleId, userId, status, ticketStatusId: statusId, crModuleId, roleName }));

  };

  const handleChangeRowsPerPage = (e) => {
    dispatch(sliceActions.setRowsPerPage({ pageSize: e.target.value, totalRecords: 0, pageNo: 0 }));
    dispatch(getTicketStatusList({ filterValues, moduleId, userId, status, ticketStatusId: statusId, crModuleId, roleName }));

  };

  const handlePopup = (ticketId, string) => {
    setStringData(string);
    setShow(true);
    setTicketNo(ticketId);
  };

  let getStringValue = (value) => {
    switch (value) {
      case "underAnalysis":
        return postTicketStatusToUnderAnalysis;
      case "accept":
        return postTicketStatusToAccept;
      case "reject":
        return postTicketStatusToReject;
      case "wip":
        return postTicketStatusToWip;
      case "completed":
        return postTicketStatusToCompleted;
      case "resolved":
        return postTicketStatusToResolved;
      case "reassign_to_vendor":
        return postTicketStatusToReassign;
      case "reassign_to_other_group":
        return postTicketStatusReassignToOtherGroup;
      default:
        return "";
    }
  };

  let actionName = getStringValue(stringData);

  let actions = (row) => {
    let customActions = [];
    // if (Number(ticketStatus) === 9) {
    //   {
    //     (userRoles[0]?.userRoleId !== USER_ROLE_ID?.DCR_ROLE_ID || userRoles[0]?.userRoleId !== USER_ROLE_ID?.CCR_ROLE_ID) && customActions.push({
    //       title: "Assign to me", icon: < AssignmentIndIcon fontSize="small" />, handleClick: () => dispatch(postTicketStatusToAssign(`${row.original.ticketId}`))
    //     });
    //   }
    //   customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
    //   return customActions;
    // }
    if (!Number(status)) {
      if (userRoles[0]?.userRoleId === USER_ROLE_ID?.SERVICE_MANAGER_ROLE_ID) {
        customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
      } else if (userRoles[0]?.userRoleId === USER_ROLE_ID?.DATA_CENTER_GROUP_ROLE_ID || userRoles[0]?.userRoleId === USER_ROLE_ID?.PERMIS_SUPPORT_GROUP_ROLE_ID) {

        if (row.original.ticketStatusName === "Pending") {
          customActions.push({ title: "Assign to me", icon: <AssignmentIndIcon fontSize="small" />, handleClick: () => dispatch(postTicketStatusToAssign(`${row.original.ticketId}`)) });
          customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
        }
        if (row.original.ticketStatusName === "Assigned") {
          customActions.push({ title: "Under Analysis", icon: < AssignmentIndIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "underAnalysis") });
          customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
        }
        if (row.original.ticketStatusName === "Under Analysis") {
          customActions.push({ title: "Comment", icon: <CommentIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "underAnalysis") });
          customActions.push({ title: "Accept", icon: <ThumbUpIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "accept") });
          customActions.push({ title: "Reject", icon: <ThumbDownIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "reject") });
          customActions.push({ title: "Reassign to vendor group", icon: < AssignmentIndIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "reassign_to_vendor") });
          customActions.push({ title: USER_ROLE_ID.DATA_CENTER_GROUP_ROLE_ID ? "Reassign to non-IT group" : "Reassign to IT group", icon: < AssignmentIndIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "reassign_to_other_group") });
          customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
        }
        if (row.original.ticketStatusName === "Approved") {
          customActions.push({ title: "Work In Progress", icon: <ThumbUpIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "wip") });
          customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
        }
        if (row.original.ticketStatusName === "Work in progress") {
          customActions.push({ title: "Comment", icon: <CommentIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "wip") });
          customActions.push({ title: "Resolve", icon: <ThumbUpIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "resolved") });
          customActions.push({ title: "Reassign to vendor group", icon: < AssignmentIndIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "reassign_to_vendor") });
          customActions.push({ title: USER_ROLE_ID.DATA_CENTER_GROUP_ROLE_ID ? "Reassign to non-IT group" : "Reassign to IT group", icon: < AssignmentIndIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "reassign_to_other_group") });
          customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
        }
        if (row.original.ticketStatusName === "Rejected") {
          customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
        }
        if (row.original.ticketStatusName === "Resolved") {
          customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
        }
        if (row.original.ticketStatusName === "Reassigned") {
          customActions.push({ title: "Completed", icon: <ThumbUpIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "completed") });
          customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
        }
      } else {
        customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
        customActions.push({ title: "Edit", icon: <EditIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/edit-ticket/${row.original.ticketId}`)) });
        customActions.push({ title: "Cancel", icon: <CancelIcon fontSize="small" />, handleClick: () => handleDelete(`${row.original.ticketId}`) });
      }
    } else if (Number(status) === SDC_STATUS_ID?.OPEN_STATUS_ID) {
      customActions.push({ title: "Reassign", icon: < AssignmentIndIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/1/re-assign/${row.original.ticketId}`)) });
      customActions.push({ title: "Edit", icon: < EditIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/1/edit-ticket/${row.original.ticketId}`)) });
      customActions.push({ title: "Cancel", icon: < CancelIcon fontSize="small" />, handleClick: () => handleDelete(`${row.original.ticketId}`) });
    } else if (Number(status) === SDC_STATUS_ID?.PENDING_STATUS_ID) {
      customActions.push({ title: "Reassign", icon: < AssignmentIndIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/2/re-assign/${row.original.ticketId}`)) });
      customActions.push({ title: "Edit", icon: < EditIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/2/edit-ticket/${row.original.ticketId}`)) });
      customActions.push({ title: "Cancel", icon: < CancelIcon fontSize="small" />, handleClick: () => handleDelete(`${row.original.ticketId}`) });
    } else if (Number(status) === SDC_STATUS_ID?.WIP_STATUS_ID) {
      customActions.push({ title: "Reassign", icon: < AssignmentIndIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/3/re-assign/${row.original.ticketId}`)) });
      customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
      customActions.push({ title: "Edit", icon: < EditIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/3/edit-ticket/${row.original.ticketId}`)) });
      customActions.push({ title: "Cancel", icon: < CancelIcon fontSize="small" />, handleClick: () => handleDelete(`${row.original.ticketId}`) });
    } else if (Number(status) === SDC_STATUS_ID?.RESOLVED_STATUS_ID) {
      customActions.push({ title: "Close Ticket", icon: < CancelIcon fontSize="small" />, handleClick: () => dispatch(patchCloseData({ ticketId: `${row.original.ticketId}`, status })) });
      customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
    } else if (Number(status) === SDC_STATUS_ID?.CLOSED_STATUS_ID) {
      customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
    } else if (Number(status) === SDC_STATUS_ID?.TRANSFERRED_STATUS_ID) {
      customActions.push({ title: "Accept", icon: < ThumbUpAltIcon fontSize="small" />, handleClick: () => dispatch(patchAcceptData({ ticketId: `${row.original.ticketId}`, status })) });
      customActions.push({ title: "Reject", icon: < ThumbDownAltIcon fontSize="small" />, handleClick: () => dispatch(patchRejectData({ ticketId: `${row.original.ticketId}`, status })) });
    } else if (Number(status) === SDC_STATUS_ID?.UNASSIGNED_STATUS_ID) {
      customActions.push({ title: "Assign", icon: < AssignmentIndIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/8/assign/${row.original.ticketId}`)) });
      customActions.push({ title: "Edit", icon: < EditIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/8/edit-ticket/${row.original.ticketId}`)) });
      customActions.push({ title: "Cancel", icon: < CancelIcon fontSize="small" />, handleClick: () => handleDelete(`${row.original.ticketId}`) });
    } else if (Number(status) === OTHER_STATUS_ID?.PENDING_STATUS_ID) {
      if (userRoles[0]?.userRoleId === USER_ROLE_ID?.DCR_ROLE_ID || userRoles[0]?.userRoleId === USER_ROLE_ID?.CCR_ROLE_ID) {
        customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
        customActions.push({ title: "Edit", icon: <EditIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/edit-ticket/${row.original.ticketId}`)) });
        customActions.push({ title: "Cancel", icon: <CancelIcon fontSize="small" />, handleClick: () => handleDelete(`${row.original.ticketId}`) });
      } else if (userRoles[0]?.userRoleId === USER_ROLE_ID?.SERVICE_MANAGER_ROLE_ID) {
        customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
      } else {
        { (userRoles[0]?.userRoleId !== USER_ROLE_ID?.DCR_ROLE_ID || userRoles[0]?.userRoleId !== USER_ROLE_ID?.CCR_ROLE_ID) && customActions.push({ title: "Assign to me", icon: < AssignmentIndIcon fontSize="small" />, handleClick: () => dispatch(postTicketStatusToAssign(`${row.original.ticketId}`)) }); }
        customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
      }
    } else if (Number(status) === OTHER_STATUS_ID?.ASSIGNED_STATUS_ID) {
      if (userRoles[0]?.userRoleId === USER_ROLE_ID?.DCR_ROLE_ID || userRoles[0]?.userRoleId === USER_ROLE_ID?.CCR_ROLE_ID) {
        customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
        customActions.push({ title: "Cancel", icon: <CancelIcon fontSize="small" />, handleClick: () => handleDelete(`${row.original.ticketId}`) });

      } else if (userRoles[0]?.userRoleId === USER_ROLE_ID?.SERVICE_MANAGER_ROLE_ID) {
        customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
      } else {
        customActions.push({ title: "Under Analysis", icon: < AssignmentIndIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "underAnalysis") });
        customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
      }
    } else if (Number(status) === OTHER_STATUS_ID?.UNDER_ANALYSIS_STATUS_ID) {
      if (userRoles[0]?.userRoleId === USER_ROLE_ID?.DCR_ROLE_ID || userRoles[0]?.userRoleId === USER_ROLE_ID?.CCR_ROLE_ID) {
        customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
        customActions.push({ title: "Cancel", icon: <CancelIcon fontSize="small" />, handleClick: () => handleDelete(`${row.original.ticketId}`) });
      } else if (userRoles[0]?.userRoleId === USER_ROLE_ID?.SERVICE_MANAGER_ROLE_ID) {
        customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
      } else if (userRoles[0]?.userRoleId === USER_ROLE_ID?.DATA_CENTER_GROUP_ROLE_ID) {
        customActions.push({ title: "Comment", icon: <CommentIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "underAnalysis") });
        customActions.push({ title: "Accept", icon: <ThumbUpIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "accept") });
        customActions.push({ title: "Reject", icon: <ThumbDownIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "reject") });
        customActions.push({ title: "Reassign to vendor group", icon: < AssignmentIndIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "reassign_to_vendor") });
        customActions.push({ title: "Reassign to non-IT group", icon: < AssignmentIndIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "reassign_to_other_group") });
        customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
      } else if (userRoles[0]?.userRoleId === USER_ROLE_ID?.PERMIS_SUPPORT_GROUP_ROLE_ID) {
        customActions.push({ title: "Comment", icon: <CommentIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "underAnalysis") });
        customActions.push({ title: "Accept", icon: <ThumbUpIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "accept") });
        customActions.push({ title: "Reject", icon: <ThumbDownIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "reject") });
        customActions.push({ title: "Reassign to vendor group", icon: < AssignmentIndIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "reassign_to_vendor") });
        customActions.push({ title: "Reassign to IT group", icon: < AssignmentIndIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "reassign_to_other_group") });
        customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
      } else {
        customActions.push({ title: "Comment", icon: <CommentIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "underAnalysis") });
        customActions.push({ title: "Accept", icon: <ThumbUpIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "accept") });
        customActions.push({ title: "Reject", icon: <ThumbDownIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "reject") });
        customActions.push({ title: "Reassign", icon: < AssignmentIndIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "reassign") });
        customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
      }
    } else if (Number(status) === OTHER_STATUS_ID?.APPROVED_STATUS_ID) {
      if (userRoles[0]?.userRoleId === USER_ROLE_ID?.DCR_ROLE_ID || userRoles[0]?.userRoleId === USER_ROLE_ID?.CCR_ROLE_ID) {
        customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
        customActions.push({ title: "Cancel", icon: <CancelIcon fontSize="small" />, handleClick: () => handleDelete(`${row.original.ticketId}`) });
      } else if (userRoles[0]?.userRoleId === USER_ROLE_ID?.SERVICE_MANAGER_ROLE_ID) {
        customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
      } else {
        customActions.push({ title: "Work In Progress", icon: <ThumbUpIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "wip") });
        customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
      }
    } else if (Number(status) === OTHER_STATUS_ID?.REJECTED_STATUS_ID) {
      if (userRoles[0]?.userRoleId === USER_ROLE_ID?.SERVICE_MANAGER_ROLE_ID) {
        customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
      } else {
        { (userRoles[0]?.userRoleId === USER_ROLE_ID?.CCR_ROLE_ID || userRoles[0]?.userRoleId === USER_ROLE_ID?.DCR_ROLE_ID) && customActions.push({ title: "Resolve", icon: <ThumbUpIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "resolved") }); }
        customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
      }
    } else if (Number(status) === OTHER_STATUS_ID?.WIP_STATUS_ID) {
      if (userRoles[0]?.userRoleId === USER_ROLE_ID?.CCR_ROLE_ID || userRoles[0]?.userRoleId === USER_ROLE_ID?.DCR_ROLE_ID) {
        customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
        customActions.push({ title: "Cancel", icon: <CancelIcon fontSize="small" />, handleClick: () => handleDelete(`${row.original.ticketId}`) });
      } else if (userRoles[0]?.userRoleId === USER_ROLE_ID?.SERVICE_MANAGER_ROLE_ID) {
        customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
      } else {
        customActions.push({ title: "Comment", icon: <CommentIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "wip") });
        customActions.push({ title: "Resolve", icon: <ThumbUpIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "resolved") });
        customActions.push({ title: "Reassign", icon: < AssignmentIndIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "reassign") });
        customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
      }
    } else if (Number(status) === OTHER_STATUS_ID?.RESOLVED_STATUS_ID) {
      if (userRoles[0]?.userRoleId === USER_ROLE_ID?.SERVICE_MANAGER_ROLE_ID) {
        customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
      } else {
        //{ (!userRoles[0]?.userRoleId === 8 || !userRoles[0]?.userRoleId === 7) && customActions.push({ title: "Resolved", icon: <ThumbUpIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "resolved") }); }
        customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
      }
    } else if (Number(status) === OTHER_STATUS_ID?.REASSIGNED_STATUS_ID) {
      if (userRoles[0]?.userRoleId === USER_ROLE_ID?.CCR_ROLE_ID || userRoles[0]?.userRoleId === USER_ROLE_ID?.DCR_ROLE_ID) {
        customActions.push({ title: "Resolved", icon: <ThumbUpIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "resolved") });
        customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
      } else if (userRoles[0]?.userRoleId === USER_ROLE_ID?.SERVICE_MANAGER_ROLE_ID) {
        customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
      } else {
        customActions.push({ title: "Completed", icon: <ThumbUpIcon fontSize="small" />, handleClick: () => handlePopup(row.original.ticketId, "completed") });
        customActions.push({ title: "History", icon: <HistoryIcon fontSize="small" />, handleClick: () => dispatch(commonSliceActions.navigateTo(`/sdc/service-request/history/${row.original.ticketId}`)) });
      }
    }
    return customActions;
  };

  const displayColumnDefOptions = {
    "mrt-row-actions": {
      Cell: ({ row }) => <DottedMenu actions={actions(row)} />
    }
  };

  let filterFlags = {
    startDateFlag: true,
    endDateFlag: true,
    ticketIdFlag: true,
    issueTypeFlag: true,
    assignFlag: true,
    districtFlag: true,
    siteIdFlag: true,
    statusFlag: true,
    hourFlag: false

  };

  let title = getTitleStatus(Number(status));

  const internalActions = [];
  const ToolbarActions = [];
  if (!status) {
    ToolbarActions.push(
      <Typography variant="h6" sx={{ fontFamily: "sans-serif" }}>{title}</Typography>
    );
    {
      userRoles[0]?.userRoleId !== USER_ROLE_ID?.PERMIS_SUPPORT_GROUP_ROLE_ID && userRoles[0]?.userRoleId !== USER_ROLE_ID?.DATA_CENTER_GROUP_ROLE_ID &&
        userRoles[0]?.userRoleId !== USER_ROLE_ID?.SERVICE_MANAGER_ROLE_ID &&
        internalActions.push(
          <Tooltip arrow title="Generate Ticket">
            <IconButton onClick={() => dispatch(commonSliceActions.navigateTo("/sdc/service-request/create-ticket"))}>
              <AddCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        );
    }

  } else {
    ToolbarActions.push(
      <Typography variant="h6" sx={{ fontFamily: "sans-serif" }}>{title}</Typography>
    );

  }

  internalActions.push(
    <Tooltip arrow title="Download Excel">
      <IconButton onClick={() => dispatch(getDownloadFile({ url: API_URL.SERVICE_REQUEST.EXCEL_EXPORT_URL, file: { fileName: "Tickets Report", ext: "xls" }, filter: { filterValues, moduleId, userId, status, ticketStatusId: statusId, crModuleId } }))}>
        <FileDownloadIcon />
      </IconButton>
    </Tooltip>
  );
  const muiTableContainerProps = { sx: { maxHeight: '510px' } };

  let textValue;
  if (actionName === postTicketStatusToReassign) {
    textValue = "vendor";
  }

  return (
    <>
      <Grid item xs={12} sm={12} md={12}>
        <Filter filterFlags={filterFlags} />
        <ReactTable {...{
          data: ticketData,
          columns,
          initialState,
          actions,
          ToolbarActions,
          internalActions,
          displayColumnDefOptions,
          state,
          muiTableContainerProps,
          handleChangePage,
          handleChangeRowsPerPage,
          pageNo,
          totalRecords,
          pageSize,
          enableCustomPagination: true
        }} />
      </Grid>
      <PopUp show={show} dispatchAction={actionName} actionText={textValue} handleClose={() => setShow(false)} ticketId={ticketNo} />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  totalTicketsData: getTotalTicketsData,
  userDetails: getUserDetailsData
});


export default connect(mapStateToProps)(ViewPage);
