/* eslint-disable no-undef */
import { createAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { call, put } from "redux-saga/effects";
import { errorNotify } from "./notificationUtils";

function* invokeApi(api, url, payload) {
  // TODO: headers , token , etc ... need to be handled
  const { types = ["REQUEST", "SUCCESS", "FAILURE"] } = payload;
  let requestAction = createAction(types[0]),
    successAction = createAction(types[1]),
    failureAction = createAction(types[2]);

  yield put(requestAction());

  const { response, error } = yield call(api, url, payload);
  if (error) {
    yield put(failureAction({ error }));

    //TODO: implement logic for handling errors

    //TODO: show notification popup instead of alerts
    yield put(
      errorNotify({
        title: "Error in invokeApi",
        message: "Please check after some time."
      })
    );
  } else {
    if (_.get(response, "data.resultCode")==="OPERATION_FAILURE") {
      let code = _.get(response, "data.resultCode");
      let string = _.get(response, "data.resultString");
      yield put(failureAction({ error: response.error }));

      //TODO: show notification popup instead of alerts
      yield put(
        errorNotify({
          title: code,
          message: string
        })
      );
    } else {
      yield put(successAction({ response: response?.data }));
    }
  }


  return { response, error };
}

export function* handleAPIRequest(apiFn, ...rest) {
  let { api, url, payload } = apiFn(...rest);
  return yield call(invokeApi, api, url, payload);
}
