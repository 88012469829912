import { createAction } from '@reduxjs/toolkit';
import { STATE_REDUCER_KEY } from './constants';

export const ACTION_TYPES = {

    LOGIN_POST_DETAILS: `${STATE_REDUCER_KEY}/LOGIN_POST_DETAILS`,

    LOGIN_POST_REQUEST: `${STATE_REDUCER_KEY}/LOGIN_POST_REQUEST`,
    LOGIN_POST_SUCCESS: `${STATE_REDUCER_KEY}/LOGIN_POST_SUCCESS`,
    LOGIN_POST_FAILURE: `${STATE_REDUCER_KEY}/LOGIN_POST_FAILURE`

};

export const loginPostApi = createAction(ACTION_TYPES.LOGIN_POST_DETAILS);
