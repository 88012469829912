import _ from "lodash";

export default function validate(values) {
    const errors = {};

    if (
      !_.has(values, "assignTo") || _.get(values, "assignTo", "") === null
    ) {
      _.set(errors, "assignTo", "please select the role");
    }

    if (
      !_.has(values, "userId") || _.get(values, "userId", "") === null
    ) {
      _.set(errors, "userId", "please select the technician name");
    }
    return errors;
  }
