import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";

const initialState = {
  totalTicketCount: {
    data: []
  },

  dashboardData: {
    isLoading: false,
    data: []
  },

  cameraTicketCount: {
    loading: false,
    data: []
  },

  sdcDashboardCount: {
    loading: false,
    data: []
  },

  ccrTicketCount: {
    loading: false,
    data: []
  },

  ccrDashboardCount: {
    loading: false,
    data: []
  },

  dcrTicketCount: {
    loading: false,
    data: []
  },

  dcrDashboardCount: {
    loading: false,
    data: []
  },

  cameraTypeWiseCount: {
    loading: false,
    data: []
  },

  itAndNonItCount: {
    loading: false,
    data: []
  }

};

const slice = createSlice({
  initialState,
  name: STATE_REDUCER_KEY,
  reducers: {
    clear: (state) => {
      // eslint-disable-next-line no-unused-vars
      state = initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(ACTION_TYPES.FETCH_TOTAL_TICKETS_COUNT_REQUEST, (state) => {
        state.totalTicketCount.loading = true;
      })
      .addCase(
        ACTION_TYPES.FETCH_TOTAL_TICKETS_COUNT_SUCCESS,
        (state, action) => {
          state.totalTicketCount.data = _.get(action, "payload.response.payLoad", []);
        }
      )
      .addCase(ACTION_TYPES.FETCH_DASHBOARD_DATA_REQUEST, (state) => {
        state.dashboardData.isLoading = true;
      })
      .addCase(
        ACTION_TYPES.FETCH_DASHBOARD_DATA_SUCCESS,
        (state, action) => {
          state.dashboardData.data = _.get(action, "payload.response.payLoad", []);
        }
      )
      .addCase(ACTION_TYPES.FETCH_CAMERA_TICKETS_COUNT_DATA_REQUEST, (state) => {
        state.cameraTicketCount.loading = true;
      })
      .addCase(
        ACTION_TYPES.FETCH_CAMERA_TICKETS_COUNT_DATA_SUCCESS,
        (state, action) => {
          state.cameraTicketCount.data = _.get(action, "payload.response.payLoad", []);
          state.cameraTicketCount.loading = false;
        }
      )
      .addCase(ACTION_TYPES.FETCH_CAMERA_TICKETS_COUNT_DATA_FAILURE, (state) => {
        state.cameraTicketCount.loading = true;
      })
      .addCase(ACTION_TYPES.FETCH_CCR_TICKETS_COUNT_DATA_REQUEST, (state) => {
        state.ccrTicketCount.loading = true;
      })
      .addCase(
        ACTION_TYPES.FETCH_CCR_TICKETS_COUNT_DATA_SUCCESS,
        (state, action) => {
          state.ccrTicketCount.data = _.get(action, "payload.response.payLoad", []);
          state.ccrTicketCount.loading = false;
        }
      )
      .addCase(ACTION_TYPES.FETCH_CCR_TICKETS_COUNT_DATA_FAILURE, (state) => {
        state.ccrTicketCount.loading = true;
      })
      .addCase(ACTION_TYPES.FETCH_DCR_TICKETS_COUNT_DATA_REQUEST, (state) => {
        state.dcrTicketCount.loading = true;
      })
      .addCase(
        ACTION_TYPES.FETCH_DCR_TICKETS_COUNT_DATA_SUCCESS,
        (state, action) => {
          state.dcrTicketCount.data = _.get(action, "payload.response.payLoad", []);
          state.dcrTicketCount.loading = false;
        }
      )
      .addCase(ACTION_TYPES.FETCH_DCR_TICKETS_COUNT_DATA_FAILURE, (state) => {
        state.dcrTicketCount.loading = true;
      })
      .addCase(ACTION_TYPES.FETCH_CAMERA_TYPE_WISE_COUNT_DATA_REQUEST, (state) => {
        state.cameraTypeWiseCount.loading = true;
      })
      .addCase(
        ACTION_TYPES.FETCH_CAMERA_TYPE_WISE_COUNT_DATA_SUCCESS,
        (state, action) => {
          state.cameraTypeWiseCount.data = _.get(action, "payload.response.payLoad", []);
          state.cameraTypeWiseCount.loading = false;
        }
      )
      .addCase(ACTION_TYPES.FETCH_CAMERA_TYPE_WISE_COUNT_DATA_FAILURE, (state) => {
        state.cameraTypeWiseCount.loading = true;
      })
      .addCase(ACTION_TYPES.FETCH_IT_AND_NON_IT_COUNT_DATA_REQUEST, (state) => {
        state.itAndNonItCount.loading = true;
      })
      .addCase(
        ACTION_TYPES.FETCH_IT_AND_NON_IT_COUNT_DATA_SUCCESS,
        (state, action) => {
          state.itAndNonItCount.data = _.get(action, "payload.response.payLoad", []);
          state.itAndNonItCount.loading = false;
        }
      )
      .addCase(ACTION_TYPES.FETCH_IT_AND_NON_IT_COUNT_DATA_FAILURE, (state) => {
        state.itAndNonItCount.loading = true;
      })
      .addCase(ACTION_TYPES.FETCH_DASHBOARD_SDC_COUNT_DATA_REQUEST, (state) => {
        state.sdcDashboardCount.loading = true;
      })
      .addCase(
        ACTION_TYPES.FETCH_DASHBOARD_SDC_COUNT_DATA_SUCCESS,
        (state, action) => {
          state.sdcDashboardCount.data = _.get(action, "payload.response.payLoad", []);
          state.sdcDashboardCount.loading = false;
        }
      )
      .addCase(ACTION_TYPES.FETCH_DASHBOARD_SDC_COUNT_DATA_FAILURE, (state) => {
        state.sdcDashboardCount.loading = true;
      })

      .addCase(ACTION_TYPES.FETCH_DASHBOARD_DCR_COUNT_DATA_REQUEST, (state) => {
        state.dcrDashboardCount.loading = true;
      })
      .addCase(
        ACTION_TYPES.FETCH_DASHBOARD_DCR_COUNT_DATA_SUCCESS,
        (state, action) => {
          state.dcrDashboardCount.data = _.get(action, "payload.response.payLoad", []);
          state.dcrDashboardCount.loading = false;
        }
      )
      .addCase(ACTION_TYPES.FETCH_DASHBOARD_DCR_COUNT_DATA_FAILURE, (state) => {
        state.dcrDashboardCount.loading = true;
      })
      .addCase(ACTION_TYPES.FETCH_DASHBOARD_CCR_COUNT_DATA_REQUEST, (state) => {
        state.ccrDashboardCount.loading = true;
      })
      .addCase(
        ACTION_TYPES.FETCH_DASHBOARD_CCR_COUNT_DATA_SUCCESS,
        (state, action) => {
          state.ccrDashboardCount.data = _.get(action, "payload.response.payLoad", []);
          state.ccrDashboardCount.loading = false;
        }
      )
      .addCase(ACTION_TYPES.FETCH_DASHBOARD_CCR_COUNT_DATA_FAILURE, (state) => {
        state.ccrDashboardCount.loading = true;
      });
  }
});

export const { actions, reducer } = slice;
