import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";

const initialState = {
  navigator: () => { },
  filterValues: {},
  tableProps: {
    pagination: {}
  }
};

const slice = createSlice({
  initialState,
  name: STATE_REDUCER_KEY,
  reducers: {
    clearAll: () => initialState,
    setNavigate: (state, action) => {
      state.navigator = action.payload;
    },
    navigateTo: (state, action) => {
      state.navigator(action.payload);
    },
    resetCommonFilters: (state) => {
      state.filterValues = initialState.filterValues;
    },
    setCommonFilters: (state, action) => {
      state.filterValues = action.payload || {};
    },
    setPagination: (state, action) => {
      state.tableProps.pagination = { ...state.tableProps.pagination, ...action.payload };
    },
    resetPagination: (state) => {
      state.tableProps.pagination = initialState.tableProps.pagination;
    },
    resetTableProps: (state) => {
      state.tableProps = initialState.tableProps;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(ACTION_TYPES.SET_FILTER_VALUES, (state, action) => {
      state.filterValues = _.get(action, "payload.finalValues", {});
    });
  }
});

export const { actions, reducer } = slice;
