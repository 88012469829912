import { FormHelperText } from "@mui/material";
import React from "react";

const RenderFormHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return;
  } else {
    return <FormHelperText error>{error}</FormHelperText>;
  }
};

export default RenderFormHelper;
