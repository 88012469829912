import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { commonSliceActions } from "../actions";

const Navigator = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(commonSliceActions.setNavigate(navigate));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
export default Navigator;
