export const STATE_REDUCER_KEY = "serviceRequest";


export const getTitleStatus = (value) => {
    switch (value) {
        case 1:
            return "Open Tickets";
        case 2:
            return "Pending Tickets";
        case 3:
            return "Work In Progress Tickets";
        case 4:
            return "User Resolved Tickets";
        case 5:
            return "Closed Tickets";
        case 6:
            return "User Transferred Tickets";
        case 8:
            return "Un-Assigned Tickets";
        case 9:
            return "Pending Tickets";
        case 10:
            return "Assigned Tickets";
        case 11:
            return "Under-Analysis Tickets";
        case 12:
            return "Accepted Tickets";
        case 13:
            return "Rejected Tickets";
        case 14:
            return "Work In Progress Tickets";
        case 15:
            return "Completed Tickets";
        case 16:
            return "Resolved Tickets";
        case 17:
            return "Re-assigned Tickets";
        case 18:
            return "Re-assign to other category Tickets";
        default:
            return "Tickets Details";
    }
};
