import { put, all, takeLatest, fork, take } from "redux-saga/effects";
import { ACTION_TYPES } from "./actions";
import { LoginPostCall } from "./api";
import { handleAPIRequest } from "../../../utils/http";
import { commonSliceActions } from "../common/actions";
import { successNotify } from "utils/notificationUtils";

export function* LoginPost(action) {
  let request = action.payload;
  yield fork(handleAPIRequest, LoginPostCall, request);
  const actionTypes = yield take([ACTION_TYPES.LOGIN_POST_SUCCESS]);
  //const { response } = yield take("response");
  // console.log("response", Response)

  if (actionTypes.type === ACTION_TYPES.LOGIN_POST_SUCCESS) {
    yield put(
      successNotify({
        title: "Logged in",
        message: "Successfully Logged In"
      })
    );
    yield put(commonSliceActions.navigateTo("/sdc/dashboard"));
  }
}

export default function* loginSaga() {
  yield all([takeLatest(ACTION_TYPES.LOGIN_POST_DETAILS, LoginPost)]);
}
