
import DashboardIcon from '@mui/icons-material/Dashboard';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconExpandLess from '@mui/icons-material/ExpandLess';
import IconExpandMore from '@mui/icons-material/ExpandMore';
import ViewListIcon from '@mui/icons-material/ViewList';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import HistoryIcon from '@mui/icons-material/History';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CancelIcon from '@mui/icons-material/Cancel';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  AccountCircle,
  Lock as LockIcon,
  Visibility,
  VisibilityOff,
  Email as EmailIcon
} from "@mui/icons-material";


const MuiIcons = {
  EditIcon, AddCircleOutlineIcon, FileDownloadIcon, HistoryIcon, DeleteForeverIcon,
  AccountCircle, LockIcon, Visibility, VisibilityOff, EmailIcon, DashboardIcon,
  FactCheckIcon, FileOpenIcon, TransferWithinAStationIcon, AssignmentTurnedInIcon,
  HighlightOffIcon, IconExpandLess, IconExpandMore, ViewListIcon, EventRepeatIcon,
  PendingActionsIcon, EventSeatIcon, MenuIcon, ChevronLeftIcon, ChevronRightIcon,
  AssignmentIndIcon, CancelIcon, ThumbDownAltIcon, ThumbUpAltIcon, AddCircleIcon
};

export default MuiIcons;

