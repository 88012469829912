import React from "react";
import { FormControl, FormHelperText, IconButton, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
//import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import LabelField from "./LabelField";
import ClearIcon from '@mui/icons-material/Clear';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
//import validate from "../validation/dateFieldValidation";

const DateField = (props) => {
  const { onClear, label, ...rest } = props;
  //console.log("value,onChange ", value, onChange)
  // const [value, setValue] = React.useState(null);


  // const [value, setValue] = React.useState('');
  // const [error, setError] = React.useState('');

  // const handleChange = (event) => {
  //   const inputValue = event.target.value;
  //   const errorMessage = validate(inputValue);
  //   setError(errorMessage);
  //   //setValue(inputValue);
  // };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <LabelField label={label} />
        <FormControl>
          <DatePicker
            {...rest}
            // value={value}
            //onChange={handleChange}
            renderInput={(params) => (
              <div style={{ display: "flex" }}>
                <TextField variant="standard" {...params} />
                {props.value &&
                  <IconButton style={{ position: "relative" }} onClick={() => onClear()}>
                    <ClearIcon />
                  </IconButton>
                }
              </div>
            )
            }
          />
        </FormControl>
      </LocalizationProvider >
      <FormHelperText error sx={{ position: "absolute" }}>
        {/* {error && <span>{error}</span>} */}
      </FormHelperText>

    </>
  );
};

export default DateField;
