import { InputLabel } from '@mui/material';
import React from 'react';

const LabelField = ({label}) => {
  return (
    <InputLabel
      sx={{
        fontSize: "13px"
      }}
    >
      {label}
    </InputLabel>
  );
};
export default LabelField;
