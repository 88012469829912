import { Card, CardContent, Grid, Typography } from "@mui/material";
import Animate from "../Animate";
import { DoubleArrow } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actions as commonSliceActions } from "../../../modules/common/common/slice";
import LoadingOverlay from "react-loading-overlay-ts";
import { ScaleLoader } from "react-spinners";


const CustomTileCard = ({ title, value, bgColor, showMoreInfo = true, slaReport = false, slaPath,
    navigateInfo: { dashboardFilters = {} } = {}, colors = ["#044197cc", "#010101"],
    path = "/sdc/service-request", inProgress = false }) => {
    // const colorIndex = value % bgColor.length;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const hex2rgba = (hex, alpha = 1) => {
        const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
        return `rgba(${r},${g},${b},${alpha})`;
    };

    const colorIndex = bgColor?.findIndex(color => color.id === title);
    return (
        <>
            <LoadingOverlay
                active={inProgress}
                spinner={<ScaleLoader color="white" />}
            >
                <Card sx={{ textAlign: "center", height: "100%", backgroundColor: bgColor[colorIndex]?.color || bgColor, color: "white" }}>
                    <CardContent>
                        <Typography sx={{ fontSize: "22px" }}>{title}</Typography>
                        <Typography sx={{ fontSize: "20px" }}>{value}</Typography>
                    </CardContent>
                    {showMoreInfo && <Grid item xs={12} sx={{
                        cursor: "pointer",
                        color: "white", background: `linear-gradient(45deg, ${hex2rgba(colors[1])}, ${hex2rgba(colors[0])})`
                    }}>
                        <div onClick={() => {
                            { dashboardFilters && dispatch(commonSliceActions.setCommonFilters(dashboardFilters)); }
                            {
                                slaReport ?
                                    navigate({ pathname: slaPath })
                                    :
                                    navigate({
                                        pathname: path,
                                        search: `?status=${title}`
                                    });
                            }
                        }
                        }>
                            <Typography align='right' variant='h6' alignItems='center'>
                                <Animate.move x={-15}>
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexWrap: "wrap"
                                    }}>
                                        <DoubleArrow />
                                        <Typography sx={{ ml: 1, mr: 1, mt: 0.5 }}>More Info</Typography>
                                        <DoubleArrow />
                                    </div>
                                </Animate.move>
                            </Typography>
                        </div>
                    </Grid>}
                </Card >
            </LoadingOverlay >
        </>
    );
};

export default CustomTileCard;
