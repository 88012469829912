import { Navigate } from "./modules/common/common/components";
import { LoginPage } from "modules/common/Login/components";
import React from "react";
import { Outlet } from "react-router-dom";


function App() {
  return (
    <div >
      <Navigate />
      <LoginPage />
      <Outlet />
    </div>
  );
}

export default App;
