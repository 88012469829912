import { FormHelperText, InputLabel } from "@mui/material";
import { MuiComponents } from "../../../components";

const { FormControl, TextField } = MuiComponents;

const RenderTextField = ({
  label,
  input,
  placeholder,
  meta: { touched, error },
  isMandatory = false,
  ...custom
}) => {
  return (
    <>
      <InputLabel sx={{ fontSize: "13px" }}>
        {label} {isMandatory && <span style={{ color: "red", fontSize: "15px" }}>*</span>}</InputLabel>
      <FormControl error={touched && error}>
        <TextField
          {...input}
          {...custom}
          value={input.value}
          placeholder={placeholder}
          error={touched && error}
        />
      </FormControl>
      {!!touched && (
        <FormHelperText error sx={{ position: "relative" }}>
          {error}
        </FormHelperText>
      )}
    </>
  );
};

export default RenderTextField;
