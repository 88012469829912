import { createAction } from '@reduxjs/toolkit';
import { STATE_REDUCER_KEY } from './constants';

export const ACTION_TYPES = {

    FETCH_DISTRICT_WISE_CAMERA_DETAILS: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_WISE_CAMERA_DETAILS`,

    FETCH_DISTRICT_WISE_CAMERA_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_WISE_CAMERA_REQUEST`,
    FETCH_DISTRICT_WISE_CAMERA_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_WISE_CAMERA_SUCCESS`,
    FETCH_DISTRICT_WISE_CAMERA_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DISTRICT_WISE_CAMERA_FAILURE`,

    FETCH_LOCATION_WISE_CAMERA_DETAILS: `${STATE_REDUCER_KEY}/FETCH_LOCATION_WISE_CAMERA_DETAILS`,

    FETCH_LOCATION_WISE_CAMERA_REQUEST: `${STATE_REDUCER_KEY}/FETCH_LOCATION_WISE_CAMERA_REQUEST`,
    FETCH_LOCATION_WISE_CAMERA_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_LOCATION_WISE_CAMERA_SUCCESS`,
    FETCH_LOCATION_WISE_CAMERA_FAILURE: `${STATE_REDUCER_KEY}/FETCH_LOCATION_WISE_CAMERA_FAILURE`,

    FETCH_CAMERA_DETAILS: `${STATE_REDUCER_KEY}/FETCH_CAMERA_DETAILS`,

    FETCH_CAMERA_DETAILS_REQUEST: `${STATE_REDUCER_KEY}/FETCH_CAMERA_DETAILS_REQUEST`,
    FETCH_CAMERA_DETAILS_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_CAMERA_DETAILS_SUCCESS`,
    FETCH_CAMERA_DETAILS_FAILURE: `${STATE_REDUCER_KEY}/FETCH_CAMERA_DETAILS_FAILURE`

};

export const fetchDistrictWiseCameraCount = createAction(ACTION_TYPES.FETCH_DISTRICT_WISE_CAMERA_DETAILS);
export const fetchLocationWiseCameraDetails = createAction(ACTION_TYPES.FETCH_LOCATION_WISE_CAMERA_DETAILS);
export const fetchCameraDetails = createAction(ACTION_TYPES.FETCH_CAMERA_DETAILS);
