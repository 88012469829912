import React from "react";
import { Grid } from "@mui/material";
import AssignForm from "./AssignForm";
import AssignView from "./AssignView";

const AssignPage = () => {

  return (
    <>
      <Grid>
        <AssignView />
        <AssignForm />
      </Grid>
    </>
  );
};

export default AssignPage;
