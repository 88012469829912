import _ from "lodash";

const checkIfDuplicateExists = (val) => {
  // eslint-disable-next-line no-undef
  let check = new Set(val).size !== val.length;
  return check;
};

export default function validate(values) {

  let newArray = [];
  let systemArray = [];
  let duplicateExist = false;

  const errors = {};

  if (!values.districtId) {
    errors.districtId = "District Required";
  }
  if (!values.locationId) {
    errors.locationId = "Location Required";
  }
  if (!values.actionRequiredId) {
    errors.actionRequiredId = "please select action required";
  }
  if (!values.contactNumber) {
    errors.contactNumber = "contact number required";
  }
  if (!values.contactEmail) {
    errors.contactEmail = "contact email required";
  }
  if (!values.contactName) {
    errors.contactName = "contact name required";
  }
  if (values?.contactNumber) {
    if (!/^(0|[1-9][0-9]{9})$/i.test(values.contactNumber)) {
      errors.contactNumber = "Please provide valid phone number";
    }
  }
  if (values?.contactEmail) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.contactEmail)) {
      errors.contactEmail = "Please provide valid email address";
    }
  }
  if (
    !_.has(values, "ticketProblems") ||
    _.get(values, "ticketProblems", "") === null
  ) {
    _.set(errors, "ticketProblems", "required");
  } else {
    const membersArrayErrors = [];
    values.ticketProblems.forEach((member, memberIndex) => {
      const memberErrors = {};
      if (!member || !member.problemCategoryId) {
        memberErrors.problemCategoryId = "please select a problem category";
        membersArrayErrors[memberIndex] = memberErrors;
      }
      if (!member || !member.problemTypesId) {
        memberErrors.problemTypesId = "please select a problem type";
        membersArrayErrors[memberIndex] = memberErrors;
      }
      if (member.problemTypesId) {
        newArray.push(Number(member.problemTypesId));
      }
      if (newArray.length > 0) {
        duplicateExist = checkIfDuplicateExists(newArray);
      }
      if (duplicateExist === true && newArray.length > 0) {
        memberErrors.problemTypesId = 'Duplicate entry occurred';
        membersArrayErrors[memberIndex] = memberErrors;
      }
    });

    if (membersArrayErrors.length) {
      errors.ticketProblems = membersArrayErrors;
    }
  }

  if (
    !_.has(values, "systemStatusPayload") ||
    _.get(values, "systemStatusPayload", "") === null
  ) {
    _.set(errors, "systemStatusPayload", "required");
  } else {
    const membersArrayErrors = [];
    values.systemStatusPayload.forEach((member, memberIndex) => {
      const memberErrors = {};
      if (!member || !member.systemId) {
        memberErrors.systemId = "please select a system type";
        membersArrayErrors[memberIndex] = memberErrors;
      }
      if (!member || member.statusId === undefined) {
        memberErrors.statusId = "please select a system status";
        membersArrayErrors[memberIndex] = memberErrors;
      }
      if (member.systemId) {
        systemArray.push(Number(member.systemId));
      }
      if (systemArray.length > 0) {
        duplicateExist = checkIfDuplicateExists(systemArray);
      }
      if (duplicateExist === true && systemArray.length > 0) {
        memberErrors.systemId = 'Duplicate entry occurred';
        membersArrayErrors[memberIndex] = memberErrors;
      }
    });

    if (membersArrayErrors.length) {
      errors.systemStatusPayload = membersArrayErrors;
    }
  }
  return errors;
}
