import { flow } from "lodash";
import { STATE_REDUCER_KEY } from "./constants";

const getCommon = (state) => state[STATE_REDUCER_KEY];

const navigate = (common) => common?.navigate;
export const getNavigateTo = flow(getCommon, navigate);

const tableProps = (common) => common?.tableProps;
export const getTableProps = flow(getCommon, tableProps);

const getFilterData = (common) => common?.filterValues;
export const getFilterValues = flow(getCommon, getFilterData);
