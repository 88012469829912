import FormComponents from "./form-components";
import Button from "./Button";
import ReactNotifications from "./ReactNotifications";
import RootBoundary from "./RootBoundary";
import AccountMenu from './AccountMenu';
import ReactTable from "./ReactTable";
import DottedMenu from "./DottedMenu";
import CustomBadge from "./CustomBadge";
import CustomCard from "./CustomCard";
import PopUp from "./PopUp";
import CustomTileCard from "./CustomTileCard";

const CustomComponents = {
    ...FormComponents,
    Button,
    ReactNotifications,
    RootBoundary,
    AccountMenu,
    ReactTable,
    DottedMenu,
    CustomBadge,
    CustomCard,
    CustomTileCard,
    PopUp
};

export default CustomComponents;


