import React, { useEffect, useState, useRef } from "react";
import { MapContainer, Marker, Popup, TileLayer, useMap, GeoJSON, Tooltip } from "react-leaflet";
import "leaflet/dist/leaflet.css";
//import icon from "./constants";
import geoJson from '../../../../assets/map/Kerala_Districts.geo.json';
import L from 'leaflet';
import { HTTP_CONSTANTS } from "../../../../common/constants";
import LocationOnIcon from '@mui/icons-material/LocationOn';

const Location = () => {

  const [cameraData, setCameraData] = useState([]);
  const [cameraListData, setCameraListData] = useState([]);
  const [cameraView, setCameraView] = useState(false);
  const [cameraDetails, setCameraDetails] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState("");

  const geoRef = useRef();

  useEffect(() => {
    fetch(`${HTTP_CONSTANTS.BASE_URL}/master/location/list/all/district-wise-camera-cound`)
      .then(response => response.json())
      .then(data => {
        setCameraData(data.payLoad);
      });

    if (selectedDistrict !== "") {
      fetch(`${HTTP_CONSTANTS.BASE_URL}/master/location/list/all/district-wise-camera-details?districtId=` + selectedDistrict + '&moduleId=1')
        .then(response => response.json())
        .then(res => {
          setCameraListData(res.payLoad);
          //setCameraData(cDetails);
          res.payLoad.map((camId) => {
            fetch(`${HTTP_CONSTANTS.BASE_URL}/master/location-asset/list/all/camera-details-by-locationid?locationId=` + camId.locationId)
              .then(response => response.json())
              .then(camData => {
                setCameraDetails(camData.payLoad);
              });
          });
        });
    }
  }, [selectedDistrict]);

  const Markers = () => {
    const map = useMap();
    if (selectedDistrict !== "") {
      const layer = geoRef.current
        .getLayers()
        .find((layer1) => layer1?.feature?.properties?.District_I.toString() === selectedDistrict);
      map.fitBounds(layer.getBounds());
      //.find((layer1) => layer1.feature.properties.District_I === selectedDistrict);
    }
    if (cameraView) {
      return (
        cameraListData.length > 0 &&
        cameraListData.map((marker, index) => {
          return (
            <Marker
              eventHandlers={{
                click: () => {
                  map.setView(
                    [
                      marker.latitude,
                      marker.longitude
                    ],
                    14
                  );
                  setCameraView(true);
                  //handleCameraDetails(marker.locationId);
                }
              }}
              key={index}
              position={{
                lat: marker.latitude,
                lng: marker.longitude
              }}
              icon={
                L.divIcon({
                  className: 'pointer-icon-default',
                  iconSize: [55, 63],
                  iconAnchor: [12, 41],
                  shadowSize: [41, 41]
                })
              }
              autoPanOnFocus={index}
            >
              <Tooltip>
                <span>{marker.locationName}</span>
              </Tooltip>
              <Popup>
                <>
                  <div style={{ background: "#000000", color: "#FFFFFF", fontSize: "14px", padding: "8px" }}><LocationOnIcon style={{ float: "left" }} />{marker?.locationName}</div>
                  <div style={{ background: "#E7E7E7", fontSize: "13px", padding: "8px", borderRadius: "4px", marginTop: "10px" }}>Device Type: <b>{marker?.deviceType}</b></div>
                  <ul style={{ padding: "0px 0px 10px 0px" }}>
                    {cameraDetails.map((camData, i) => (
                      <li key={i} style={{ listStyle: "none", fontSize: "12px", borderRadius: "4px", background: "#E7E7E7", margin: "4px 0px", padding: "5px" }}>{camData.assetname}:<b>{camData.assetNos} {camData.assetUnit}</b></li>
                    ))
                    }
                  </ul>
                </>
              </Popup>
            </Marker>
          );
        })
      );
    } else {
      return (
        cameraData?.length > 0 &&
        cameraData?.map((marker, index) => {
          return (
            <Marker
              eventHandlers={{
                click: () => {
                  map.setView(
                    [
                      marker.latitude,
                      marker.longitude
                    ],
                    10
                  );
                  fetch(`${HTTP_CONSTANTS.BASE_URL}/master/location/list/all/district-wise-camera-details?districtId=` + marker.districtId + '&moduleId=1')
                    .then(response => response.json())
                    .then(res => {
                      setCameraListData(res.payLoad);
                      //setCameraData(cDetails);
                      res.payLoad.map((camId) => {
                        fetch(`${HTTP_CONSTANTS.BASE_URL}/master/location-asset/list/all/camera-details-by-locationid?locationId=` + camId.locationId)
                          .then(response => response.json())
                          .then(camData => {
                            setCameraDetails(camData.payLoad);
                          });
                      });
                    });
                  setCameraView(true);
                }
              }}
              key={index}
              position={{
                lat: marker.latitude,
                lng: marker.longitude
              }}
              icon={
                L.divIcon({
                  className: 'number-icon-default',
                  iconSize: [55, 63],
                  iconAnchor: [12, 41],
                  shadowSize: [41, 41],
                  html: marker.totalCameras
                })
              }
              autoPanOnFocus={index}
            >
              {/* <Tooltip permanent >
                <span>{marker.totalCameras}</span>
              </Tooltip> */}
            </Marker>
          );
        })
      );
    }
  };

  return (
    <MapContainer
      center={[10.1632, 76.6413]}
      zoom={8}
      scrollWheelZoom={false}
      style={{ height: "95vh" }}
    >
      <div style={{ height: "15px", position: "relative", zIndex: 400, top: "16px", left: "51px", width: "170px" }}>
        <div>
          <select id="district" onChange={(e) => {
            setSelectedDistrict(e.target.value);
          }} value={selectedDistrict}>
            <option value="">Select District</option>
            <option value="1">Thiruvananthapuram</option>
            <option value="2">Kollam</option>
            <option value="3">Pathanamthitta</option>
            <option value="4">Alappuzha</option>
            <option value="5">Kottayam</option>
            <option value="6">Idukki</option>
            <option value="7">Ernakulam</option>
            <option value="8">Thrissur</option>
            <option value="9">Palakkad</option>
            <option value="10">Malappuram</option>
            <option value="11">Kozhikkode</option>
            <option value="12">Wayanad</option>
            <option value="13">Kannur</option>
            <option value="14">Kasaragod</option>
          </select>
        </div>
        <button onClick={() => {
          location.reload();
        }}>Reset</button>
      </div>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      <GeoJSON
        data={geoJson}
        ref={geoRef}
      />
      <Markers />
    </MapContainer>
  );
};

export default Location;
