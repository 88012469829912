import { Backdrop, Box, Card, Divider, Fade, Grid, IconButton, Modal, Paper } from '@mui/material';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getCameraTicketCountData, getCameraTypeWiseCount, getCcrDashboardCount, getCcrTicketCountData, getDcrDashboardCount, getDcrTicketCountData, getItAndNonItCount, getSdcDashboardCount } from '../actions';
import { getCameraTicketCounts, getCameraTypeWiseCounts, getCcrDashboardDetails, getCcrTicketCounts, getDashboardDetails, getDcrDashboardDetails, getDcrTicketCounts, getItAndNonItDataCount, getSdcDashboardDetails, getTotalStatusCount } from '../selectors';
import { Bar, Doughnut } from 'react-chartjs-2';
import { barConfig, bgColor, bgDashboardColor, chartConfig, Config, formatFunction, itNonItChartConfig, paperStyle, sdcChartConfig, slaCardColor, style } from '../constants';
import Filter from 'common/components/custom/form-components/Filter';
import { useSelector } from 'react-redux/es/exports';
import { STATE_REDUCER_KEY } from 'modules/common/common';
import { STATE_REDUCER_KEY as REDUCER_KEY } from "../constants";
import _ from "lodash";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  ArcElement,
  Legend
} from 'chart.js';
import CustomCard from 'common/components/custom/CustomCard';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Tooltip as Tool } from '@mui/material';
import { getUserDetailsData } from 'modules/common/Login/selectors';
import CustomTileCard from 'common/components/custom/CustomTileCard';
import { USER_ROLE_ID } from 'modules/common/common/constants';
import { getSlaReport } from 'modules/admin/serviceRequest/actions';
import { getPagintation } from 'modules/admin/serviceRequest/selectors';
//import { actions as commonSlice } from "../../common/slice";


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ArcElement,
  Tooltip,
  Legend
);


const Dashboard = (props) => {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { loading: itNonItLoading = false } = useSelector((state) => _.get(state, `${REDUCER_KEY}.itAndNonItCount`));
  const { loading: sdcDashboardLoading = false } = useSelector((state) => _.get(state, `${REDUCER_KEY}.sdcDashboardCount`));
  const { loading: dcrDashboardLoading = false } = useSelector((state) => _.get(state, `${REDUCER_KEY}.dcrDashboardCount`));
  const { loading: ccrDashboardLoading = false } = useSelector((state) => _.get(state, `${REDUCER_KEY}.ccrDashboardCount`));

  const dispatch = useDispatch();
  const { cameraTicketCounts = [], ccrTicketCounts = {}, dcrTicketCounts = {},
    cameraTypeWiseCounts = {}, itAndNonItCounts = {}, userDetails: { userDetails: { userRoles = [] } = {} } = {},
    sdcDashboardCounts = {}, ccrDashboardCounts = {}, dcrDashboardCounts = {}, reportCount: { totalRecords = "" } = {} } = props;

  const { filterValues = {} } = useSelector((state) => _.get(state, STATE_REDUCER_KEY));

  let statusCount;
  if (userRoles[0]?.userRoleId === USER_ROLE_ID?.SERVICE_MANAGER_ROLE_ID) {
    statusCount = formatFunction(cameraTypeWiseCounts);
  }

  useEffect(() => {
    if (userRoles[0]?.userRoleId === USER_ROLE_ID?.SDC_ROLE_ID) {
      dispatch(getSdcDashboardCount({ filterValues }));
    } else if (userRoles[0]?.userRoleId === USER_ROLE_ID?.DCR_ROLE_ID) {
      dispatch(getDcrDashboardCount({ filterValues }));
    } else if (userRoles[0]?.userRoleId === USER_ROLE_ID?.CCR_ROLE_ID) {
      dispatch(getDcrDashboardCount({ filterValues }));
      dispatch(getCcrDashboardCount({ filterValues }));
    } else if (userRoles[0]?.userRoleId === USER_ROLE_ID?.SERVICE_MANAGER_ROLE_ID) {
      dispatch(getCameraTypeWiseCount({ filterValues }));
      dispatch(getDcrTicketCountData({ filterValues }));
      dispatch(getCcrTicketCountData({ filterValues }));
      dispatch(getCameraTicketCountData({ filterValues }));
      dispatch(getSlaReport({ filterValues }));
    } else if (userRoles[0]?.userRoleId === USER_ROLE_ID?.DATA_CENTER_GROUP_ROLE_ID || userRoles[0]?.userRoleId === USER_ROLE_ID?.PERMIS_SUPPORT_GROUP_ROLE_ID) {
      dispatch(getSlaReport({ filterValues }));
      dispatch(getItAndNonItCount({ filterValues }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues]);

  // useEffect(() => {
  //   // return () => {
  //   dispatch(commonSlice.resetCommonFilters());
  //   // };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  let itNonItData;
  if (userRoles[0]?.userRoleId === USER_ROLE_ID?.DATA_CENTER_GROUP_ROLE_ID || userRoles[0]?.userRoleId === USER_ROLE_ID?.PERMIS_SUPPORT_GROUP_ROLE_ID) {
    const itNonItValues =
      itAndNonItCounts?.map((item) => (
        item?.ticketcount || 0
      ));

    const itNonItLabels =
      itAndNonItCounts?.map((item) => (
        item?.ticketstatus || 0
      ));


    itNonItData = {
      labels: itNonItLabels,
      datasets: [
        {
          label: "Ticket count",
          data: itNonItValues
        }
      ]
    };

  }

  let sdcDashboardData;
  if (userRoles[0]?.userRoleId === USER_ROLE_ID?.SDC_ROLE_ID) {
    const sdcValues =
      sdcDashboardCounts?.map((item) => (
        item?.ticketsCount || 0
      ));

    const sdcLabels =
      sdcDashboardCounts?.map((item) => (
        item?.ticketStatus || 0
      ));

    sdcDashboardData = {
      labels: sdcLabels,
      datasets: [
        {
          label: "Ticket count",
          data: sdcValues
        }
      ]
    };

  }

  let dcrDashboardData;
  if (userRoles[0]?.userRoleId === USER_ROLE_ID?.DCR_ROLE_ID) {
    const dcrValues =
      dcrDashboardCounts?.map((item) => (
        item?.ticketsCount || 0
      ));

    const dcrLabels =
      dcrDashboardCounts?.map((item) => (
        item?.ticketStatus || 0
      ));

    dcrDashboardData = {
      labels: dcrLabels,
      datasets: [
        {
          label: "Ticket count",
          data: dcrValues
        }
      ]
    };

  }

  let ccrDashboardData;
  if (userRoles[0]?.userRoleId === USER_ROLE_ID?.CCR_ROLE_ID) {

    const dcrValues =
      dcrDashboardCounts?.map((item) => (
        item?.ticketsCount || 0
      ));

    const dcrLabels =
      dcrDashboardCounts?.map((item) => (
        item?.ticketStatus || 0
      ));

    dcrDashboardData = {
      labels: dcrLabels,
      datasets: [
        {
          label: "Ticket count",
          data: dcrValues
        }
      ]
    };

    const ccrValues =
      ccrDashboardCounts?.map((item) => (
        item?.ticketsCount || 0
      ));

    const ccrLabels =
      ccrDashboardCounts?.map((item) => (
        item?.ticketStatus || 0
      ));

    ccrDashboardData = {
      labels: ccrLabels,
      datasets: [
        {
          label: "Ticket count",
          data: ccrValues
        }
      ]
    };

  }

  const filterFlags = {
    startDateFlag: true,
    endDateFlag: true,
    ticketIdFlag: false,
    issueTypeFlag: false,
    assignFlag: false,
    districtFlag: true,
    siteIdFlag: false,
    statusFlag: false,
    problemTypeFlag: false,
    problemCategoryFlag: false
  };

  return (
    <Grid>
      {userRoles[0]?.userRoleId === USER_ROLE_ID?.SERVICE_MANAGER_ROLE_ID && <Paper sx={paperStyle}>
        <Tool arrow title="Filter">
          <IconButton onClick={handleOpen} sx={{ float: "right" }}>
            <FilterAltIcon />
          </IconButton>
        </Tool>
        <Card sx={{ height: 320, mb: 2 }}>
          <Bar data={statusCount?.data} options={
            {
              ...barConfig,
              ...Config,
              scales: {},
              maintainAspectRatio: false
            }
          }
            height={8} width={8}
          /></Card>

        <Grid container spacing={3}>
          <Grid item xs={12} md={2.4} sm={2.4}>
            <CustomCard
              {...{
                label: 'Total Cameras -',
                label4: "UP",
                label5: "DOWN",
                count: cameraTypeWiseCounts[0]?.totalCameras,
                up: cameraTypeWiseCounts[0]?.upCameras,
                down: cameraTypeWiseCounts[0]?.downCameras,
                color: 'red',
                p: 1
              }}
            />
          </Grid>
          <Grid item xs={12} md={2.4} sm={2.4}>
            <CustomCard
              {...{
                label: 'AI Cameras -',
                label4: "UP", label5: "DOWN",
                count: cameraTypeWiseCounts[1]?.totalCameras,
                color: 'red',
                p: 1,
                up: cameraTypeWiseCounts[1]?.upCameras,
                down: cameraTypeWiseCounts[1]?.downCameras
              }}
            />
          </Grid>
          <Grid item xs={12} md={2.4} sm={2.4}>
            <CustomCard
              {...{
                label: 'PVDS -', label4: "UP", label5: "DOWN",
                count: cameraTypeWiseCounts[2]?.totalCameras, color: 'red', p: 1,
                up: cameraTypeWiseCounts[2]?.upCameras,
                down: cameraTypeWiseCounts[2]?.downCameras
              }}
            />
          </Grid>
          <Grid item xs={12} md={2.4} sm={2.4}>
            <CustomCard
              {...{
                label: 'RLVDS -', label4: "UP", label5: "DOWN",
                count: cameraTypeWiseCounts[3]?.totalCameras,
                color: 'red',
                p: 1,
                up: cameraTypeWiseCounts[3]?.upCameras,
                down: cameraTypeWiseCounts[3]?.downCameras
              }}
            />
          </Grid>
          <Grid item xs={12} md={2.4} sm={2.4}>
            <CustomCard
              {...{
                label: 'SVDS -',
                label4: "UP",
                label5: "DOWN",
                count: cameraTypeWiseCounts[4]?.totalCameras, color: 'red', p: 1,
                up: cameraTypeWiseCounts[4]?.upCameras,
                down: cameraTypeWiseCounts[4]?.downCameras
              }}
            />
          </Grid>
        </Grid>
      </Paper>}

      {userRoles[0]?.userRoleId === USER_ROLE_ID?.SERVICE_MANAGER_ROLE_ID && <Grid>
        <Grid item xs={12} md={6} sm={4}>
          <Divider sx={{ fontSize: 35, fontFamily: "Luminari", fontWeight: "bold", color: "#cc5233" }}>TICKETS</Divider>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={3} md={3} sm={3}>
            <CustomCard
              {...{
                label: 'Central Control Room', label1: "Total No.of Tickets",
                label2: "Tickets Open", label3: "Tickets Closed", color: 'red', p: 1,
                total: ccrTicketCounts[0]?.totalTickets, opened: ccrTicketCounts[0]?.openTickets, closed: ccrTicketCounts[0]?.closedTickets,
                showMoreInfo: true,
                navigateInfo: { dashboardFilters: filterValues },
                navigateTitle: "ccr"
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3} md={3} sm={3}>
            <CustomCard
              {...{
                label: 'District Control Room', label1: "Total No.of Tickets",
                label2: "Tickets Open", label3: "Tickets Closed", color: 'red',
                p: 1, total: dcrTicketCounts[0]?.totalTickets, opened: dcrTicketCounts[0]?.openTickets, closed: dcrTicketCounts[0]?.closedTickets,
                showMoreInfo: true,
                navigateInfo: { dashboardFilters: filterValues },
                navigateTitle: "dcr"
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3} md={3} sm={3}>
            <CustomCard
              {...{
                label: 'Field Cameras', label1: "Total No.of Tickets",
                label2: "Tickets Open", label3: "Tickets Closed", color: 'red',
                p: 1, total: cameraTicketCounts[0]?.totalTickets, opened: cameraTicketCounts[0]?.openTickets,
                closed: cameraTicketCounts[0]?.closedTickets, showMoreInfo: true,
                navigateInfo: { dashboardFilters: filterValues },
                navigateTitle: "sdc"
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3} md={3} sm={3}>
            <CustomCard
              {...{
                label: 'SLA Report Status', label1: "",
                label2: "SLA Breached Ticket Count", label3: "", color: 'red',
                p: 1, opened: totalRecords, showMoreInfo: true,
                slaReport: true,
                slaPath: "/sdc/service-request/sla-report/2"
              }}
            />
          </Grid>
        </Grid>
      </Grid>}

      {userRoles[0]?.userRoleId === USER_ROLE_ID?.SDC_ROLE_ID &&
        <>
          <Tool arrow title="Filter">
            <IconButton onClick={handleOpen} sx={{ float: "right" }}>
              <FilterAltIcon />
            </IconButton>
          </Tool>
          <Grid container spacing={3}>
            {sdcDashboardCounts?.map((item, index) => (
              <Grid key={index} item xs={12} sm={4} md={4}>
                <CustomTileCard
                  title={item?.ticketStatus}
                  value={item?.ticketsCount || 0}
                  bgColor={bgDashboardColor}
                  inProgress={sdcDashboardLoading}
                  navigateInfo={{ dashboardFilters: filterValues }}
                />
              </Grid>
            ))}
          </Grid>
          <Grid elevation={6} style={{ margin: "auto", height: "20rem" }}>
            <Doughnut
              data={sdcDashboardData}
              options={
                {
                  ...sdcChartConfig,
                  maintainAspectRatio: false
                }}
            />
          </Grid>
        </>
      }

      {userRoles[0]?.userRoleId === USER_ROLE_ID?.DCR_ROLE_ID &&
        <>
          <Tool arrow title="Filter">
            <IconButton onClick={handleOpen} sx={{ float: "right" }}>
              <FilterAltIcon />
            </IconButton>
          </Tool>
          <Grid container spacing={3}>
            {dcrDashboardCounts?.map((item, index) => (
              <Grid key={index} item xs={12} sm={4} md={4}>
                <CustomTileCard
                  title={item?.ticketStatus}
                  value={item?.ticketsCount || 0}
                  bgColor={bgColor}
                  inProgress={dcrDashboardLoading}
                  navigateInfo={{ dashboardFilters: filterValues }}
                />
              </Grid>
            ))}
          </Grid>

          <Grid elevation={6} style={{ margin: "auto", height: "20rem" }}>
            <Doughnut
              data={dcrDashboardData}
              options={
                {
                  ...chartConfig,
                  maintainAspectRatio: false
                }}
            />
          </Grid>
        </>
      }

      {(userRoles[0]?.userRoleId === USER_ROLE_ID?.DATA_CENTER_GROUP_ROLE_ID || userRoles[0]?.userRoleId === USER_ROLE_ID?.PERMIS_SUPPORT_GROUP_ROLE_ID) &&
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} md={4}>
              <CustomTileCard
                title={"SLA Breached Count"}
                value={totalRecords || 0}
                bgColor={slaCardColor}
                slaReport={true}
                slaPath={"/sdc/service-request/sla-report/2"}
                inProgress={itNonItLoading}
              />
            </Grid>
            {itAndNonItCounts?.map((item, index) => (
              <Grid key={index} item xs={12} sm={4} md={4}>
                <CustomTileCard
                  title={item?.ticketstatus}
                  value={item?.ticketcount || 0}
                  bgColor={bgColor}
                  inProgress={itNonItLoading}
                  navigateInfo={{ dashboardFilters: filterValues }}
                />
              </Grid>

            ))}

          </Grid>
          <Grid elevation={6} style={{ margin: "auto", height: "20rem" }}>
            <Doughnut
              data={itNonItData}
              options={
                {
                  ...itNonItChartConfig,
                  maintainAspectRatio: false
                }}
            />
          </Grid>
        </>
      }

      {userRoles[0]?.userRoleId === USER_ROLE_ID?.CCR_ROLE_ID &&
        <>
          <Grid>
            <Tool arrow title="Filter">
              <IconButton onClick={handleOpen} sx={{ float: "right" }}>
                <FilterAltIcon />
              </IconButton>
            </Tool>
          </Grid><br />
          <Grid item xs={12} md={6} sm={4}>
            <Divider sx={{ fontSize: 30, fontFamily: "Luminari", fontWeight: "bold", color: "#cc5233" }}>DCR TICKETS</Divider>
          </Grid><br />
          <Grid container spacing={3}>
            {dcrDashboardCounts?.map((item, index) => (
              <Grid key={index} item xs={12} sm={4} md={4}>
                <CustomTileCard
                  title={item?.ticketStatus}
                  value={item?.ticketsCount || 0}
                  bgColor={bgColor}
                  inProgress={dcrDashboardLoading}
                  navigateInfo={{ dashboardFilters: filterValues }}
                />
              </Grid>
            ))}
          </Grid>
          <Grid elevation={6} style={{ margin: "auto", height: "20rem" }}>
            <Doughnut
              data={dcrDashboardData}
              options={
                {
                  ...chartConfig,
                  maintainAspectRatio: false
                }}
            />
          </Grid>
          <Grid item xs={12} md={6} sm={4}>
            <Divider sx={{ fontSize: 30, fontFamily: "Luminari", fontWeight: "bold", color: "#cc5233" }}>CCR TICKETS</Divider>
          </Grid><br />
          <Grid container spacing={3}>
            {ccrDashboardCounts?.map((item, index) => (
              <Grid key={index} item xs={12} sm={4} md={4}>
                <CustomTileCard
                  title={item?.ticketStatus}
                  value={item?.ticketsCount || 0}
                  bgColor={bgColor}
                  inProgress={ccrDashboardLoading}
                  navigateInfo={{ dashboardFilters: filterValues }}
                />
              </Grid>
            ))}
          </Grid>

          <Grid elevation={6} style={{ margin: "auto", height: "20rem" }}>
            <Doughnut
              data={ccrDashboardData}
              options={
                {
                  ...chartConfig,
                  maintainAspectRatio: false
                }}
            />
          </Grid>

        </>
      }

      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500
          }
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Filter filterFlags={filterFlags} handleClose={handleClose} />
          </Box>
        </Fade>
      </Modal>
    </Grid >

  );
};


const mapStateToProps = createStructuredSelector({
  totalCount: getTotalStatusCount,
  dashboardData: getDashboardDetails,
  cameraTicketCounts: getCameraTicketCounts,
  ccrTicketCounts: getCcrTicketCounts,
  dcrTicketCounts: getDcrTicketCounts,
  cameraTypeWiseCounts: getCameraTypeWiseCounts,
  itAndNonItCounts: getItAndNonItDataCount,
  sdcDashboardCounts: getSdcDashboardDetails,
  ccrDashboardCounts: getCcrDashboardDetails,
  dcrDashboardCounts: getDcrDashboardDetails,
  userDetails: getUserDetailsData,
  reportCount: getPagintation
});


export default connect(mapStateToProps)(Dashboard);
