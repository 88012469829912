import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { ACTION_TYPES } from "./actions";
import { STATE_REDUCER_KEY } from "./constants";

const initialState = {
    districtWiseCameraCount: {
        loading: false,
        data: []
    },
    locationWiseCameraDetails: {
        loading: false,
        data: []
    },
    cameraDetails: {
        loading: false,
        data: []
    }
};

const slice = createSlice({
    initialState,
    name: STATE_REDUCER_KEY,
    reducers: {
        resetLocationDetails: (state) => {
            state.locationWiseCameraDetails.data = initialState.locationWiseCameraDetails.data;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ACTION_TYPES.FETCH_DISTRICT_WISE_CAMERA_REQUEST, (state) => {
                state.districtWiseCameraCount.loading = true;
            })
            .addCase(
                ACTION_TYPES.FETCH_DISTRICT_WISE_CAMERA_SUCCESS,
                (state, action) => {
                    state.districtWiseCameraCount.data = _.get(action, "payload.response.payLoad", []);
                }
            )
            .addCase(ACTION_TYPES.FETCH_LOCATION_WISE_CAMERA_REQUEST, (state) => {
                state.locationWiseCameraDetails.loading = true;
            })
            .addCase(
                ACTION_TYPES.FETCH_LOCATION_WISE_CAMERA_SUCCESS,
                (state, action) => {
                    state.locationWiseCameraDetails.data = _.get(action, "payload.response.payLoad", []);
                    state.locationWiseCameraDetails.loading = false;
                }
            )
            .addCase(ACTION_TYPES.FETCH_LOCATION_WISE_CAMERA_FAILURE, (state) => {
                state.locationWiseCameraDetails.loading = true;
            })
            .addCase(ACTION_TYPES.FETCH_CAMERA_DETAILS_REQUEST, (state) => {
                state.cameraDetails.loading = true;
            })
            .addCase(
                ACTION_TYPES.FETCH_CAMERA_DETAILS_SUCCESS,
                (state, action) => {
                    state.cameraDetails.data = _.get(action, "payload.response.payLoad", []);
                }
            );
    }
});

export const { actions, reducer } = slice;
