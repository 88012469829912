import { combineReducers, configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import { reducer as notificationsReducer } from "reapop";
import rootReducer from "./rootReducers";
//import persistedReducer from './rootReducers';
import rootSaga from "./rootSaga";
import { persistStore } from 'redux-persist';
import persistReducer from "redux-persist/es/persistReducer";
import storage from 'redux-persist/lib/storage';

const sagaMiddleware = createSagaMiddleware();

const combinedReducer = combineReducers({
  ...rootReducer,
  notifications: notificationsReducer()
});

const persistConfig = {
  key: 'login',
  storage
};

const persistedReducer = persistReducer(persistConfig, combinedReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: [logger, sagaMiddleware]
});

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);


