import { FormHelperText, Grid, TextareaAutosize } from "@mui/material";
import { MuiComponents } from "../../../components";
import LabelField from "./LabelField";
import { FORM_CONTROL_STYLE } from "common/constants";

const { FormControl } = MuiComponents;

const style = {
    border: "1px solid #C0E1EC",
    outline: "none",
    minHeight: "36px",
    font: "inherit",
    borderRadius: "10px",
    fontWeight: 400,
    fontSize: "18px",
    color: "#000",
    width: "100%"
};

const RenderTextArea = ({
    label,
    input,
    placeholder,
    meta: { touched, error },
    ...custom
}) => {
    return (
        <>
            <Grid sx={FORM_CONTROL_STYLE}>
                <LabelField label={label} />
                <FormControl error={touched && error}>
                    <TextareaAutosize
                        style={style}
                        {...input}
                        {...custom}
                        value={input.value}
                        placeholder={placeholder}
                        error={touched && error}
                    />
                </FormControl>
                {!!touched && (
                    <FormHelperText error sx={{ position: "absolute" }}>
                        {error}
                    </FormHelperText>
                )}
            </Grid>
        </>
    );
};

export default RenderTextArea;
