import { actions as slice } from "./slice";
import { STATE_REDUCER_KEY } from "./constants";
import { createAction } from "@reduxjs/toolkit";

export const ACTION_TYPES = {

SET_FILTER_VALUES: `${STATE_REDUCER_KEY}/SET_FILTER_VALUES`

};

export const setFilterData = createAction(ACTION_TYPES.SET_FILTER_VALUES);
export const commonSliceActions = slice;
