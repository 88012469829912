import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {
    FETCH_TOTAL_TICKETS_COUNT: `${STATE_REDUCER_KEY}/FETCH_TOTAL_TICKETS_COUNT`,

    FETCH_TOTAL_TICKETS_COUNT_REQUEST: `${STATE_REDUCER_KEY}/FETCH_TOTAL_TICKETS_COUNT_REQUEST`,
    FETCH_TOTAL_TICKETS_COUNT_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_TOTAL_TICKETS_COUNT_SUCCESS`,
    FETCH_TOTAL_TICKETS_COUNT_FAILURE: `${STATE_REDUCER_KEY}/FETCH_TOTAL_TICKETS_COUNT_FAILURE`

};

export const getTotalTicketsCount = createAction(ACTION_TYPES.FETCH_TOTAL_TICKETS_COUNT);


