import { useState } from "react";
import _ from "lodash";
import { Field } from "redux-form";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import RenderSelectField from "./Dropdown";
import LabelField from "./LabelField";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { USER_ROLE_ID } from "modules/common/common/constants";

const RenderLabelFieldArray = ({
  fields,
  title,
  variant,
  formFields: { fieldData, dispatchActions = [], reducerKey },
  userId
}) => {

  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState("panel1");
  const getFields = useSelector((state) =>
    _.get(state, `${reducerKey[0].STATE_REDUCER_KEY}`)
  );

  const getSystem = useSelector((state) =>
    _.get(state, `${reducerKey[1].STATE_REDUCER_KEY}`)
  );

  useEffect(() => {
    dispatchActions.forEach((element) => {
      dispatch(element);
    });
    fields.forEach((item, index) => {
      const dependantFields = fieldData.filter(i => i?.dependant);
      dependantFields.forEach(field => {
        field.onChange?.forEach(
          ({ action, data }) => {
            let value = fields.get(index);
            action && dispatch(action({ value, data }));
          }
        );
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fields.length < 1) {
      fields.push({});
    }
  }, [fields]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
      sx={{ backgroundColor: "#f2f2f2" }} >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontFamily: "sans-serif", fontSize: "15px" }}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          {userId !== USER_ROLE_ID?.DCR_ROLE_ID && userId !== USER_ROLE_ID?.CCR_ROLE_ID &&
            <IconButton
              onClick={() => {
                fields.push({});
              }}
              sx={{ float: "right", marginTop: "-1%" }}
            >
              <AddCircleOutlineIcon />
            </IconButton>}

          <Divider />
          <CardContent
            sx={{
              maxHeight: 200,
              overflow: "auto",
              width: "95%"
            }}
          >
            {fields.map((member, index) => {
              return (
                <Card key={index} sx={{ height: "7rem", marginTop: "1%", marginLeft: "4%" }}>
                  <Typography sx={{ fontFamily: "sans-serif", m: 1, fontSize: "13px" }}>{title + " " + `${index + 1}`}</Typography>
                  <Grid key={index}>
                    <Grid
                      container
                      spacing={4}
                      sx={{ justifyContent: "center" }}
                    >
                      <Grid item xs={12} sm={4} md={5}>
                        <LabelField label={`${fieldData[0].label}`} />
                        <Field
                          name={`${member}.${fieldData[0].name}`}
                          component={RenderSelectField}
                          variant={variant}
                          onChange={(value) => {
                            fieldData[0].onChange.forEach(
                              ({ action, data, fn }) => {
                                action && dispatch(action({ value, data }));
                                fn && fn(`${member}.${fieldData[1].name}`);
                              }
                            );

                          }}
                        >
                          {getFields}
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={4} md={5}>
                        <LabelField label={`${fieldData[1].label}`} />
                        <Field
                          name={`${member}.${fieldData[1].name}`}
                          component={RenderSelectField}
                          variant={variant}
                        >
                          {_.get(reducerKey, "1.key", false)
                            ? _.get(getSystem, _.get(fields.get(index), fieldData[0].name), [])
                            : getSystem
                          }
                        </Field>
                      </Grid>
                    </Grid>
                    <Grid>
                      {fields.length - 1 === index && index !== 0 && <IconButton onClick={() => fields.pop()} sx={{ float: "right", marginTop: "-35px", marginRight: "25px" }}>
                        <DeleteForeverIcon />
                      </IconButton>
                      }
                    </Grid>
                  </Grid>
                </Card>
              );
            })}
          </CardContent>
        </Typography>
      </AccordionDetails>
    </Accordion >
  );
};

export default RenderLabelFieldArray;
