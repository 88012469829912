import { createAction } from "@reduxjs/toolkit";
import { STATE_REDUCER_KEY } from "./constants";

export const ACTION_TYPES = {
    FETCH_TOTAL_TICKETS_COUNT: `${STATE_REDUCER_KEY}/FETCH_TOTAL_TICKETS_COUNT`,

    FETCH_TOTAL_TICKETS_COUNT_REQUEST: `${STATE_REDUCER_KEY}/FETCH_TOTAL_TICKETS_COUNT_REQUEST`,
    FETCH_TOTAL_TICKETS_COUNT_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_TOTAL_TICKETS_COUNT_SUCCESS`,
    FETCH_TOTAL_TICKETS_COUNT_FAILURE: `${STATE_REDUCER_KEY}/FETCH_TOTAL_TICKETS_COUNT_FAILURE`,

    FETCH_DASHBOARD_DATA: `${STATE_REDUCER_KEY}/FETCH_DASHBOARD_DATA`,

    FETCH_DASHBOARD_DATA_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DASHBOARD_DATA_REQUEST`,
    FETCH_DASHBOARD_DATA_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DASHBOARD_DATA_SUCCESS`,
    FETCH_DASHBOARD_DATA_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DASHBOARD_DATA_FAILURE`,

    FETCH_CAMERA_TICKETS_COUNT_DATA: `${STATE_REDUCER_KEY}/FETCH_CAMERA_TICKETS_COUNT_DATA`,

    FETCH_CAMERA_TICKETS_COUNT_DATA_REQUEST: `${STATE_REDUCER_KEY}/FETCH_CAMERA_TICKETS_COUNT_DATA_REQUEST`,
    FETCH_CAMERA_TICKETS_COUNT_DATA_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_CAMERA_TICKETS_COUNT_DATA_SUCCESS`,
    FETCH_CAMERA_TICKETS_COUNT_DATA_FAILURE: `${STATE_REDUCER_KEY}/FETCH_CAMERA_TICKETS_COUNT_DATA_FAILURE`,

    FETCH_CCR_TICKETS_COUNT_DATA: `${STATE_REDUCER_KEY}/FETCH_CCR_TICKETS_COUNT_DATA`,

    FETCH_CCR_TICKETS_COUNT_DATA_REQUEST: `${STATE_REDUCER_KEY}/FETCH_CCR_TICKETS_COUNT_DATA_REQUEST`,
    FETCH_CCR_TICKETS_COUNT_DATA_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_CCR_TICKETS_COUNT_DATA_SUCCESS`,
    FETCH_CCR_TICKETS_COUNT_DATA_FAILURE: `${STATE_REDUCER_KEY}/FETCH_CCR_TICKETS_COUNT_DATA_FAILURE`,

    FETCH_DCR_TICKETS_COUNT_DATA: `${STATE_REDUCER_KEY}/FETCH_DCR_TICKETS_COUNT_DATA`,

    FETCH_DCR_TICKETS_COUNT_DATA_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DCR_TICKETS_COUNT_DATA_REQUEST`,
    FETCH_DCR_TICKETS_COUNT_DATA_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DCR_TICKETS_COUNT_DATA_SUCCESS`,
    FETCH_DCR_TICKETS_COUNT_DATA_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DCR_TICKETS_COUNT_DATA_FAILURE`,

    FETCH_CAMERA_TYPE_WISE_COUNT_DATA: `${STATE_REDUCER_KEY}/FETCH_CAMERA_TYPE_WISE_COUNT_DATA`,

    FETCH_CAMERA_TYPE_WISE_COUNT_DATA_REQUEST: `${STATE_REDUCER_KEY}/FETCH_CAMERA_TYPE_WISE_COUNT_DATA_REQUEST`,
    FETCH_CAMERA_TYPE_WISE_COUNT_DATA_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_CAMERA_TYPE_WISE_COUNT_DATA_SUCCESS`,
    FETCH_CAMERA_TYPE_WISE_COUNT_DATA_FAILURE: `${STATE_REDUCER_KEY}/FETCH_CAMERA_TYPE_WISE_COUNT_DATA_FAILURE`,

    FETCH_IT_AND_NON_IT_COUNT_DATA: `${STATE_REDUCER_KEY}/FETCH_IT_AND_NON_IT_COUNT_DATA`,

    FETCH_IT_AND_NON_IT_COUNT_DATA_REQUEST: `${STATE_REDUCER_KEY}/FETCH_IT_AND_NON_IT_COUNT_DATA_REQUEST`,
    FETCH_IT_AND_NON_IT_COUNT_DATA_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_IT_AND_NON_IT_COUNT_DATA_SUCCESS`,
    FETCH_IT_AND_NON_IT_COUNT_DATA_FAILURE: `${STATE_REDUCER_KEY}/FETCH_IT_AND_NON_IT_COUNT_DATA_FAILURE`,


    FETCH_DASHBOARD_CCR_COUNT_DATA: `${STATE_REDUCER_KEY}/FETCH_DASHBOARD_CCR_COUNT_DATA`,

    FETCH_DASHBOARD_CCR_COUNT_DATA_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DASHBOARD_CCR_COUNT_DATA_REQUEST`,
    FETCH_DASHBOARD_CCR_COUNT_DATA_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DASHBOARD_CCR_COUNT_DATA_SUCCESS`,
    FETCH_DASHBOARD_CCR_COUNT_DATA_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DASHBOARD_CCR_COUNT_DATA_FAILURE`,

    FETCH_DASHBOARD_DCR_COUNT_DATA: `${STATE_REDUCER_KEY}/FETCH_DASHBOARD_DCR_COUNT_DATA`,

    FETCH_DASHBOARD_DCR_COUNT_DATA_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DASHBOARD_DCR_COUNT_DATA_REQUEST`,
    FETCH_DASHBOARD_DCR_COUNT_DATA_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DASHBOARD_DCR_COUNT_DATA_SUCCESS`,
    FETCH_DASHBOARD_DCR_COUNT_DATA_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DASHBOARD_DCR_COUNT_DATA_FAILURE`,

    FETCH_DASHBOARD_SDC_COUNT_DATA: `${STATE_REDUCER_KEY}/FETCH_DASHBOARD_SDC_COUNT_DATA`,

    FETCH_DASHBOARD_SDC_COUNT_DATA_REQUEST: `${STATE_REDUCER_KEY}/FETCH_DASHBOARD_SDC_COUNT_DATA_REQUEST`,
    FETCH_DASHBOARD_SDC_COUNT_DATA_SUCCESS: `${STATE_REDUCER_KEY}/FETCH_DASHBOARD_SDC_COUNT_DATA_SUCCESS`,
    FETCH_DASHBOARD_SDC_COUNT_DATA_FAILURE: `${STATE_REDUCER_KEY}/FETCH_DASHBOARD_SDC_COUNT_DATA_FAILURE`

};

export const getTotalTicketsCount = createAction(ACTION_TYPES.FETCH_TOTAL_TICKETS_COUNT);
export const getDashboardData = createAction(ACTION_TYPES.FETCH_DASHBOARD_DATA);
export const getCameraTicketCountData = createAction(ACTION_TYPES.FETCH_CAMERA_TICKETS_COUNT_DATA);
export const getCcrTicketCountData = createAction(ACTION_TYPES.FETCH_CCR_TICKETS_COUNT_DATA);
export const getDcrTicketCountData = createAction(ACTION_TYPES.FETCH_DCR_TICKETS_COUNT_DATA);
export const getCameraTypeWiseCount = createAction(ACTION_TYPES.FETCH_CAMERA_TYPE_WISE_COUNT_DATA);
export const getItAndNonItCount = createAction(ACTION_TYPES.FETCH_IT_AND_NON_IT_COUNT_DATA);
export const getCcrDashboardCount = createAction(ACTION_TYPES.FETCH_DASHBOARD_CCR_COUNT_DATA);
export const getDcrDashboardCount = createAction(ACTION_TYPES.FETCH_DASHBOARD_DCR_COUNT_DATA);
export const getSdcDashboardCount = createAction(ACTION_TYPES.FETCH_DASHBOARD_SDC_COUNT_DATA);

