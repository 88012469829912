import { Count, Move } from "./Methods";

const rotate = ({ children }) => (<Move
    rotation={90}
    timing={200}
    springConfig={{ tension: 200, friction: 7 }}
>
    {children}
</Move>);

const scale = ({ children, springConfig }) => (
    <Move scale={1.5} springConfig={{ mass: 2, tension: 900, friction: 20, ...springConfig }}>
        {children}
    </Move>);

const move = ({ children, x = -10 }) => (
    <Move x={x}>
        {children}
    </Move>);

const count = ({ number = 0 }) => (<Count number={number} />);

export default { move, scale, rotate, count };
